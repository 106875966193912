<template>
  <v-app>
    <v-app-bar
      app
      color="grey lighten-5"
      dark
      height="65"
    >
      <div class="align-left">
        <button @click="$router.push('/')">
          <v-img
            alt="Technik Interlytics Logo"
            class="mr-2 mt-2"
            contain
            src="./assets/images/ti_logo.jpg"
            transition="scale-transition"
            height="63"          
          />
        </button>
      </div>

      <span class="mr-10 text-h5" style="color:darkslategrey;">
        Technik Interlytics:&nbsp;&nbsp;&nbsp;&nbsp;Verity<u>X</u> for High Fidelity Data
      </span>

    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer color="blue-grey darken-4" class="mt-15">
      <span style="color:aliceblue;">Copyright ©2024 Technik Interlytics. All rights reserved.</span>
      <v-spacer />
      <span style="color:aliceblue;">Contact us at info@technikinterlytics.com</span>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
};
</script>
