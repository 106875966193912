import { VR_Common as VRC } from "./VR_Common";

export const VR_Transforms = {

  hashCat:[],
  hashCatFunc:[],
  hashFuncToCat:[],
  categories:[
    {
      title:"assignment",
      funcs:[
        {
          title:"setToValue",
          desc:"Assign a fixed value. "
          + "Use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-, -pipe-, "
          + "-mathpi- to assign PI, -mathe- to assign E. "
          + "Param1: value to assign. ",
          param1:{datatype:"string", type:"text", required:true},
        },
        {
          title:"setToRef",
          desc:"Assigns the value of a referenced source field in the current record. " 
          + "Param1: source field title.",
          param1:{datatype:"string", type:"sourceref", required:true},
        },
        {
          title:"setToTargetRef",
          desc:"Assigns the value of a referenced target field in the current record. " 
          + "Param1: target field title whose value is used after it is computed.",
          param1:{datatype:"string", type:"targetref", required:true},
        },
        {
          title:"setToBinomialDist",
          desc:"The discrete probability distribution of number of successes in N independent trials with each having boolean outcome with success probability P. " 
          + "The position of measurement defaults to a random number in range 0-N. To specify it, use a suffix " 
          + "added to end of param1 like 2(5) or 2(field_2) where the latter causes a lookup of the referenced field to use " 
          + "its value for measuremnt x. If this is out of bounds (0-N) then default is used."
          + "Param1: N trials, integer > 0.Optionally, add suffix of (measurement point either int value or field ref title). "
          + "Param2: P probability of success, real >=0 and <=1 such as 0.5 for a coin toss. "
          + "Param3: choice of cumulative distributon function or probability mass function (i.e. single point value). "
          ,
          param1:{datatype:"integer", type:"text", required:true},
          param2:{datatype:"real", type:"text", required:true},
          param3:{datatype:"string", type:"text", required:true},
        },
        {
          title:"setToPoissonDist",
          desc:"The discrete probability distribution of number of events occurring in a time interval, with an average rate of events. "
          + "The position of measurement defaults to a random number in range +- 3*stddev. To specify it, use a suffix " 
          + "added to end of param1 like 12.75(7) or 12.75(field_2) where the latter causes a lookup of the referenced field to use " 
          + "its value for measuremnt x. "
          + "Param1: average events per time interval. Must be > 0 . "
          + "Param2: choice of cumulative distributon function or probability mass function (i.e. single point value). "
          ,
          param1:{datatype:"real", type:"text", required:true},
          param2:{datatype:"string", type:"text", required:true},
        },
        {
          title:"setToNormalDist",
          desc:"The Normal (Gaussian) probability distribution which is symmetric around the mean and described by its standard deviation. "
          + "68% of values occur with 1 stdDev of mean; 95% within 2 stdDev; 99.8% within 3 stdDev."
          + "The position measurement defaults to a random number in range +- 3*stddev. " 
          + "To specify it, use a suffix added to end of param1 like 4.25(11.2) or 4.25(field_2) " 
          + "where the latter causes a lookup of the referenced field to use its value for measuremnt x. "
          + "Param1: mean. "
          + "Param2: std dev. Must be >=0 . "
          + "Param3: choice of cumulative distributon function or probability density function (i.e. single point value). "
          ,
          param1:{datatype:"real", type:"text", required:true},
          param2:{datatype:"real", type:"text", required:true},
          param3:{datatype:"string", type:"text", required:true},
        },
        {
          title:"setToLogNormalDist",
          desc:"The Log-Normal probability distribution where the logarithm of a variable has a normal distribution described by its mean and standard deviation (of the log not the random variable). "
          + "The position measurement defaults to a random number in range 0 to 3*stddev. "
          + "To specify it, use a suffix added to end of param1 like 4.25(11.2) or 4.25(field_2) " 
          + "where the latter causes a lookup of the referenced field to use its value for measuremnt x. "
          + "Param1: mean. Must be >0. "
          + "Param2: std dev. Must be >=0 . "
          + "Param3: required choice of cumulative distributon function or probability density function (i.e. single point value). "
          ,
          param1:{datatype:"real", type:"text", required:true},
          param2:{datatype:"real", type:"text", required:true},
          param3:{datatype:"string", type:"text", required:true},
        },
        {
          title:"setToRandom",
          desc:"Uses random number generator to produce a value scaled between the minimum and maximum values. "
          + "Param1: optional as minimum. Default is 0. "
          + "Param2: optional as maximum. Default is 1. If this is < min then set to min + 1. "
          + "Param3: optional real between 0.0 and 1.0 as fraction of records to apply this function to with default=1.0. Example, 0.3 means 30% of records are assigned whereas 70% are not."
          ,
          param1:{datatype:"real", type:"text", required:false},
          param2:{datatype:"real", type:"text", required:false},
          param3:{datatype:"real", type:"text", required:false},
        },
        {
          title:"setToFreqList",
          desc:"Applies a value from a list using relative frequencies of occurrence. "
          + "Param1: relative frequencies of occurrence. Can use any real >0 and will be scaled to sum. Since list, enter delimited by pipe such as 2|7|.5|9 where the second list item occurs 37.8% percent (7/18.5) of the time. "
          + "Param2: list of values correlated to Param1 frequencies and entered with pipe delimiter like red|green|blue|orange . " 
          + "Param3: optional reference field title to use as source of number to select list item instead of the default use of random number. Must be real number 0.0-1.0. "
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"text", required:true},
          param3:{datatype:"string", type:"text", required:false},
        },
        {
          title:"lookup",
          desc:"Assigns a value from a lookup dictionary based on matching values to keys. "
          + "The dictionary is the name of a source file. " 
          + "The match can be made to one, two, or three source fields in the record. The first field is always used as "
          + "the current field value. " 
          + "Fields 2 and 3 are optionally set in Param2 by selecting from the field select box. If more than 2 "
          + "selected then only the first two will be used. " 
          + "Leave Param2 empty to use only 1 field as the match value. All selected fields must match their "
          + "respective conditions for a lookup result to be assigned. " 
          + "The match field conditions are taken from each row of the lookup dictionary starting from the first column (using 1 based indexing) , "
          + "and the result to assign is taken from the next column after all matching field conditions. " 
          + "Thus, a single match means the condition is in column 1 and the associated result in column 2, whereas " 
          + "if two matching fields are used then first condition is in column 1, second condition is in column 2, and result is in column 3. "
          + "Conditions can use front and/or back wildcard (*) like top*, *night, *state* to allow token matching. "
          + "To use multiple conditions for the same replacement value ( OR condition ), enter them as separate lines in the dictionary. " 
          + "To use AND and NOT conditions, use special notations as delimiters such as: " 
          + "top*-and-*night-not-*blue* which means a match requires both top* and *night be true as well as no instances of the token blue. " 
          + "Param1: dictionary URI or Alias of a source file which will be included when configuring a job. "
          + "Param2: Fields 2 and 3"
          ,
          param1:{datatype:"string", type:"fileref", required:true},
          param2:{datatype:"string", type:"text", required:false},
        },
      ]
    },
    {
      title:"conditional",
      funcs:[
        {
          title:"noOp",
          desc:"no operation. Can be used as stopping operation in a conditional.",
        },
        {
          title:"ifEmpty",
          desc:"If value is empty. Compares current value as string",
        },
        {
          title:"ifNotEmpty",
          desc:"If value is not empty. Compares current value as string",
        },
        {
          title:"ifEq",
          desc:"If equals. Compares current value as real number to Param1 which is either a real number or "
          + "special notation -mathpi- (set to PI), -mathe- (set to E). True if equal. "
          + "Param1: Comparison value",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"ifNotEq",
          desc:"If NOT equals. Compares current value as real number to Param1 which is either a real number or "
          + "special notation -mathpi- (set to PI), -mathe- (set to E). True if NOT equal. "
          + "Param1: Comparison value",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"ifGte",
          desc:"If greater than or equals. Compares current value as real number to Param1 which is either a real number or "
          + "special notation -mathpi- (set to PI), -mathe- (set to E). True if current value is greater than or equal to Param1. "
          + "Param1: Comparison value",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"ifNotGte",
          desc:"If NOT greater than or equals. Compares current value as real number to Param1 which is either a real number or "
          + "special notation -mathpi- (set to PI), -mathe- (set to E). True if current value is NOT greater than or equal to Param1. "
          + "Param1: Comparison value",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"ifGt",
          desc:"If greater than. Compares current value as real number to Param1 which is either a real number or "
          + "special notation -mathpi- (set to PI), -mathe- (set to E). True if current value is greater than to Param1. "
          + "Param1: Comparison value",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"ifNotGt",
          desc:"If NOT greater than. Compares current value as real number to Param1 which is either a real number or "
          + "special notation -mathpi- (set to PI), -mathe- (set to E). True if current value is NOT greater than to Param1. "
          + "Param1: Comparison value",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"ifLte",
          desc:"If less than or equals. Compares current value as real number to Param1 which is either a real number or "
          + "special notation -mathpi- (set to PI), -mathe- (set to E). True if current value is less than or equal to Param1. "
          + "Param1: Comparison value",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"ifNotLte",
          desc:"If NOT  than or equals. Compares current value as real number to Param1 which is either a real number or "
          + "special notation -mathpi- (set to PI), -mathe- (set to E). True if current value is NOT less than or equal to Param1. "
          + "Param1: Comparison value",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"ifLt",
          desc:"If less than. Compares current value as real number to Param1 which is either a real number or "
          + "special notation -mathpi- (set to PI), -mathe- (set to E). True if current value is less than to Param1. "
          + "Param1: Comparison value",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"ifNotLt",
          desc:"If NOT less than. Compares current value as real number to Param1 which is either a real number or "
          + "special notation -mathpi- (set to PI), -mathe- (set to E). True if current value is NOT less than to Param1. "
          + "Param1: Comparison value",
          param1:{datatype:"real", type:"text", required:true},
        },    
        {
          title:"ifStrEq",
          desc:"If strings are equal. Compares current string value to Param1 which is required. True if equal. " 
          + "Specify multiple test strings by delimiting with | (pipe without spacing) as in String1|strING2|strINg3 . "
          + "Use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-, -pipe-, "
          + "-mathpi- to assign PI, -mathe- to assign E . "
          + "Param1: Comparison value. "
          + "Param2: whether case sensitive (default is false)"
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"list", required:false, listVals:["false", "true"]},
        },
        {
          title:"ifNotStrEq",
          desc:"If strings are NOT equal. Compares current string value to Param1 which is required. True if equal. " 
          + "Specify multiple test strings by delimiting with | (pipe without spacing) as in String1|strING2|strINg3 . "
          + "Use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-, -pipe-, "
          + "-mathpi- to assign PI, -mathe- to assign E . "
          + "Param1: Comparison value. "
          + "Param2: whether case sensitive (default is false)"
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"list", required:false, listVals:["false", "true"]},
        },
        {
          title:"ifStrStarts",
          desc:"If current value starts with Param1 which is required. True if equal. " 
          + "Specify multiple test strings by delimiting with | (pipe without spacing) as in String1|strING2|strINg3 . "
          + "Use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-, -pipe-, "
          + "-mathpi- to assign PI, -mathe- to assign E . "
          + "Param1: Comparison value. "
          + "Param2: whether case sensitive (default is false)"
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"list", required:false, listVals:["false", "true"]},
        },
        {
          title:"ifNotStrStarts",
          desc:"If current value does NOT start with Param1 which is required. True if equal. " 
          + "Specify multiple test strings by delimiting with | (pipe without spacing) as in String1|strING2|strINg3 . "
          + "Use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-, -pipe-, "
          + "-mathpi- to assign PI, -mathe- to assign E . "
          + "Param1: Comparison value. "
          + "Param2: whether case sensitive (default is false)"
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"list", required:false, listVals:["false", "true"]},
        },
        {
          title:"ifStrContains",
          desc:"If current value contains Param1 which is required. True if equal. " 
          + "Specify multiple test strings by delimiting with | (pipe without spacing) as in String1|strING2|strINg3 . "
          + "Use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-, -pipe-, "
          + "-mathpi- to assign PI, -mathe- to assign E . "
          + "Param1: Comparison value. "
          + "Param2: whether case sensitive (default is false). "
          + "Param3: whether to remove all spaces from current value before match so 'this is 2 words' becomes 'thisis2words' (default is false). "
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"list", required:false, listVals:["false", "true"]},
          param3:{datatype:"string", type:"list", required:false, listVals:["false", "true"]},
        },
        {
          title:"ifNotStrContains",
          desc:"If current value does NOT contain Param1 which is required. True if equal. " 
          + "Specify multiple test strings by delimiting with | (pipe without spacing) as in String1|strING2|strINg3 . "
          + "Use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-, -pipe-, "
          + "-mathpi- to assign PI, -mathe- to assign E . "
          + "Param1: Comparison value. "
          + "Param2: whether case sensitive (default is false). "
          + "Param3: whether to remove all spaces from current value before match so 'this is 2 words' becomes 'thisis2words' (default is false). "
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"list", required:false, listVals:["false", "true"]},
          param3:{datatype:"string", type:"list", required:false, listVals:["false", "true"]},
        },
        {
          title:"ifStrEnds",
          desc:"If current value ends with Param1 which is required. True if equal. " 
          + "Specify multiple test strings by delimiting with | (pipe without spacing) as in String1|strING2|strINg3 . "
          + "Use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-, -pipe-, "
          + "-mathpi- to assign PI, -mathe- to assign E . "
          + "Param1: Comparison value. "
          + "Param2: whether case sensitive (default is false)"
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"list", required:false, listVals:["false", "true"]},
        },
        {
          title:"ifNotStrEnds",
          desc:"If current value does NOT end with Param1 which is required. True if equal. " 
          + "Specify multiple test strings by delimiting with | (pipe without spacing) as in String1|strING2|strINg3 . "
          + "Use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-, -pipe-, "
          + "-mathpi- to assign PI, -mathe- to assign E . "
          + "Param1: Comparison value. "
          + "Param2: whether case sensitive (default is false)"
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"list", required:false, listVals:["false", "true"]},
        },
        {
          title:"ifInt",
          desc:"If integer. Compares current value. "
          + "Param1: whether any value range (default), positive (greater than 0), or negative (less than 0). ",
          param1:{datatype:"string", type:"list", required:false, listVals:["any", "positive", "negative"]},
        },
        {
          title:"ifNotInt",
          desc:"If not integer. Compares current value. "
          + "Param1: whether any value range (default), positive (greater than 0), or negative (less than 0). ",
          param1:{datatype:"string", type:"list", required:false, listVals:["any", "positive", "negative"]},
        },
        {
          title:"ifReal",
          desc:"If real. Compares current value. "
          + "Param1: whether any value range (default), positive (greater than 0), or negative (less than 0). ",
          param1:{datatype:"string", type:"list", required:false, listVals:["any", "positive", "negative"]},
        },
        {
          title:"ifNotReal",
          desc:"If not real. Compares current value. "
          + "Param1: whether any value range (default), positive (greater than 0), or negative (less than 0). ",
          param1:{datatype:"string", type:"list", required:false, listVals:["any", "positive", "negative"]},
        },
        {
          title:"ifISODate",
          desc:"If current value is in ISO datetime format which is yyyymmdd with optional time " 
          + "part at end starting with a 'T' as in yyyymmddThhmmss. Date part can use delimiters / or - "
          + "while time part may use : delimiter such as 2022-08-14T08:30:00 or without as 20220814T083000"
          ,
        },
        {
          title:"ifNotISODate",
          desc:"If current value is not in ISO datetime format which is yyyymmdd with optional time " 
          + "part at end starting with a 'T' as in yyyymmddThhmmss. Date part can use delimiters / or - "
          + "while time part may use : delimiter such as 2022-08-14T08:30:00 or without as 20220814T083000"
          ,
        },
        {
          title:"ifDateFormat",
          desc:"If current value is in supplied date format. " 
          + "Date can use delimiters / or - which will be automatically detected. "
          + "Some formats use month abbreviation like Jan, Mar as denoted with MMM, "
          + "and others use full month name like January as denoted by MONTH."
          + "Param1: Date format "
          ,
          param1:{datatype:"string", type:"list", required:true, listVals:["mmddyy", "mmdyy"
          ,"mdyy","mmddyyyy","mmdyyyy","mdyyyy","ddmmyy","ddmyy","dmyy","ddmmyyyy"
          ,"ddmyyyy","dmyyyy","yymmdd","yymmd","yymd","yyyymmdd","yyyymmd","yyyymd"
          ,"yyyyddd","yyyyMMMdd","ddMMMyyyy","MONTHdd,yyyy","ddMONTH,yyyy"
          ,"yyyyMONTHdd","ddMONTHyyyy","yyMONTHdd","ddMONTHyy"]},
        },
        {
          title:"ifNotDateFormat",
          desc:"If current value is not in supplied date format. " 
          + "Date can use delimiters / or - which will be automatically detected. "
          + "Some formats use month abbreviation like Jan, Mar as denoted with MMM, "
          + "and others use full month name like January as denoted by MONTH."
          + "Param1: Date format.  "
          ,
          param1:{datatype:"string", type:"list", required:true, listVals:["mmddyy", "mmdyy"
          ,"mdyy","mmddyyyy","mmdyyyy","mdyyyy","ddmmyy","ddmyy","dmyy","ddmmyyyy"
          ,"ddmyyyy","dmyyyy","yymmdd","yymmd","yymd","yyyymmdd","yyyymmd","yyyymd"
          ,"yyyyddd","yyyyMMMdd","ddMMMyyyy","MONTHdd,yyyy","ddMONTH,yyyy"
          ,"yyyyMONTHdd","ddMONTHyyyy","yyMONTHdd","ddMONTHyy"]},
        },
        {
          title:"ifPossibleDate",
          desc:"If current value is possibly a date by checking common date formats " 
          + "and ensuring numeric part values are within range of valid dates. "
          + "Only numeric date formats are allowed meaning no month names or abbreviations. "
          + "Date can use delimiters / or - which will be automatically detected. "
          ,          
        },
        {
          title:"ifNotPossibleDate",
          desc:"If current value is not possibly a date by checking common date formats " 
          + "and ensuring numeric part values are within range of valid dates. "
          + "Only numeric date formats are allowed meaning no month names or abbreviations. "
          + "Date can use delimiters / or - which will be automatically detected. "
          ,          
        },
      ]
    },
    {
      title:"numeric",
      funcs:[
        {
          title:"convertMainFrameNumber",
          desc:"Converts a number in string representation in coded main frame format to string of real number. "
          + "Encoded last character is indicator of this formatting including sign reversal if necessary. "
          + "Always makes last 2 digits into decimal portion so no further divide by 100 is necessary. " 
          + "If special char is within input string it becomes the end char and the "
          + "remaining suffix is discarded. Leading zeros are truncated so 000.12 becomes 0.12 . Codes are:"
          + "{= 0, }= 0 and negate; "
          + "a= 1, j= 1 and negate; "
          + "b= 2, k= 2 and negate; "
          + "c= 3, l= 3 and negate; "
          + "d= 4, m= 4 and negate; "
          + "e= 5, n= 5 and negate; "
          + "f= 6, o= 6 and negate; "
          + "g= 7, p= 7 and negate; "
          + "h= 8, q= 8 and negate; "
          + "i= 9, r= 9 and negate", 
        },
        {
          title:"round",
          desc:"Rounds number to nearest integer with midpoint going to even number. If for a real datatype field, will end with .00",
        },
        {
          title:"floor",
          desc:"Returns the largest integral value less than or equal to number. Real datatype field will end with .00",
        },
        {
          title:"ceiling",
          desc:"Returns the smallest integral value greater than or equal to number. Real datatype field will end with .00",
        },
        {
          title:"abs",
          desc:"Returns absolute value",
        },
        {
          title:"negate",
          desc:"Inverts sign",
        },
        {
          title:"mult",
          desc:"Multiply current value by supplied number. May use special notations: -mathpi- for pi, -mathe- for e . "
          + "Param1: number for operation",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"div",
          desc:"Divide current value by supplied number. May use special notations: -mathpi- for pi, -mathe- for e. "
          + "If Param1 is 0 then result is set to 9.99 x 10^10 . "
          + "Param1: number for operation",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"add",
          desc:"Add current value to supplied number. May use special notations: -mathpi- for pi, -mathe- for e . "
          + "Param1: number for operation",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"subtract",
          desc:"Subtract current value by supplied number (current-x). May use special notations: -mathpi- for pi, -mathe- for e . "
          + "Param1: number for operation",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"subtractFrom",
          desc:"Subtract current value from supplied number (x-current). May use special notations: -mathpi- for pi, -mathe- for e . "
          + "Param1: number for operation",
          param1:{datatype:"real", type:"text", required:true},
        },
        {
          title:"multByRef",
          desc:"Multiply current value by value of referenced field in record. "
          + "Param1: source field name reference to get number for operation",
          param1:{datatype:"string", type:"sourceref", required:true},
        },
        {
          title:"divByRef",
          desc:"Divide current value by value of referenced field in record (current/ref). If referenced field value is 0 then result is set to 9.99 x 10^10 . "
          + "Param1: source field name reference to get number for operation",
          param1:{datatype:"string", type:"sourceref", required:true},
        },
        {
          title:"addByRef",
          desc:"Add current value to value of referenced field in record. "
          + "Param1: source field name reference to get number for operation",
          param1:{datatype:"string", type:"sourceref", required:true},
        },
        {
          title:"subtractByRef",
          desc:"Subtract current value by value of referenced field in record (current-ref). "
          + "Param1: source field name reference to get number for operation",
          param1:{datatype:"string", type:"sourceref", required:true},
        },
        {
          title:"divFromRef",
          desc:"Divide value of referenced field in record by current value (ref/current). If current value is 0 then result is set to 9.99 x 10^10 . "
          + "Param1: source field name reference to get number for operation",
          param1:{datatype:"string", type:"sourceref", required:true},
        },
        {
          title:"subtractFromRef",
          desc:"Subtract value of referenced field in record by current value (ref-current). If current value is 0 then result is set to 9.99 x 10^10 . "
          + "Param1: title of source field. ",
          param1:{datatype:"string", type:"sourceref", required:true},
        },
        {
          title:"multRefs",
          desc:"Multiply referenced fields in record. "
          + "Param1: source field name reference to get number for operation. "
          + "Param2: second source field title. "
          + "Param3: third source field title.",
          param1:{datatype:"string", type:"sourceref", required:true},
          param2:{datatype:"string", type:"sourceref", required:true},
          param3:{datatype:"string", type:"sourceref", required:false},
        },
        {
          title:"addRefs",
          desc:"Add referenced fields in record. "
          + "Param1: source field name reference to get number for operation. "
          + "Param2: second source field title. "
          + "Param3: third source field title.",
          param1:{datatype:"string", type:"sourceref", required:true},
          param2:{datatype:"string", type:"sourceref", required:true},
          param3:{datatype:"string", type:"sourceref", required:false},
        },
        {
          title:"log",
          desc:"Base 10 logarithm of current value. If value is not greater than 0, result is set to -1000000. If datatype of field is int, then real value is first converted with FLOOR.",
        },
        {
          title:"ln",
          desc:"Base E logarithm of current value. If value is not greater than 0, result is set to -1000000. If datatype of field is int, then real value is first converted with FLOOR.",
        },
        {
          title:"pow10",
          desc:"Base 10 exponential of current value.",
        },
        {
          title:"powe",
          desc:"Base E exponential of current value.",
        },
        {
          title:"setDecimal",
          desc:"Sets the number of decimal places for a number. If <=0 then value is truncated to integer. If less than number's decimals, excess digits cut. If greater, decimal places padded right with 0."
          + "Param1: integer number of decimal places"
          ,
          param1:{datatype:"integer", type:"text", required:true},
        },
        {
          title:"convertFromExp",
          desc:"Will convert a string representing exponential number into non-exponential number. String will be checked for format n.nnEsnn where n is integer digit, "
          + "and s is an optional + or -. String can also have front - or be enclosed in parentheses. Examples: "
          + "1.3E05 converted to 130000, -1.23e-1 converted to -0.123",
        },            
      ]
    },
    {
      title:"text",
      funcs:[
        {
          title:"trim",
          desc:"remove whitespace from front and back.",
        },
        {
          title:"ltrim",
          desc:"remove whitespace from front.",
        },
        {
          title:"rtrim",
          desc:"remove whitespace from back.",
        },
        {
          title:"toLower",
          desc:"sets to all lower case.",
        },
        {
          title:"toUpper",
          desc:"sets to all upper case.",
        },
        {
          title:"front",
          desc:"takes characters from front of string. " 
          + "Matching part is found in current value and then cut after its first occurrence, "
          + "such as 'r' for value 'horse' yields 'hor'." 
          + "Can use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-  . "
          + "Param1: string part used to find matching part in current value"
          ,
          param1:{datatype:"string", type:"text", required:true},
        },
        {
          title:"frontN",
          desc:"takes number of characters from front of string. "
          + "Param1: integer number of characters"
          ,
          param1:{datatype:"integer", type:"text", required:true},
        },
        {
          title:"end",
          desc:"takes characters from end of string. " 
          + "Matching part in current value which is then cut at first occurrence, "
          + "such as 'r' for value 'horse' yields 'rse'."
          + "Can use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-  . "
          + "Param1: string part used to find matching part in current value"
          ,
          param1:{datatype:"string", type:"text", required:true},
        },
        {
          title:"endN",
          desc:"takes number of characters from back of string. "
          + "Param1: integer number of characters.",
          param1:{datatype:"integer", type:"text", required:true},
        },
        {
          title:"mid",
          desc:"takes characters from middle of string. " 
          + "Matching part in current value is then cut at first occurrence. "
          + "If Param2 not used, result is after cutting with Param1. "
          + "If Param2 is used, then initial result is again cut after Param2's occurrence meaning result is string including Param1 and Param2 only. "
          + "For value 'Semantics' Param1='m' and Param2='c' yields 'mantic'. "
          + "For value 'mishmash' Param1='ma' and Param2='s' yields 'mas'."
          + "Can use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-  . "
          + "Param1: string part used to find matching part in current value"
          + "Param2: optional second string part"
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"text", required:false},
        },
        {
          title:"midN",
          desc:"takes characters from middle of string. " 
          + "If Param2 not used, result is after cutting with Param1. "
          + "For value 'Semantics' Param1='2' and Param2='6' yields 'mantic' , and "
          + "for value 'mishmash' Param1='4' and Param2='3' yields 'mas'."
          + "Param1: integer starting position using 0-based indexing"
          + "Param2: optional second integer for number of characters to take"
          ,
          param1:{datatype:"integer", type:"text", required:true},
          param2:{datatype:"integer", type:"text", required:false},
        },
        {
          title:"charAt",
          desc:"takes one character. "
          + "Param1: integer starting position using 0-based indexing"
          ,
          param1:{datatype:"integer", type:"text", required:true},
        },
        {
          title:"prepend",
          desc:"adds string part to front of string. "
          + "Can use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-  . "
          + "Param1: string to add"
          ,
          param1:{datatype:"string", type:"text", required:true},
        },
        {
          title:"append",
          desc:"adds string part to front of string. "
          + "Can use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-  . "
          + "Param1: string to add"
          ,
          param1:{datatype:"string", type:"text", required:true},
        },
        {
          title:"remove",
          desc:"removes all instances of string part from current value. "
          + "Can use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-  . "
          + "Param1: string to remove"
          ,
          param1:{datatype:"string", type:"text", required:true},
        },
        {
          title:"replace",
          desc:"replaces all instances of string part from current value with supplied string part. "
          + "Can use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-  . "
          + "Param1: string to replace"
          + "Param2: string to add"
          ,
          param1:{datatype:"string", type:"text", required:true},
          param2:{datatype:"string", type:"text", required:true},
        },
        {
          title:"setLength",
          desc:"forces string to have specified length potentially cutting or padding value. "
          + "Can use following special notations for characters that interfere with parsing: "
          + "-comma-, -forwardslash-, -backwardslash-, -curlyleft-, -curlyright-, " 
          + "-squareleft-, -squareright-, -parenleft-, -parenright-, -space-  . "
          + "Param1: integer length"
          + "Param2: optional side to cut or pad (left, right) and defaults to right"
          + "Param3: character to pad with if needed and defaults to 'x'"
          ,
          param1:{datatype:"integer", type:"text", required:true},
          param2:{datatype:"string", type:"text", required:false},
          param3:{datatype:"string", type:"text", required:false},
        },            
      ]
    },
    {
      title:"date",
      funcs:[
        {
          title:"setToIsoDate",
          desc:"Creates an ISO8601 DateTime string which is yyyymmddThhmmss. "
          + "Param1 may be: today (yields current date only), now (yields current dateTime), "
          + "or an ISO dateTime value which is checked and used for result if correct or else result is empty."
          + "Param1: optional and defaults to special notation 'today'"
          ,
          param1:{datatype:"string", type:"text", required:false},
        },
        {
          title:"dateToIso",
          desc:"converts date in specified format into ISO8601 yyyymmdd. Param1 is required incoming date format as: "
          + "mmddyy, mmdyy, mdyy, mmddyyyy, mmdyyyy, mdyyyy, ddmmyy, ddmyy, dmyy, ddmmyyyy, ddmyyyy, dmyyyy, "
          + "yymmdd, yymmd, yymd, yyyymmdd, yyyymmd, yyyymd, yyyyddd, yyyyMMMdd, yyMMMdd, ddMMMyyyy, ddMMMyy. "
          + "MMM = 3 letter month title like JAN"
          + "Param1: date format"
          ,
          param1:{datatype:"string", type:"list", required:true},
        },
        {
          title:"excelDateNumberToIso",
          desc:"converts a date in numeric excel format into ISO8601 yyyymmdd format with fractional days removed. Excel uses "
          + "number days since January 1, 1900 as its counting base. "
          + "Example: 44416 = 20210808, 42855 = 20170430",
        },            
      ]
    },
  ],
  SetHashArrays() {
    let txt="";
    try {
      this.hashCat=[];
      this.hashCatFunc=[];
      this.hashFuncToCat=[];
      for (let i=0; i< this.categories.length; i++) {
        this.hashCat[this.categories[i].title]=i;
        for (let j=0; j< this.categories[i].funcs.length; j++) {
          this.hashFuncToCat[this.categories[i].funcs[j].title.toLowerCase()]=this.categories[i].title;
          txt= `${this.categories[i].title}.${this.categories[i].funcs[j].title.toLowerCase()}`;
          this.hashCatFunc[txt]=j;
        }
      }
    }
    catch (e) { alert(`error SetHashArrays: ${e.toString()}`); }
  },
  SetFuncParamsState(nCat, nFunc, fdObj, srcItems) {
    let cat="", func="", txt="";
    let params=[];
    try {
      if (typeof nCat!="number" || nCat<0) throw("missing nCat");
      if (typeof nFunc!="number" || nFunc<0) throw("missing nFunc");
      if (!fdObj || typeof fdObj!="object") throw("missing fieldDef object");
      cat= this.categories[nCat].title.toLowerCase();
      func= this.categories[nCat].funcs[nFunc].title.toLowerCase();
      params[0]={use:false,type:"",list:[], listMult:0, required:true, dataType:"string"};
      params[1]={use:false,type:"",list:[], listMult:0, required:true, dataType:"string"};
      params[2]={use:false,type:"",list:[], listMult:0, required:true, dataType:"string"};
      if (cat=="assignment") {
        if (func=="settovalue") {
          params[0].use=true; params[0].type="text";
        }
        else if (func=="settoref") {
          params[0].use=true; params[0].type="list";
          for (let i=0; i< fdObj.fields.length; i++) {
            params[0].list.push(fdObj.fields[i].title);
          }
          params[0].list.sort();
        }
        else if (func=="settotargetref") {
          params[0].use=true; params[0].type="list";
          for (let i=0; i< fdObj.fields.length; i++) {
            txt= fdObj.fields[i].target.length>0 ? fdObj.fields[i].target : fdObj.fields[i].title;
            params[0].list.push(txt);
          }
          params[0].list.sort();
        }
        else if (func=="settobinomialdist") {
          params[0].use=true; params[0].type="integer";
          params[1].use=true; params[1].type="real";
          params[2].use=true; params[2].type="list";
          params[2].list.push("cumulative");params[2].list.push("prob mass");
        }
        else if (func=="settopoissondist") {
          params[0].use=true; params[0].type="real";
          params[1].use=true; params[1].type="list";
          params[1].list.push("cumulative");params[1].list.push("prob mass");
        }
        else if (func=="settonormaldist") {
          params[0].use=true; params[0].type="real";
          params[1].use=true; params[1].type="real";
          params[2].use=true; params[2].type="list";
          params[2].list.push("cumulative");params[2].list.push("prob density");
        }
        else if (func=="settolognormaldist") {
          params[0].use=true; params[0].type="real";
          params[1].use=true; params[1].type="real";
          params[2].use=true; params[2].type="list";
          params[2].list.push("cumulative");params[2].list.push("prob density");
        }
        else if (func=="settorandom") {
          params[0].use=true; params[0].type="real"; params[0].required=false;
          params[1].use=true; params[1].type="real"; params[1].required=false;
          params[2].use=true; params[2].type="real"; params[2].required=false;
        }
        else if (func=="settofreqlist") {
          params[0].use=true; params[0].type="text"; params[0].required=true;
          params[1].use=true; params[1].type="text"; params[1].required=true;
          params[2].use=true; params[2].type="text"; params[2].required=false;
        }
        else if (func=="lookup") {
          params[0].use=true; params[0].type="list"; 
          params[1].use=true; params[1].type="list"; params[1].listMult=2;
          for (let i=0; i< srcItems.length; i++) {
            if (typeof srcItems[i].alias=="string" && srcItems[i].alias.length>0) {
              txt= `[${srcItems[i].alias}]`;
            }
            else if (srcItems[i].type=="extdir") {
              txt= `${srcItems[i].type}:${srcItems[i].dir}${srcItems[i].title}`;
            }
            else if (srcItems[i].type=="awss3") {
              txt= `${srcItems[i].type}:${srcItems[i].title}(${srcItems[i].dir})`;
            }
            else {
            txt=srcItems[i].title;
            }
            params[0].list.push(txt);
          }
          params[0].list.sort();
          for (let i=0; i< fdObj.fields.length; i++) {
            txt= fdObj.fields[i].target.length>0 ? fdObj.fields[i].target : fdObj.fields[i].title;
            params[1].list.push(txt);
          }
          params[1].list.sort();
        }
      }
      else if (cat=="conditional") {
        if (func=="ifeq" || func=="ifnoteq") {
          params[0].use=true; params[0].type="text";
        }
        else if (func=="ifgte" || func=="ifnotgte" || func=="ifgt" || func=="ifnotgt") {
          params[0].use=true; params[0].type="text";
        }
        else if (func=="iflte" || func=="ifnotlte" || func=="iflt" || func=="ifnotlt") {
          params[0].use=true; params[0].type="text";
        }
        else if (func=="ifstreq" || func=="ifstrnoteq") {
          params[0].use=true; params[0].type="text";
          params[1].use=true; params[1].type="list"; params[1].required=false;
          params[1].list.push("false");params[1].list.push("true");
        }
        else if (func=="ifstrstarts" || func=="ifstrnotstarts") {
          params[0].use=true; params[0].type="text";
          params[1].use=true; params[1].type="list"; params[1].required=false;
          params[1].list.push("false");params[1].list.push("true");
        }
        else if (func=="ifstrcontains" || func=="ifstrnotcontains") {
          params[0].use=true; params[0].type="text";
          params[1].use=true; params[1].type="list"; params[1].required=false;
          params[1].list.push("false");params[1].list.push("true");
          params[2].use=true; params[2].type="list"; params[2].required=false;
          params[2].list.push("false");params[2].list.push("true");
        }
        else if (func=="ifstrends" || func=="ifstrnotends") {
          params[0].use=true; params[0].type="text";
          params[1].use=true; params[1].type="list"; params[1].required=false;
          params[1].list.push("false");params[1].list.push("true");
        }
        else if (func=="ifint" || func=="ifnotint") {
          params[0].use=true; params[0].type="list";params[0].required=false;
          params[1].list.push("any");params[1].list.push("positive");params[1].list.push("negative");
        }
        else if (func=="ifreal" || func=="ifnotreal") {
          params[0].use=true; params[0].type="list";params[0].required=false;
          params[1].list.push("any");params[1].list.push("positive");params[1].list.push("negative");
        }
      }
      else if (cat=="numeric") {
        if (func=="mult" || func=="div" || func=="add" || func=="subtract") {
          params[0].use=true; params[0].type="text"; params[0].dataType="real";
        }
        else if (func=="multbyref" || func=="divbyref" || func=="addbyref" || func=="subtractbyref" 
        || func=="divfromref" || func=="subtractfromref") {
          params[0].use=true; params[0].type="list"; 
          for (let i=0; i< fdObj.fields.length; i++) {
            params[0].list.push(fdObj.fields[i].title);
          }
          params[0].list.sort();
        }
        else if (func=="multrefs" || func=="addrefs") {
          params[0].use=true; params[0].type="list"; 
          params[1].use=true; params[1].type="list";
          params[2].use=true; params[2].type="list"; params[2].required=false; 
          for (let i=0; i< fdObj.fields.length; i++) {
            params[0].list.push(fdObj.fields[i].title);
            params[1].list.push(fdObj.fields[i].title);
            params[2].list.push(fdObj.fields[i].title);
          }
          params[0].list.sort();
          params[1].list.sort();
          params[2].list.sort();
        }
        else if (func=="setdecimal") {
          params[0].use=true; params[0].type="text"; params[0].dataType="integer";
        }
      }
      else if (cat=="text") {
        if (func=="front" || func=="end") {
          params[0].use=true; params[0].type="text"; 
        }
        else if (func=="frontn" || func=="endn") {
          params[0].use=true; params[0].type="text"; params[0].dataType="integer";
        }
        else if (func=="mid") {
          params[0].use=true; params[0].type="text"; 
          params[1].use=true; params[1].type="text"; params[1].required=false; 
        }
        else if (func=="midn") {
          params[0].use=true; params[0].type="text"; params[0].dataType="integer";
          params[1].use=true; params[1].type="text"; params[1].dataType="integer"; params[1].required=false; 
        }
        else if (func=="charat") {
          params[0].use=true; params[0].type="text"; params[0].dataType="integer";
        }
        else if (func=="prepend" || func=="append" || func=="remove") {
          params[0].use=true; params[0].type="text"; 
        }
        else if (func=="replace") {
          params[0].use=true; params[0].type="text"; 
          params[1].use=true; params[1].type="text"; 
        }
        else if (func=="setlength") {
          params[0].use=true; params[0].type="text"; params[0].dataType="integer";
          params[1].use=true; params[1].type="text"; params[1].required=false; 
          params[2].use=true; params[2].type="text"; params[2].required=false;           
        }
      }
      else if (cat=="date") {
        if (func=="settoisodate") {
          params[0].use=true; params[0].type="text"; params[0].required=false;
        }
        else if (func=="datetoiso"){
          params[0].use=true; params[0].type="list"; 
          params[0].list.push("mmddyy"); params[0].list.push("mmdyy");params[0].list.push("mdyy");
          params[0].list.push("mmddyyyy");params[0].list.push("mmdyyyy");params[0].list.push("mdyyyy");
          params[0].list.push("ddmmyy");params[0].list.push("ddmyy");params[0].list.push("dmyy");
          params[0].list.push("ddmmyyyy");params[0].list.push("ddmyyyy");params[0].list.push("dmyyyy");
          params[0].list.push("yymmdd");params[0].list.push("yymmd");params[0].list.push("yymd");
          params[0].list.push("yyyymmdd");params[0].list.push("yyyymmd");params[0].list.push("yyyymd");
          params[0].list.push("yyyyddd");params[0].list.push("yyyyMMMdd");params[0].list.push("yyMMMdd");
          params[0].list.push("ddMMMyyyy");params[0].list.push("ddMMMyy");
        }
      }
    }
    catch(e) {
      params[0]={type:`notok:${e}`};
    }
    return params;
  },
  CheckFuncParams(nCat, nFunc, fdObj, srcItems, formOpObj) {
    let cat="", func="", txt="", result="", title="", title1="", title2="";
    let flag=false, flag1=false, flag2=false;
    let n1=-1;
    let params=[], temp=[], temp1=[], hashAliases=[];
    try {
      if (typeof nCat!="number" || nCat<0) throw("missing nCat");
      if (typeof nFunc!="number" || nFunc<0) throw("missing nFunc");
      if (!formOpObj || typeof formOpObj!="object") throw("missing Op object");
      cat= this.categories[nCat].title.toLowerCase();
      func= this.categories[nCat].funcs[nFunc].title.toLowerCase();
      params[0]=params[1]=params[2]={use:false,type:"",list:[], required:true, dataType:"string"};
      for (let i=0; i<srcItems.length; i++) {
        if (typeof srcItems[i].alias=="string" && srcItems[i].alias.length>0) {
          hashAliases[srcItems[i].alias.toLowerCase()]=i;
        }
      }
      if (cat=="assignment") {
        if (func=="settoref") {
          if (formOpObj.param1.length==0) result="SetToRef: missing source field reference";
          else {
            title=formOpObj.param1.toLowerCase();
            for (let i=0; i< fdObj.fields.length; i++) {
              txt= fdObj.fields[i].title;
              if (title==txt.toLowerCase()) {
                flag=true;
                break;
              }
            }
            if (!flag) result="SetToRef: not a current source field";
          }
        }
        else if (func=="settotargetref") {
          if (formOpObj.param1.length==0) result="SetToTargetRef: missing target field reference";
          else {
            title=formOpObj.param1.toLowerCase();
            for (let i=0; i< fdObj.fields.length; i++) {
              txt= fdObj.fields[i].target.length>0 ? fdObj.fields[i].target : fdObj.fields[i].title;
              if (title==txt.toLowerCase()) {
                flag=true;
                break;
              }
            }
            if (!flag) result="SetToTargetRef: not a current target field";
          }
        }
        else if (func=="settobinomialdist") {
          txt="";
          if (VRC.Contains(formOpObj.param1,"(",false)) {
            txt= formOpObj.param1.substring(0,formOpObj.param1.indexOf("(")).trim();
            txt=txt.substring(0, txt.indexOf(")"));
            formOpObj.param1= formOpObj.param1.substring(0, formOpObj.param1.indexOf("(")).trim();
          }
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 integer number`;
          else if (formOpObj.param2.length==0) result=`${func.toUpperCase()}: missing Param2 real number 0.0-1.0`;
          else if (formOpObj.param3.length==0) result=`${func.toUpperCase()}: missing Param3 type`;
          else if (!VRC.IsPosInt(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 not positive integer number`;
          else {
            n1=parseFloat(formOpObj.param2);
            if (n1<0.0 || n1>1.0) result=`${func.toUpperCase()}: Param2 is too large, not real number 0.0-1.0`;
          }
          if (txt.length>0 && result.length==0) {
            if (VRC.IsPosInt(txt) && parseInt(txt)> parseInt(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 has integer specified for measurement ${txt} which is larger than number of trials ${formOpObj.param1}`;
          }
        }
        else if (func=="settopoissondist") {
          txt="";
          if (VRC.Contains(formOpObj.param1,"(",false)) {
            txt= formOpObj.param1.substring(0,formOpObj.param1.indexOf("(")).trim();
            txt=txt.substring(0, txt.indexOf(")"));
            formOpObj.param1= formOpObj.param1.substring(0, formOpObj.param1.indexOf("(")).trim();
          }
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 real number > 0`;
          else if (formOpObj.param2.length==0) result=`${func.toUpperCase()}: missing Param2 type`;
          else if (!VRC.IsPosReal(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 not real number > 0`;
        }
        else if (func=="settonormaldist") {
          txt="";
          if (VRC.Contains(formOpObj.param1,"(",false)) {
            txt= formOpObj.param1.substring(0,formOpObj.param1.indexOf("(")).trim();
            txt=txt.substring(0, txt.indexOf(")"));
            formOpObj.param1= formOpObj.param1.substring(0, formOpObj.param1.indexOf("(")).trim();
          }
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 real number`;
          else if (formOpObj.param2.length==0) result=`${func.toUpperCase()}: missing Param2 real number`;
          else if (formOpObj.param3.length==0) result=`${func.toUpperCase()}: missing Param3 type`;
          else if (!VRC.IsReal(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 not real number`;
          else if (!VRC.IsPosReal(formOpObj.param2)) result=`${func.toUpperCase()}: Param2 not positive real number`;
        }
        else if (func=="settolognormaldist") {
          txt="";
          if (VRC.Contains(formOpObj.param1,"(",false)) {
            txt= formOpObj.param1.substring(0,formOpObj.param1.indexOf("(")).trim();
            txt=txt.substring(0, txt.indexOf(")"));
            formOpObj.param1= formOpObj.param1.substring(0, formOpObj.param1.indexOf("(")).trim();
          }
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 real number`;
          else if (formOpObj.param2.length==0) result=`${func.toUpperCase()}: missing Param2 real number`;
          else if (formOpObj.param3.length==0) result=`${func.toUpperCase()}: missing Param3 type`;
          else if (!VRC.IsPosReal(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 not positive real number`;
          else if (!VRC.IsPosReal(formOpObj.param2)) result=`${func.toUpperCase()}: Param2 not positive real number`;
        }
        else if (func=="settorandom") {
          if (formOpObj.param1.length>0 && !VRC.IsReal(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 not real number`;
          else if (formOpObj.param2.length>0 && !VRC.IsReal(formOpObj.param2)) result=`${func.toUpperCase()}: Param2 not real number`;
          else if (formOpObj.param3.length>0 && !VRC.IsPosReal(formOpObj.param3)) result=`${func.toUpperCase()}: Param3 not real number`;
          else if (parseFloat(formOpObj.param3)>1) result=`${func.toUpperCase()}: Param3 is too large (> 1)`;
        }
        else if (func=="settofreqlist") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 list of frequencies`;
          else if (formOpObj.param2.length==0) result=`${func.toUpperCase()}: missing Param2 list of values`;
          else {
            temp=formOpObj.param1.split("|");
            temp1=formOpObj.param2.split("|");
            if (temp.length!=temp1.length) result=`${func.toUpperCase()}: Param1 and Param2 have different number of list members ${temp.length} and ${temp1.length}`;
            else {
              for (let i=0; i< temp.length; i++) {
                txt=temp[i].trim();
                if (txt.length==0 || !VRC.IsReal(txt)) {
                  result=`${func.toUpperCase()}: Param1 list contains a non-number: ${txt}`;
                  break;
                }
              }
            }
          }
        }
        else if (func=="lookup") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase}: missing source file reference`;
          if (result.length>0) flag=false;
          else {
            flag=false;
            title=formOpObj.param1.toLowerCase();
            if (VRC.StartsWith(title,"[",false) && VRC.EndsWith(title,"]",false)) {
              title= title.substring(1);
              title=title.substring(0, title.length-1);
              if (typeof hashAliases[title]=="number") flag=true;
            }
            else {
            for (let i=0; i< srcItems.length; i++) {
                if (srcItems[i].type=="extdir") {
                  txt= `${srcItems[i].type}:${srcItems[i].dir}${srcItems[i].title}`;
                }
                else if (srcItems[i].type=="awss3") {
                  txt= `${srcItems[i].type}:${srcItems[i].title}(${srcItems[i].dir})`;
                }
                else {
              txt=srcItems[i].title;
              }
              if (title==txt.toLowerCase()) {
                flag=true;
                break;
                }
              }
            }
            if (!flag) result=`${func.toUpperCase}: missing source file reference ${title}`;
            else if (formOpObj.param2.length>0) {
              temp=formOpObj.param2.split("|");
              temp1=[];
              for (let i=0; i<temp.length; i++) {
                txt=temp[i].toLowerCase().trim();
                if (txt.length>0) {
                  if (typeof fdObj.hashFields[txt]=="number") temp1.push(temp[i].trim());
                  else {
                    result=`${func.toUpperCase}: specified field is not in current Field Definition ${txt}`;
                    flag=false;
                    break;
                  }
                }
              }
            }
          }
        }
      }
      else if (cat=="conditional") {
        if (func=="ifeq" || func=="ifnoteq" 
        || func=="ifgte" || func=="ifnotgte" || func=="ifgt" || func=="ifnotgt" 
        || func=="iflte" || func=="ifnotlte" || func=="iflt" || func=="ifnotlt") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 real number`;
          else if (!VRC.IsNumber("real", formOpObj.param1, false)) result=`${func.toUpperCase()}: Param1 not real number`;
        }
        else if (func=="ifstreq" || func=="ifnotstreq" 
        || func=="ifstrstarts" || func=="ifnotstrstarts" 
        || func=="ifstrends" || func=="ifnotstrends" 
        || func=="ifstrcontains" || func=="ifnotstrcontains") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 value`;
          else {
            if (formOpObj.param2.length>0) {
              txt=formOpObj.param2.toLowerCase();
              if (txt!="false" && txt!="true") result=`${func.toUpperCase()}: Param2 must be true or false if used`;
            }
            if (result.length==0 && formOpObj.param3.length>0) {
              txt=formOpObj.param3.toLowerCase();
              if (txt!="false" && txt!="true") result=`${func.toUpperCase()}: Param3 must be true or false if used`;
            }
          }
        }
        else if (func=="ifint" || func=="ifnotint" || func=="ifreal" || func=="ifnotreal") {
          if (formOpObj.param1.length>0) {
            txt=formOpObj.param1.toLowerCase();
            if (txt!="any" && txt!="positive" && txt!="negative") result=`${func.toUpperCase()}: Param1 must be any, positive, negative if used`;
          }
        }
        else if (func=="ifdateformat" || func=="ifnotdateformat") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 value`;
        }
      }
      else if (cat=="numeric") {
        if (func=="mult" || func=="div" || func=="add" || func=="subtract") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 real number`;
          else if (!VRC.IsNumber("real", formOpObj.param1, false)) result=`${func.toUpperCase()}: Param1 not real number`;
        }
        else if (func=="multbyref" || func=="divbyref" || func=="addbyref" || func=="subtractbyref" 
        || func=="divfromref" || func=="subtractfromref") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing source field reference`;
          else {
            title=formOpObj.param1.toLowerCase();
            for (let i=0; i< fdObj.fields.length; i++) {
              txt= fdObj.fields[i].title;
              if (title==txt.toLowerCase()) {
                flag=true;
                break;
              }
            }
            if (!flag) result=`${func.toUpperCase()}: field reference is not in current source fields`;
          }
        }
        else if (func=="multrefs" || func=="addrefs") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Parm1 source field reference`;
          else if (formOpObj.param2.length==0) result=`${func.toUpperCase()}: missing Parm2 source field reference`;
          else {
            title=formOpObj.param1.toLowerCase();
            title1=formOpObj.param2.toLowerCase();
            title2=formOpObj.param3.toLowerCase();
            for (let i=0; i< fdObj.fields.length; i++) {
              txt= fdObj.fields[i].title.toLowerCase();
              if (title==txt) flag=true;
              else if (title1==txt) flag1=true;
              else if (title2==txt) flag2=true;
            }
            if (!flag) result=`${func.toUpperCase()}: Param1 field reference is not in current source fields`;
            else if (!flag1) result=`${func.toUpperCase()}: Param2 field reference is not in current source fields`;
            else if (formOpObj.param3.length>0 && !flag2) result=`${func.toUpperCase()}: Param3 field reference is not in current source fields`;
          }
        }
        else if (func=="setdecimal") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 integer number`;
          else if (!VRC.IsPosInt(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 not positive integer number`;
        }
      }
      else if (cat=="text") {
        if (func=="front" || func=="end") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 string`;
        }
        else if (func=="frontn" || func=="endn") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 integer number`;
          else if (!VRC.IsPosInt(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 not integer number`;
        }
        else if (func=="mid") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 string`;
        }
        else if (func=="midn") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 integer number`;
          else if (!VRC.IsPosInt(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 not integer number`;
          else if (formOpObj.param2.length>0 && !VRC.IsPosInt(formOpObj.param2)) result=`${func.toUpperCase()}: Param2 not integer number`;
        }
        else if (func=="charat") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 integer number`;
          else if (!VRC.IsPosInt(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 not integer number`;
        }
        else if (func=="prepend" || func=="append" || func=="remove") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 string`;
        }
        else if (func=="replace") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 string`;
          else if (formOpObj.param2.length==0) result=`${func.toUpperCase()}: missing Param2 string`;
        }
        else if (func=="setlength") {
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 integer number`;
          else if (!VRC.IsPosInt(formOpObj.param1)) result=`${func.toUpperCase()}: Param1 not integer number`;
          else if (formOpObj.param2.length>0 && formOpObj.param2!="left" && formOpObj.param2!="right") result=`${func.toUpperCase()}: Param2 must be either left, right if used`;         
        }
      }
      else if (cat=="date") {
        if (func=="datetoiso"){
          if (formOpObj.param1.length==0) result=`${func.toUpperCase()}: missing Param1 date format`;
          else {
            title=formOpObj.param1.toLowerCase();
            if (title!="mmddyy" && title!="mmdyy" && title!="mdyy" 
            && title!="mmddyyyy" && title!="mmdyyyy" && title!="mdyyyy" 
            && title!="ddmmyy" && title!="ddmyy" && title!="dmyy" 
            && title!="ddmmyyyy" && title!="ddmyyyy" && title!="dmyyyy" 
            && title!="yymmdd" && title!="yymmd" && title!="yymd" 
            && title!="yyyymmdd" && title!="yyyymmd" && title!="yyyymd" 
            && title!="yyyyddd" && title!="yyyyMMMdd" && title!="yyMMMdd" 
            && title!="ddMMMyyyy" && title!="ddMMMyy") {
              result=`${func.toUpperCase()}: Param1 is not allowable date format`;
            }
          }
        }
      }
    }
    catch(e) {
      result=`notok:${e}`;
    }
    return result;
  },
  GetTransformStr(transformObj) {
    let result="";
    try {
      if (!transformObj || typeof transformObj!="object") throw("missing transform object");
      result= "transform:[{title:" + transformObj.title + "}";
      result += `,{testval:${transformObj.testVal}}`;
      for (let i=0; i< transformObj.ops.length; i++) {
        result += ",{op:" 
        + `title=${transformObj.ops[i].title}`
        + `,param1=${transformObj.ops[i].param1}`
        + `,param2=${transformObj.ops[i].param2}`
        + `,param3=${transformObj.ops[i].param3}`        
        + "}";
      }
      result += "]";
    }
    catch(e) {
      result=`notok:${e}`;
    }
    return result;
  },
  CheckRangeValue(rngIn) {
    let result="", rng="", txt="", txt1="", op="", op1="";
    let flag=false, flag1=false;
    try {
      if (!rngIn || typeof rngIn!="string" || rngIn.length==0) return result;
      txt=rngIn;
      if (VRC.StartsWith(txt, "eq", false) || (!VRC.StartsWith(txt, "gt", false) && !VRC.StartsWith(txt, "lt", false))) {
        if (VRC.StartsWith(txt, "eq", false)) txt = txt.substring(2);
        if (VRC.Contains(txt, "gt", false)) txt = txt.substring(0, txt.indexOf("gt"));
        else if (VRC.Contains(txt, "lt", false)) txt = txt.substring(0, txt.indexOf("lt"));
        if (!VRC.IsNumber("real", txt, false)) {
          throw ("range starting with eq or being number must be real not: " + txt);
        }
        else rng = txt;
      }
      else if (VRC.StartsWith(txt, "gt", false) || VRC.StartsWith(txt, "lt", false)) {
        if (VRC.StartsWith(txt, "gte", false)) {
          op = "gte";
          txt = txt.substring(3);
        }
        else if (VRC.StartsWith(txt, "lte", false)) {
          op = "lte";
          txt = txt.substring(3);
        }
        else if (VRC.StartsWith(txt, "gt", false)) {
          op = "gt";
          txt = txt.substring(2);
        }
        else if (VRC.StartsWith(txt, "lt", false)) {
          op = "lt";
          txt = txt.substring(2);
        }
        if (VRC.StartsWith(txt, "-", false)) {
          flag = true;
          txt = txt.substring(1);
        }
        txt1 = "";
        if (VRC.Contains(txt, "gte", false)) {
          op1 = "gte";
          txt1 = txt.substring(txt.indexOf("gte") + 3);
          txt = txt.substring(0, txt.indexOf("gte"));
        }
        else if (VRC.Contains(txt, "lte", false)) {
          op1 = "lte";
          txt1 = txt.substring(txt.indexOf("lte") + 3);
          txt = txt.substring(0, txt.indexOf("lte"));
        }
        else if (VRC.Contains(txt, "gt", false)) {
          op1 = "gt";
          txt1 = txt.substring(txt.indexOf("gt") + 2);
          txt = txt.substring(0, txt.indexOf("gt"));
        }
        else if (VRC.Contains(txt, "lt", false)) {
          op1 = "lt";
          txt1 = txt.substring(txt.indexOf("lt") + 2);
          txt = txt.substring(0, txt.indexOf("lt"));
        }
        if (VRC.StartsWith(txt1, "-", false)) {
          flag1 = true;
          txt1 = txt1.substring(1);
        }
        if (!VRC.IsNumber("real", txt, false)) {
          if (flag) txt = "-" + txt;
          throw (`range starts with operator ${op} but is not followed by real ${txt}`);
        }
        if (op1.length>0 && !VRC.IsNumber("real", txt1, false)) {
          if (flag1) txt1 = "-" + txt1;
          throw (`range includes second operator ${op1} which is not followed by real ${txt1}`);
        }
        if (flag) txt = "-" + txt;
        if (flag1) txt1 = "-" + txt1;
        rng = op + txt + op1 + txt1;
      }
      else {
        throw ("unknown range syntax: " + txt);
      }
      result= rng;
    }
    catch(e) {
      result=`notok:${e}`;
    }
    return result;
  },
}