<template>
    <v-container class="pa-0 ma-0" style="max-width: 100%;">
      <v-row>
        <v-col cols="12">
          <div>
            <v-img 
              height="225"
              src="../assets/images/rings_1000w.png"          
            />
          </div>
        </v-col>
      </v-row>

      <v-card-text class="px-16 mt-4 text-body-1">
        <ul>
          <li>
            <b>Why High Fidelity:</b> Certain uses require accurate, enriched, tracked data 
            with thorough Quality Control. This includes financial audit, 
            stock & fund investment models, risk underwriting, 
            engineering modeling, supply chain planning, 
            AI & Machine Learning, public health models, and other high-impact uses.
          </li>
          <li>
            <b>Why VerityX:</b> In order to achieve High Fidelity, we must successfully handle all 
            problems & inconsistencies in the data even when they are not detected nor 
            suspected by usual methods. This situation is commonly called an 'edge' case 
            and pushed aside for future attention. However, a high impact use cannot 
            make do with the sub-optimum data nor wait for a possible future solution. The nature 
            of some of the problems & inconsistencies makes them undetectable and unsolvable by standard approaches 
            since they can be very small occurences within very large data sets, or have characteristics  
            outside of the capabilities of mainstream data management and governance. This forces each person and 
            group who must work with the data to be mired in highly manual, tedious, and error prone techniques 
            using home-made custom approaches. The Verity<u>X</u> products, VerityRefiner & VerityExcel, 
            are totally focused on this area and have been built around decades worth of expert lessons 
            learned, specialized techniques, and production tested automated algorithms to 
            both detect deeply buried problems and fix and track them. The embodied expertise 
            spans many fields including science, computational modeling, 
            Big Data, Enterprise Architecture, domain knowledge  
            in accounting, personnel, risk, command & control, and many others.
          </li>
          <li>
            <b>What is Data Intelligence:</b> This has two primary meanings depending on which 
            word has emphasis. When 'Data' is emphasized, it means knowing everything that can 
            be known about the data itself and constantly working to learn more and make use 
            of this knowledge. When 'Intelligence' is emphasized, it means the work to produce 
            High Fidelity data and track its quality, trust, and 
            scope of use is the predecessor step to the real work of using it to discover 
            valuable knowledge and reliably predict future scenarios. 
            It is critical when the ramifications of even small undetected 
            errors or biases can be hugely damaging. Examples include 
            engineering design (e.g. automobiles, bridges, space vehicles),
            large dollar stock & fund strategies, real estate investments, risk underwriting, 
            medical responses to new diseases, forensic accounting, legal discovery, 
            terrorism prevention, human trafficking intelligence, 
            Machine Learning/Artifical Intelligence training.
          </li>
          <li>
            <b>Getting Started:</b>
            <ol>
              <li>Read more about VerityX products: VerityExcel and VerityRefiner</li>
              <li>Determine if one or both fit your needs</li>
              <li>Go to each product's web site and make account</li>
              <li>Load data files</li>
              <li>Analyze & Refine data into High Fidelity</li>
              <li>Use refined data in analytics and computer models</li>
            </ol>
          </li>
        </ul>
      </v-card-text>
      <v-row class="mx-0 mt-6 mb-0 text-body-1" style="background-color: white;">
        <v-col cols="6">
          <v-sheet 
            class="ml-8 my-4"
            color="blue-grey darken-4"
            elevation="3"
            width="92%" 
            height="95%"
            rounded="xl"
          >
            <p class="font-weight-bold text-h5 text-center my-0 pt-6" style="color:whitesmoke;">
              VerityExcel
            </p>
            <p class="mx-10 my-4 pb-4" style="color:whitesmoke;">
              Automates the complicated and laborious process of moving data into and out of 
              Microsoft Excel spreadsheets without suffering number and format corruption. Excel changes 
              number formatting during both importing and exporting which frequently causes significant problems 
              for analytics, generating reports, and sharing data in files and databases. 
              Controlling its behavior requires advanced skills and is time-consuming. 
              One such troublesome situation occurs for long values such as 1234567890123.45 that Excel 
              changes in <u>both speadsheet and exported as 1.23457E+12</u>. Another is 
              changing codes like 001234 into 1234.
              This introduces hidden errors into downstream products. VerityExcel uses 
              built-in expert techniques & optional user specified formats and transforms to ensure 
              correct data. Data can start and end in either text files 
              or Excel spreadsheets.
            </p>
          </v-sheet>
        </v-col>
        <v-col cols="6">
          <v-sheet 
            class="mr-8 my-4"
            color="blue-grey darken-4"
            elevation="3"
            width="92%" 
            height="95%"
            rounded="xl"
          >
            <p class="font-weight-bold text-h5 text-center my-0 pt-6" style="color:whitesmoke;">
              VerityRefiner
            </p>
            <p class="mx-10 my-4 pb-4" style="color:whitesmoke;">
              A powerful specialty tool using 
              expert techniques to find deeply buried, atypical, complex 
              data errors and apply production grade algorithms to automatically correct 
              and enhance the data. It handles 'edge cases' standard tools 
              do not such as: errant parsing, records broken into multi-line, 
              number corruption, file encoding failures, multi-language special 
              characters, sporadic errors 
              in very large volumes (e.g. TBs). A deep inspection 
              engine generates multiple views to greatly increase visibility 
              into data quality and potential problems before they spread into downstream 
              applications. Flexible source-target mapping, added enrichment metadata, 
              value transforms (including conditionality) eliminates much manual effort 
              while providing crucial High Fidelity. It can generate synthetic data 
              with several statistical distributions to evaluate models and analytics.
            </p>
          </v-sheet>
        </v-col>
      </v-row>
      <div class="text-body-1" style="height:40px; background-color: white; color:darkorange;">
        <span class="mr-10 font-weight-bold" style="float:right;">
          Examples, Sample data, Guides shown below
        </span>
      </div>
      <v-sheet class="mx-16 mt-10 text-body-1">
        <p class="text-h4 mb-4">
          Subscription Plans
        </p>
        <p class="pb-10">
          To use either product follow these steps:
          <ol class="mt-6">
            <li>Go to the product's web site using the buttons below</li>
            <li>Create an account and log in</li>
            <li>Navigate to edit account settings and choose your plan</li>
            <li>Start making High Fidelity data using context help and tutorials as needed</li>
          </ol>
        </p>
        <v-data-table
          :headers="planTable.cols" 
          :items="planTable.items" 
          item-key="id" 
          class="" 
          flat
          dense 
          hide-default-footer
          :items-per-page="15" 
          loading="true" 
          loading-text="Loading data"
        />
        <p class="text-caption mt-10">
          <ol>
            <li>VE= VerityExcel, VR= VerityRefiner</li>
            <li>
              All plans require a validated user account in the product being used. This includes following 
              cybersecurity guidance, protecting user privacy, and authenticating user identity when requested.
            </li>
            <li>Annual discounts are only applied for purchase of full year instead of per month</li>
            <li>
              Refunds are possible only within the first seven days and are pro-rated to remaining time of 
              paid subscription. All refunds incur a 20% administrative fee that is deducted from prorated amount due. 
              If this final amount is less than or equal to $5 USD then no refund will be provided
            </li>
            <li>Max Storage refers to in application not to networked nor Cloud</li>
            <li>
              Networked dirs are external disk directories that are accessible to the VerityX 
              server process when installed in a client's environment with a Custom license
            </li>
            <li>
              Cloud dirs are folders on a Cloud provider that can be accessed by VerityX process 
              using the client supplied credentials. At this time, AWSS3 is supported 
              although additional Cloud storage services will be added soon. This is the primary location for 
              very large files.
            </li>
            <li>
              Max records refers to per job. VerityExcel is constrained by the 1.04 million Excel limit; 
              VerityRefiner custom install has no limit except for performance limitations with extremely large data sets
            </li>
            <li>Max Jobs is total for month beginning 1st of that month, except for trial which is for the one week period</li>
            <li>Contact us at info@technikinterlytics.com for information on custom licenses and installing on client computers</li>
          </ol>
        </p>
        <div class="mt-12 mx-16">
          <span class="text-h5 font-weight-bold indigo--text">
            GET STARTED
            <v-icon class="mb-1 indigo--text">mdi-chevron-triple-right</v-icon>
          </span>
          <v-btn class="mx-16" color="teal accent-3" @click="GoToVerityX('verityexcel')">
            Go To VerityExcel
          </v-btn>
          <v-btn class="mx-2" color="blue accent-1" @click="GoToVerityX('verityrefiner')">
            Go To VerityRefiner
          </v-btn>
        </div>
      </v-sheet>
      <v-divider class="mt-6" />
      <v-sheet class="mx-16 mt-10">
        <v-row>
          <v-col cols="6">
            <v-card>
              <p class="font-weight-bold text-h5 text-center mx-10 my-0" style="color:darkorange">
                Introduction to High Fidelity Data
              </p>
              <p class="mx-10 my-4 pb-4 text-body-2">
                &nbsp;&nbsp;&nbsp;The world of "data" has exploded in recent years from an once arcane 
                background grind by engineers into a field promising "democratization" so that non-experts can 
                use many new powerful tools to organize, blend, and make analytic results and even perform 
                sophisticated computing activities such as Machine Learning. The advent of these tools has 
                significantly advanced the ease and power of data management tools, which I use as a broad 
                term encompassing many formerly distinct fields like quality, governance, integration, 
                databasing, etc..
                <br>
                &nbsp;&nbsp;&nbsp;I coin the term <b>High Fidelity Data</b> to separate it from mainstream data 
                management (in all its forms) and align it with other fields where the concept of High 
                Fidelity distinguishes it from the standard approaches in that field. 
                <br>
                <i>&nbsp;&nbsp;It is the culture, tools, and techniques using human expertise 
                to automate many of the laborious and complex challenges in 
                detecting, correcting, normalizing, and auditing into 
                high Signal-to-Noise (SNR) data trustworthy and fit for 
                high-impact results.</i>
                <br>
                <a href="https://technikinterlytics.com/blog/index.php/blog/?title=introduction-to-high-fidelity-data&more=1" target="_">Go to Blog</a>
              </p>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <p class="font-weight-bold text-h5 text-center mx-10 my-0" style="color:darkorange">
                Edge Case Data Defects
              </p>
              <p class="mx-10 my-4 pb-4 text-body-2">
                &nbsp;&nbsp;&nbsp;In the previous blog, I introduced the concept of <b>High Fidelity Data</b> as a 
                field and not just an offshoot of data management or data governance. Now, I will showcase some of 
                the more egregious examples of data defects that are more widespread than typically known, usually 
                undetected by routine processing, and create significant challenges to solve.
                <br>
                &nbsp;&nbsp;&nbsp;<b>Broken Records:</b>This situation occurs when data is passed 
                around in text files that are structured so one record is in one line in the file, 
                but some records are spread over several lines. I have seen this problem in many 
                large organization's data systems. The root cause is often legacy data made when 
                it was common to embed line feeds and delimiters within field values so reports 
                and web sites could present just what was saved without additional processing. 
                What makes this so egregious is that the indicators seen in modern tools focus 
                on the perspective of field oriented quality so that deviations in per field values 
                of data type, format, and ranges are apparent but imply corrective actions should be 
                done per field. This false analysis can lead to a large amount of wasted effort and time. 
                <br>
                <a href="https://technikinterlytics.com/blog/index.php/blog/?title=edge-case-data-defects&more=1" target="_">Go to Blog</a>
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
      <v-divider class="mt-6" />
      <v-sheet class="mx-8 mt-12 text-body-2">
        <p class="text-h4 mb-10">
          More Information
        </p>
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6" @click="SetScroll(0)">
              Example Excel Problems
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <p>
                We begin with a simple csv text file. Note the values with many digits and the value with leading zeros.
              </p>
              <figure>
                <img src="../assets/images/excel_conversion_orig_text.png" alt="original CSV">
                <figcaption>Initial CSV file</figcaption>
              </figure>
              <p>
                Windows has default application assignments for many file extensions. CSV is assigned to Microsoft Excel 
                so double clicking on a CSV file name will cause it to be automatically imported into Excel which will 
                open to show the file. Note that this is not an actual spreadsheet and is not saved in Excel format (xlsx extension). 
                To do so requires performing a SAVE AS function and selecting Excel workbook as the output type. 
              </p>
              <figure>
                <img src="../assets/images/excel_conversion_importedfromCSV.png" alt="imported from CSV">
                <figcaption>CSV imported into Excel by opening file</figcaption>
              </figure>
              <br><h3>Importing from CSV</h3>
              <p>
                This example shows what happens with the most common user action of double clicking on a 
                CSV file to open it in Excel. You must expand the columns to see the full values. We see 
                that the displayed values do not correpsond exactly to our source file.
                <ul>
                  <li>
                    left justified values such as cell B2's ABC0123 are assigned string data type by Excel, 
                    whereas right justified values such as cell C2's 345.23 are assigned numeric data type
                  </li>
                  <li>
                    cell C3 which is the amount for item2 is missing its .01 end digits
                  </li>
                  <li>
                    cell B5 which is the code for item4 has been changed into exponential format and shows only 6 of 19 digits
                  </li>
                  <li>
                    cell C5 which is the amount for item4 has been changed into exponential format and shows only 6 of 20 digits
                  </li>
                  <li>
                    cell C6 which is the amount for item5 has been changed into exponential format and shows only 6 of 14 digits without the fractional end of .45
                  </li>
                  <li>
                    cell C7 which is the amount for item6 is missing its leading 00 digits
                  </li>
                </ul>
              </p>
              <p>
                Several of the changes are only presentation styling with the actual original value still there. 
                Cell C3 still has the actual original value despite not showing all digits.
              </p>
              <figure>
                <img src="../assets/images/longreal_viewnodec_actualhasdec_inexcel.png" alt="long real number still has actual value">
                <figcaption>Cell C3 a long real number is actually still the original value despite the cell not showing all digits</figcaption>
              </figure>
              <p>
                The same situation exists for the truncated and exponential format displayed in C6 which is actually still the original value including the end .45 fractional digits.
              </p>
              <figure>
                <img src="../assets/images/longreal_viewexp_actualstillreal_inexcel.png" alt="exponential real number still has actual value">
                <figcaption>Cell C6 truncated and exponential format is actually still the original value</figcaption>
              </figure>
              <p>
                Cell B5 has the same situation with truncated and exponential format displayed which is actually still the original value of a long sequence of digits.
              </p>
              <figure>
                <img src="../assets/images/longint_expview_orignumber_inexcel.png" alt="exponential integer number still has actual value">
                <figcaption>Cell B5 truncated and exponential format long integer code is actually still the original value</figcaption>
              </figure>
              <p>
                In contrast, Excel has changed the value in C7 permanently by assigning it a numeric data type and thereby (correctly if it is numeric) removing the leading zeros. 
                However, this is a serious data corruption if instead of being a numeric amount the same original digits were in the code column.
              </p>
              <figure>
                <img src="../assets/images/leadzeroint_zeroremoved_inexcel.png" alt="leading zeros permanently removed">
                <figcaption>Cell C7 leading zeros removed so no longer original value</figcaption>
              </figure>
              <br><h3>Fixing Data Inside Excel</h3>
              <p>
                Excel has many functions enabling simple to complicated formatting and transformations of data both 
                during the importing process as well as within the spreadsheet. While powerful, they require both 
                advanced expertise and many more manual steps than the simple automated open and import function. Now, 
                we will see some of the ways we can adjust values after opening the CSV file. First, we change 
                the file into a stored Excel workbook by doing FILE-SAVE AS-(choose your folder)-(change Save_as_type 
                to be Excel Workbook).
              </p>
              <figure>
                <img src="../assets/images/savedasexcel.png" alt="saved as Excel workbook">
                <figcaption>Data 'Saved As' Excel workbook indicated by .xlsx extension in title bar</figcaption>
              </figure>
              <p>
                The easiest and most obvious thing to do in Excel is to select the columns (e.g. click letter B in header for entire column) 
                and then use Home ribbon panel to select Number Format which shows default of General and choose 
                from pick list TEXT. Follow same for column C but choose NUMBER. 
              </p>
              <figure>
                <img src="../assets/images/excel_setcoltypes.png" alt="column data types manually set">
                <figcaption>Columns B and C have had their data types manually set to TEXT and NUMBER respectively</figcaption>
              </figure>
              <p>
                Now column C values are all shown as real numbers with decimal parts. However, even though we set column B to be TEXT 
                the value in B5 is still in exponential format.
              </p>
              <p>
                The next method to use in Excel is part of the 
                built-in functions for database querying and data model specification. To test this. close Excel then reopen 
                it directly without a file (Windows START menu). You begin with an empty workbook. Then do DATA-FROM TEXT/CSV. 
                Select the file to import (the sample CSV we are using) and you are then shown a table of file records 
                split into columns based on Excel detecting the delimiter (in this case a comma) and also 
                reading a header row for column titles. Select TRANSFORM DATA and a transformation window is shown. 
                Here, you can do a lot of transform functions. For this simple case, we see that column B is 
                assigned a text type, and column C a number type. Click CLOSE & LOAD in menu ribbon. 
              </p>
              <figure>
                <img src="../assets/images/importvia_transformdata.png" alt="data after importing using manual peocess">
                <figcaption>Data after importing with manual process.</figcaption>
              </figure>
              <p>
                The data is now placed in a formatted table with all values in column B correctly 
                assigned text data type so that cell B5 is no longer displayed in exponential format. 
                However, the large values in column C are still changed to exponential in C5 and C6. 
                Once again, you can manually do additional steps such as selecting column C and 
                changing formatting to Accounting, or even selecting indiviudal cells. But, this 
                is clearly not a fast, automated, error-free method to use over and over with many 
                files.
              </p>
              <br><h3>The Big Problem: Exporting From Excel</h3>
              <p>
                While working inside Excel there are many functions available to manipulate 
                formatting and transform data values and types. These work well but consume time 
                and require greater expertise thereby slowing down your work progress. However, 
                the more serious problem arises when sharing data among colleagues during 
                realistic workflows when data moves into and out of multiple types of 
                data systems including databases, web application, Excel, text editors, and 
                even Data Science tools like Jupyter Lab and R Studio. Without high levels 
                of data control, accuracy, and trustworthy handling small errors can 
                propagate unseen causing failures in many downstream uses. Unfortunately, this is 
                a very common occurrence and the ramifications are 
                serious corruption of data analytics results, Data Science products, 
                database records, and Excel pivot-tables and forecast models. 
              </p>
              <p>
                How does this happen? Because Excel varies how it saves data in output files 
                like CSV without the user knowing. It becomes different than the data they saw inside 
                Excel and originally received from colleagues or made themselves. For example, 
                the output from Excel for the imported sample data file. Despite the 
                values in Excel still being accurate even with the altered display format 
                (i.e. exponential format and removed leading zeros), when Excel exports into CSV 
                it saves the values in the distorted formats not the correct original values. This is a 
                critical error in multiple ways since the data is actually now different not just in format 
                but in actual values such as loss of decimal precision (item2 value) and loss of more than 
                half of significant digits in amounts for item5 and item6. Also, code for item4 
                is a completely different value and will fail to match queries and aggregation 
                sums in databases, ERPs, accounting systems, and even Excel pivot tables.
              </p>
              <figure>
                <img src="../assets/images/excel_conversion_roundtrip_fromCSVtoCSVviaExcel.png" alt="data exported from Excel showing incorrect values">
                <figcaption>Data exported from Excel (right side) showing incorrect values (highlighted with red circles) compared to original data (left side).</figcaption>
              </figure>
              <figure>
                <img src="../assets/images/exporttocvs_aftertransformimport.png" alt="data exported from Excel after doing transform import showing incorrect values">
                <figcaption>Data exported from Excel after using the transform import method showing incorrect values.</figcaption>
              </figure>
              <br><h3>Encoding: Data is Unreadable</h3>
              <p>
                An insidious problem that is not common but does occur occasionally in many large organizations is when 
                a seemingly simple process of transferring data in text files becomes a significant problem because 
                the receiver either cannot open the file or when importing into Excel only sees 1 line of 
                Chinese characters. An example is shown in figures 12-14 for Excel, Microsoft NotePad, and Notepad++ respectively.
              </p>
              <figure>
                <img src="../assets/images/ChineseCharsinExcel.png" alt="Excel after opening text file with Chinese characters">
                <figcaption>Data imported into Excel showing only 1 row of Chinese characters.</figcaption>
              </figure>
              <figure>
                <img src="../assets/images/ChineseChars_notepad.png" alt="text file opened in Microsoft Notepad with Chinese characters">
                <figcaption>Data file opened in Microsoft Notepad showing only Chinese characters.</figcaption>
              </figure>
              <figure>
                <img src="../assets/images/ChineseChars_notepad++.png" alt="text file opened in Notepad++ with Chinese characters">
                <figcaption>Data file opened in Notepad++ showing only Chinese characters.</figcaption>
              </figure>
              <p>
                This problem occurs because the invisible information at the beginning of the file becomes out of sync 
                with how the data in the file is actually stored. This invisible information is the Byte Order Mark (BOM) 
                which differs for common encodings UTF-8, UTF-16 with its two variants Little Endian (UTF-16LE) and 
                Big Endian (UTF-16BE). As data moves into and out of different data systems and across computer operating 
                systems the file can get a BOM of one kind while the data itself is in another encoding. Recognizing 
                this situation is difficult as most data editing and processing tools cannot open the file properly nor 
                display the important invisible information. However, experts using specialized tools can detect the 
                situation. This typically requires using a tool that can show a binary view of the data.
              </p>
              <figure>
                <img src="../assets/images/binaryviewencodingproblemtext.png" alt="Binary view of text file with Chinese characters">
                <figcaption>Binary view of text file causing Chinese characters shown using Microsoft Visual Studio's Binary Editor.</figcaption>
              </figure>
              <p>
                The root cause of the problem are the first two bytes FF FE (upper left with highlight circle) 
                which indicate UTF-16LE encoding. Few applications can read this encoding correctly. On the right hand side 
                highlighted in yellow shows that the actual data is still in the file but effectively hidden by the 
                encoding bytes (the .. in front of field1). What makes this insidious is that the owner of the data 
                usually can use their tools to open and view the data properly but the receiver cannot leading to 
                cross-team debates and an impasse in sharing and using the data. Unless an expert is in one of the teams 
                it is unlikely anyone will suspect the root cause and investigate. 
              </p>
              <p>
                Using a speciality editing tool, in this case Visual Studio's Binary Editor, we delete these initial bytes and 
                save the data into a new text file. Then, we open the new file in a standard text editor to see that now 
                the data is readable. This is because without the BOM the editor detects the file encoding to be UTF-8.
              </p>
              <figure>
                <img src="../assets/images/binaryview_deletebom.png" alt="Binary view of text file after deleting BOM bytes">
                <figcaption>Binary view of text file after deleting initial 2 bytes using Microsoft Visual Studio's Binary Editor.</figcaption>
              </figure>
              <figure>
                <img src="../assets/images/binary_deletebom_viewnp++.png" alt="Opened text file after remving errant BOM">
                <figcaption>Opened text file after removing errant BOM with correct display of data since it is now UTF-8.</figcaption>
              </figure>
              <br><h4>Can ChatGPT Solve This?</h4>
              <p>
                Well, beware the lure of too simple 'solutions'. 
                Recall the proverb: "Fools rush in where angels fear to tread." 
                Here is what ChatGPT says:
              </p>
              <p style="margin: 25px; font-style: italic;">
                "A Byte Order Mark (BOM) is a special marker that indicates the byte order and encoding of a text file. It is commonly used with UTF-8, UTF-16, and UTF-32 encodings. If a file is corrupted and you suspect the issue is related to the BOM, here are some steps you can take to fix it:
                <br>1. Identify the Encoding: Determine the original encoding of the file. 
                Common encodings include UTF-8, UTF-16, and UTF-32. You can try opening the 
                file with a text editor that supports different encodings, or you might 
                have information about the encoding from the source where the file came from.
                <br>2. Text Editor with Encoding Support:  Open the corrupted file in a text 
                editor that allows you to specify the encoding explicitly. Editors like 
                Notepad++, Visual Studio Code, or Sublime Text provide options to set 
                the encoding when opening a file. Choose the correct encoding that 
                matches the original encoding of the file.
                <br>3. Remove BOM: If the issue is with an unnecessary or incorrect BOM, 
                you can remove it. Open the file in a text editor, locate the BOM characters, 
                and delete them. For example, in UTF-8, the BOM is represented by 
                the bytes `EF BB BF`. In UTF-16, it can be `FE FF` for big-endian or 
                `FF FE` for little-endian.
                <br>4. Save the File: After making changes, save the file. 
                Make sure to save it with the correct encoding. Some text editors might have 
                an option to save the file without a BOM. Ensure this option is 
                selected if you don't want a BOM in the file.
                <br>5. Hex Editor: If you are comfortable working with hex editors, 
                you can use one to directly edit the file and remove the BOM. 
                Open the file in a hex editor, locate the BOM, and delete the corresponding bytes.
                <br>6. Conversion Tools: You can use specialized tools to convert the 
                encoding of the file. For example, you can use the `iconv` command 
                in Unix-like systems or various online conversion tools.
                <br>Always make a backup of the corrupted file before attempting any modifications, as manual editing may introduce errors. Additionally, understanding the original encoding is crucial for successful recovery. If these steps don't resolve the issue, the corruption might be more severe, and recovering the original data may be challenging."
              </p>
              <p>
                Sounds easy enough. Unfortunately, attempting to do these steps involves a large amount of 
                frustration and difficult technical hurdles. 
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6" @click="SetScroll(0)">
              Example Data Problems
            </v-expansion-panel-header>
            <v-expansion-panel-content eager class="text-body-2">
              <p>
                The examples presented here are all common in both corporate and third party data sets. They are 
                routinely encountered but just as routinely not detected with standard tools and practices. They 
                can stop a project due to poor outcomes. Even when detected, they are often not fixable 
                with the data tools since they are atypical, not handled by those tools, and 
                buried within large data sets. 
              </p>
              <p>
                For several problem types, we use a sample data set from the available IRS Migration data 
                with modifications made to showcase errors. Each error by itself can be corrected during 
                processing either in mainstream data integration or stand-alone data science applications. 
                However, this requires awareness of the existence of the errors, their root cause, and the 
                proper way to handle them according to exacting rules of pedigree, value ranges, formats, 
                and coding. This is precisely what is not known in many real-world situations so these 
                errors remain buried, invisible to data processing and governance, and fed into multiple 
                downstream use cases thereby contaminating their results. Attempting to backtrack these poor 
                results can consume much time, many people, and still remain unsolved.
              </p>
              <p>
                This is VerityRefiner's purpose: use world-class human expertise to proactively 
                detect, correct, enrich, and report in a modern SaaS tool.
              </p>
              <ul>
                <li>line 3: x character in front of number</li>
                <li>lines 5-6: broken record into 2 lines</li>
                <li>line 6: end value in exponential format</li>
                <li>lines 7-9: broken record into 3 lines</li>
                <li>line 11: end value in exponential format</li>
                <li>line 18: $ character in front of number</li>
                <li>line 19: initial code is 1 character (missing lead 0), and $ character in front of number</li>
                <li>line 20: parentheses around number</li>
                <li>line 21: negative number</li>
                <li>lines 20-24: state code in lowercase</li>
                <li>lines 25-28: last values have special character at end of number</li>
              </ul>
              <figure>
                <img src="../assets/images/IRSdatawitherrors_highlight.png" alt="IRS data with highlighted errors">
                <figcaption>
                  Modified IRS data showcasing common edge case data problems
                </figcaption>
              </figure>
              <br><h3>Records Broken Across Multiple Lines</h3>
              <p>
                It might seem that getting data files with a single record actually broken across several lines 
                would be unusual and easy to detect if it occurs. However, this is not the case as this problem has 
                occured in many large organizations with robust data policies and modern tools. Indeed, in one instance 
                a key accounting system generated data extracts with records broken across tens of lines which 
                went unnoticed until a detailed QA analysis was performed using early versions of some 
                of the algorithms in VerityRefiner.
              </p>
              <p>
                The ramifications of this error include:
                <ul>
                  <li>inability to load into database</li>
                  <li>incorrect field values and types (e.g. strings in a number field)</li>
                  <li>failed finanical reconciliations</li>
                  <li>skewed model results (e.g. investment, ML)</li>
                  <li>failed system audits</li>
                  <li>incorrect spreadsheet pivot tables</li>
                  <li>inter-group organizational friction</li>
                  <li>project cancellation</li>
                </ul>
              </p>
              <p>
                If this situation is discovered (which does not always happen), data engineers can attempt to reconstruct the 
                broken records either manually or with scripts. This can work for small data files or databases 
                and when there are only a few such records. But for very large data files this is not doable since most editors 
                cannot open large files. Also, when there are many such records occuring sporadically in large data sets it is 
                too difficult to find them visually perusing the file or database query, and too slow to do so iteratively 
                through error messages when loading the bad records into a database or spreadsheet. Finally, knowing how to 
                correctly reassemble broken records requires careful attention to the nature of the data, i.e. the bsuiness 
                rules, which may or may not be available from documentation or the data owners.
              </p>
              <p>
                In contrast, VerityRefiner uses a patent pending specialized algorithm, AutoStitch, that was developed 
                and used in production over multiple years in different types of data sets. This embodies carefully 
                curated statistical and exacting rules along with options to refine it even further with user 
                knowledge. Consequently, it can rebuild the data set eliminating such errors, as is required to make 
                High Fidelity data.
              </p>
              <br><h3>Encoding: Data is Unreadable</h3>
              <p>
                An insidious problem that is not common but does occur occasionally in many large organizations is when 
                a seemingly simple process of transferring data in text files becomes a significant problem because 
                the receiver either cannot open the file or when importing into Excel only sees 1 line of 
                Chinese characters. An example is shown in figures 12-14 for Excel, Microsoft NotePad, and Notepad++ respectively.
              </p>
              <figure>
                <img src="../assets/images/ChineseCharsinExcel.png" alt="Excel after opening text file with Chinese characters">
                <figcaption>Data imported into Excel showing only 1 row of Chinese characters.</figcaption>
              </figure>
              <figure>
                <img src="../assets/images/ChineseChars_notepad.png" alt="text file opened in Microsoft Notepad with Chinese characters">
                <figcaption>Data file opened in Microsoft Notepad showing only Chinese characters.</figcaption>
              </figure>
              <figure>
                <img src="../assets/images/ChineseChars_notepad++.png" alt="text file opened in Notepad++ with Chinese characters">
                <figcaption>Data file opened in Notepad++ showing only Chinese characters.</figcaption>
              </figure>
              <p>
                This problem occurs because the invisible information at the beginning of the file becomes out of sync 
                with how the data in the file is actually stored. This invisible information is the Byte Order Mark (BOM) 
                which differs for common encodings UTF-8, UTF-16 with its two variants Little Endian (UTF-16LE) and 
                Big Endian (UTF-16BE). As data moves into and out of different data systems and across computer operating 
                systems the file can get a BOM of one kind while the data itself is in another encoding. Recognizing 
                this situation is difficult as most data editing and processing tools cannot open the file properly nor 
                display the important invisible information. However, experts using specialized tools can detect the 
                situation. This typically requires using a tool that can show a binary view of the data.
              </p>
              <figure>
                <img src="../assets/images/binaryviewencodingproblemtext.png" alt="Binary view of text file with Chinese characters">
                <figcaption>Binary view of text file causing Chinese characters shown using Microsoft Visual Studio's Binary Editor.</figcaption>
              </figure>
              <p>
                The root cause of the problem are the first two bytes FF FE (upper left with highlight circle) 
                which indicate UTF-16LE encoding. Few applications can read this encoding correctly. On the right hand side 
                highlighted in yellow shows that the actual data is still in the file but effectively hidden by the 
                encoding bytes (the .. in front of field1). What makes this insidious is that the owner of the data 
                usually can use their tools to open and view the data properly but the receiver cannot leading to 
                cross-team debates and an impasse in sharing and using the data. Unless an expert is in one of the teams 
                it is unlikely anyone will suspect the root cause and investigate. 
              </p>
              <p>
                Using a speciality editing tool, in this case Visual Studio's Binary Editor, we delete these initial bytes and 
                save the data into a new text file. Then, we open the new file in a standard text editor to see that now 
                the data is readable. This is because without the BOM the editor detects the file encoding to be UTF-8.
              </p>
              <figure>
                <img src="../assets/images/binaryview_deletebom.png" alt="Binary view of text file after deleting BOM bytes">
                <figcaption>Binary view of text file after deleting initial 2 bytes using Microsoft Visual Studio's Binary Editor.</figcaption>
              </figure>
              <figure>
                <img src="../assets/images/binary_deletebom_viewnp++.png" alt="Opened text file after remving errant BOM">
                <figcaption>Opened text file after removing errant BOM with correct display of data since it is now UTF-8.</figcaption>
              </figure>
              <br><h4>Can ChatGPT Solve This?</h4>
              <p>
                Well, beware the lure of too simple 'solutions'. 
                Recall the proverb: "Fools rush in where angels fear to tread." 
                Here is what ChatGPT says:
              </p>
              <p style="margin: 25px; font-style: italic;">
                "A Byte Order Mark (BOM) is a special marker that indicates the byte order and encoding of a text file. It is commonly used with UTF-8, UTF-16, and UTF-32 encodings. If a file is corrupted and you suspect the issue is related to the BOM, here are some steps you can take to fix it:
                <br>1. Identify the Encoding: Determine the original encoding of the file. 
                Common encodings include UTF-8, UTF-16, and UTF-32. You can try opening the 
                file with a text editor that supports different encodings, or you might 
                have information about the encoding from the source where the file came from.
                <br>2. Text Editor with Encoding Support:  Open the corrupted file in a text 
                editor that allows you to specify the encoding explicitly. Editors like 
                Notepad++, Visual Studio Code, or Sublime Text provide options to set 
                the encoding when opening a file. Choose the correct encoding that 
                matches the original encoding of the file.
                <br>3. Remove BOM: If the issue is with an unnecessary or incorrect BOM, 
                you can remove it. Open the file in a text editor, locate the BOM characters, 
                and delete them. For example, in UTF-8, the BOM is represented by 
                the bytes `EF BB BF`. In UTF-16, it can be `FE FF` for big-endian or 
                `FF FE` for little-endian.
                <br>4. Save the File: After making changes, save the file. 
                Make sure to save it with the correct encoding. Some text editors might have 
                an option to save the file without a BOM. Ensure this option is 
                selected if you don't want a BOM in the file.
                <br>5. Hex Editor: If you are comfortable working with hex editors, 
                you can use one to directly edit the file and remove the BOM. 
                Open the file in a hex editor, locate the BOM, and delete the corresponding bytes.
                <br>6. Conversion Tools: You can use specialized tools to convert the 
                encoding of the file. For example, you can use the `iconv` command 
                in Unix-like systems or various online conversion tools.
                <br>Always make a backup of the corrupted file before attempting any modifications, as manual editing may introduce errors. Additionally, understanding the original encoding is crucial for successful recovery. If these steps don't resolve the issue, the corruption might be more severe, and recovering the original data may be challenging."
              </p>
              <p>
                Sounds easy enough. Unfortunately, attempting to do these steps involves a large amount of 
                frustration and difficult technical hurdles. 
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6" @click="SetScroll(2)">
              Transform Functions
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <p>
                There are multiple transform functions that can be used to create or modify values for 
                output fields in corrected data sets. These operate similar to how Excel transforms work. 
                That is, they perform operations on either a source field value or a specified starting value. 
                Multiple transforms are chained together left to right in a dot notation like transfrom1.transform2.transform3 
                where each subsequent transform uses the result of the previous transform as its starting value. 
                Thus, transform1 uses the field value in the current record being processed and its result value is then 
                used by transform2 as its starting value.
              </p>
              <p>
                Transforms are organized into the categories: assignment, conditional, numeric, text, and date. 
                Each has default settings and 0-3 parameters that are either required or optional 
                depending on the specific transform. 
              </p>
              <p>
                Conditional transforms operate in a test -> do if false 
                else do if true manner meaning if transform1 is a conditional that has a result=False then 
                transform2 will then be performed and then operation stopped so transform3 is not used. In contrast, 
                if transform1 has a result=True then transform2 is ignored and transform3 is processed. Additional 
                transforms can be added after transform3 for further processing including having more conditionals. 
              </p>
              <br><h3>Assignment</h3>
              <v-data-table
                :headers="transformTable.assignment.cols" 
                :items="transformTable.assignment.items" 
                item-key="id" 
                class="" 
                flat
                dense 
                :items-per-page="5" 
                loading="true" 
                loading-text="Loading data"
              />
              <br><h3>Conditional</h3>
              <v-data-table
                :headers="transformTable.conditional.cols" 
                :items="transformTable.conditional.items" 
                item-key="id" 
                class="" 
                flat
                dense 
                :items-per-page="10" 
                loading="true" 
                loading-text="Loading data"
              />
              <br><h3>Numeric</h3>
              <v-data-table
                :headers="transformTable.numeric.cols" 
                :items="transformTable.numeric.items" 
                item-key="id" 
                class="" 
                flat
                dense 
                :items-per-page="10" 
                loading="true" 
                loading-text="Loading data"
              />
              <br><h3>Text</h3>
              <v-data-table
                :headers="transformTable.text.cols" 
                :items="transformTable.text.items" 
                item-key="id" 
                class="" 
                flat
                dense 
                :items-per-page="10" 
                loading="true" 
                loading-text="Loading data"
              />
              <br><h3>Dates</h3>
              <v-data-table
                :headers="transformTable.date.cols" 
                :items="transformTable.date.items" 
                item-key="id" 
                class="" 
                flat
                dense 
                :items-per-page="10" 
                loading="true" 
                loading-text="Loading data"
              />
              <br><br><h3>Making a Transform</h3>
              <p>
              A transform is a chain of operations in sequential steps. They are processed in order with 
              special handling for conditional functions which establish TRUE or FALSE result that determines 
              whether the next function is done or skipped. First, on the DataSources page choose to 
                EDIT a FIELD DEFINITION. If there are none, make one according to the example described earlier 
                in this guide. Then click the icon in the top bar to go to the TRANSFORMS subpage. Click the icon 
                to add a transform. 
              </p>
              <figure>
                <img src="../assets/images/maketransform_selecticon.png" alt="Select transform view">
                <figcaption>
                  Click the icon highlighted by the black arrow to view the transforms subpage. Then use 
                  the icons highlighted by the green arrow to manage transforms. For this example, 
                  click the icon to add a new transform. 
                </figcaption>
              </figure>
              <p>
                Transforms are specified by the target field they generate a result for. Use 
                the select box to choose a target field already defined in the FIELD DEFINITION. 
                Then, we add Ops (operations) in order. The order can be changed by modifying the 
                ORDER property within each Ops. Click icon to add an Op. 
              </p>
              <figure>
                <img src="../assets/images/maketransform_choosetarget.png" alt="Set target field in new transform">
                <figcaption>
                  Choose the target field for the new transform. 
                </figcaption>
              </figure>
              <p>
                We set the target field to be the 'code' field. We know from the quality and 
                profile analysis that some codes have a period in them. We will create a transform 
                that changes this period into an underscore.We click icon to add an Op which 
                we will set as a conditional function to detect values that have a period. 
                Click Save Op and we see the transform recipe has it.
              </p>
              <figure>
                <img src="../assets/images/maketransform_addop_conditional.png" alt="Set first Op ( order=0 )to be conditional">
                <figcaption>
                  Using the select boxes, we set the operation category to be 
                  conditional and the function to be IfStrContains. A description of 
                  the function is shown along with definitions of parameters that 
                  are required and optional. Param1 is required which we set to be period.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/maketransform_list_op0.png" alt="First Op added to transform">
                <figcaption>
                  First Op listed in transform.
                </figcaption>
              </figure>
              <p>
                Since the first Op is a conditional, the second Op is what will be done 
                if the conditional results in FALSE and then no further Ops are done. 
                The third Op will be done if result is TRUE and subsequent Ops can also be done. 
                We set the second Op to be NoOp which is a special function for No Operation 
                meaning the value will not be changed. The third Op is set to be a 
                string REPLACE with periods changed into underscores.
              </p>
              <figure>
                <img src="../assets/images/maketransform_list_3ops.png" alt="Three Ops added to transform">
                <figcaption>
                  All three Ops listed in transform.
                </figcaption>
              </figure>
              <p>
                We can test our transform using a realistic value. We enter it into 
                the TEST VALUE line and then click the green arrow to run it. The resuls 
                are shown with step wise operations, their parameters, and their individual 
                outputs. 
              </p>
              <figure>
                <img src="../assets/images/maketransform_test.png" alt="Results from testing transform">
                <figcaption>
                  We provided a sample of actual data file value to test the transform 
                  and see that it works correctly replacing the period with an underscore.
                </figcaption>
              </figure>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6" @click="SetScroll(2)">
              VerityExcel Use Guide
            </v-expansion-panel-header>
            <v-expansion-panel-content eager class="text-body-2">
              <p>
                VerityExcel reduces the effort and risk of correctly moving data 
                into and out of Microsoft Excel. It consolidates and controls the 
                work flow steps needed to make High Fidelity data with Excel 
                and avoid the common but often unseen data corruption caused by Excel. 
                There are two primary concepts: source files and jobs. 
              </p>
              <p>
                Source files can be delimited text or Excel (only xlsx). Each source file 
                can be assigned a Field Definition which defines the data structure, and optionally 
                additional detail such as specific data types, format rules, as well as use 
                a set of transform functions. The Sources application page, which is accessed 
                from the top menu (next to account logo), is devoted to handling source files.
                A job uses sources files to assess data correctness, fix errors, and 
                enrich and transform into new output. The output can be either delimited 
                text or Excel spreadsheet. The Jobs application page is also accessed from the 
                top menu.
              </p>
              <p>
                The basic work flow is depicted below. While shown as a linear process left to right 
                it is actually capable of iterating flexibly among the steps. But, this helps show 
                the essential steps. 
              </p>
              <figure>
                <img style="max-width: 85%;" src="../assets/images/process_icon_transparent.png" alt="basic work flow process">
                <figcaption>Work flow starts with adding source files</figcaption>
              </figure>
              <p class="mt-10">
                <ul>
                  <li>
                    Add source data file(s): Upload delimited text or Excel files, and/or register 
                    Cloud drives with source files
                  </li>
                  <li>
                    Define file data structure in Field Definition and optionally specify 
                    data types, formats, added metadata, value transforms. Can use auto-extract 
                    to generate initial Field Definition.
                  </li>
                  <li>
                    Run deep inspection job to detect many different data characteristics and presence 
                    of errors and inconsistencies
                  </li>
                  <li>
                    Use detected characteristics to run deep cleaning, normalization, and enrichment 
                    jobs using out-of-box settings or customized rules and transforms in Field Definition
                  </li>
                  <li>
                    Use generated High Fidelity data in high impact use cases
                  </li>
                  <li>
                    Evolve and repeat as needed from any step
                  </li>
                </ul>
              </p>
              <p>
                Every operation is context aware meaning some functions are shown or disabled based on 
                current situation. Help is available in each area and will explain options. This is the 
                primary means of guiding user actions. The User Interface (UI) design always presents information 
                and functions in a simple-first way with additional details available with drill-down 
                views.
              </p>
              <p>
                Use the top menu to select the application page. The top of each page 
                states the page you are on, your name, and the type of account you have. 
                Use the account icon to select different account operations including 
                modifying your account type and duration, and logging out.
              </p>
              <figure>
                <img src="../assets/images/topmenu.png" alt="top menu">
                <figcaption>Use top menu to select page or account operation</figcaption>
              </figure>
              <figure>
                <img src="../assets/images/sourcespage.png" alt="sources page">
                <figcaption>Sources page with top banner information and highlighting actions</figcaption>
              </figure>
              <br><h3>Sources Page</h3>
              <p>
                Clicking SOURCES in the top menu opens the Sources page and retrieves a list 
                of files. There are three types of storage locations. (Note: some account types 
                do not include using network nor Cloud directories)
                <ol>
                  <li>
                    Internal: a server folder completely managed by VerityExcel and 
                    with limited capacity by number of files and total file size
                  </li>
                  <li>
                    Network File Storage: Must first be configured by server administrator 
                    as an operating system network location accessible to the 
                    VerityExcel server process. You can then use this location's URI 
                    and register it as a folder that will be searched for files. This is 
                    read-only so you must manage files outside of VerityExcel.
                  </li>
                  <li>
                    Cloud Storage: Must first be configured on the Cloud service  
                    provider and accessible to the VerityExcel server process. 
                    You can then use this location's details 
                    and register it as a folder that will be searched for files. This is 
                    read-only so you must manage files outside of VerityExcel.
                    The type of currently available Cloud locations is listed in select box. For example, AWS's S3 
                    requires both Access and Secret Access keys, as well as being configured on AWS itself. 
                    Use the GET BUCKETS button to retrieve list of buckets for the REGION. 
                  </li>
                </ol>
                You can set filter conditions using * as front or back wildcard 
                (as with *account* or project_23*), - to signify exclusion 
                (as with -*.zzz to ignore files with an extension of zzz). Set multiple conditions by 
                separating them with the pipe ( | ) character.
              </p>
              <p>
                The arrows highlight key information and actions. The 'i' button provides 
                contextual help. Each button has description with mouse hover. Some of the actions operate on 
                a selected file which is done with the select box next to its title. The icons 
                left to right are for:
                <ul>
                  <li>
                    Help
                  </li>
                  <li>
                    Field Definition functions
                  </li>
                  <li>
                    File actions
                  </li>
                  <li>
                    Upload file or manage external and Cloud dirs
                  </li>
                  <li>
                    Search table or refresh list from server
                  </li>
                </ul>
              </p>
              <p>
                The 'FieldDef' column states whether the 
                file has a FieldDefinition assigned. It is -unk- meaning unknown until you select it at which time 
                the server is checked and either the name of the FieldDef is shown or "no" meaning none it assigned. 
              </p>
              <figure>
                <img src="../assets/images/sources_withfielddef.png" alt="Source file with FieldDef title">
                <figcaption>Source file with its FieldDef title after selecting file in table.</figcaption>
              </figure>
              <p>
                To add a Cloud location (if you have the appropriate account type), click the appropriate 
                icon button and then the add button from 'Cloud & Network Directories' 
                table. Fill in the form boxes and for this case using AWSS3 also click GET BUCKETS 
                after setting the REGION. If an error occurs, an alert will be shown. If successful, 
                close the list of Cloud & Network Directories and then refresh the source file list using the 
                search function.
              </p>
              <figure>
                <img src="../assets/images/AddCloudDir_awss3.png" alt="Adding AWSS3 Cloud DIr">
                <figcaption>Adding AWS S3 Cloud directory. Arrows highlight optional filter conditions and also GET BUCKETS button.</figcaption>
              </figure>
              <figure>
                <img src="../assets/images/sourcelist_withawss3.png" alt="Sources with AWSS3">
                <figcaption>Source list after adding AWS S3 Cloud directory and refreshing list using Search.</figcaption>
              </figure>
              <br><h4>Field Definition</h4>
              <p>
                There are multiple functions available to manage Field Definitions in the menu. 
                Some will be disabled based on context such as those for a specific file if no file 
                is selected.
              </p>
              <figure>
                <img src="../assets/images/fieldDefmenu.png" alt="FieldDef menu functions">
                <figcaption>Menu options for Field Definitions.</figcaption>
              </figure>
              <p>
                As an example, we select EDIT/VIEW FieldDef to see a selection form. This lists 
                Field Definitions associated with your account. Select and click CHOOSE.
              </p>
              <figure>
                <img src="../assets/images/editFieldDef.png" alt="Select FieldDef to edit">
                <figcaption>Selecting Field Definition to edit.</figcaption>
              </figure>
              <p>
                In the Edit Field Definition form, some changes can be made directly 
                such as for the delimiter while others require drilling further down such as 
                for a field's settings. The top banner icons provide functions at the Field 
                Definition level. The table will change based on selecting one of these icons 
                for what is displayed and also the icons availabe in the table which depend on 
                the context.
                <ul>
                  <li>
                    Help: about the form at the Field Definition level
                  </li>
                  <li>
                    View and Edit source enrichment fields: Selects form listing to show 
                    source fields and any added enrichment fields.
                  </li>
                  <li>
                    View source-target mapping: Selects form listing to show target fields 
                    and how they are mapped to source/enrichment fields. The order is important 
                    since it controls how data will be stored in generated files. The DataType 
                    shows whether it is derived from the source field (has 'src' as prefix) or is 
                    set for the target. This allows controlling the output data types independently 
                    of the source fields. 
                  </li>
                  <li>
                    View and edit transforms: Selects form listing to show defined transforms 
                    and allow operations on them such as adding, deleting, editing.
                  </li>
                  <li>
                    Import and OverWrite Field Definition: allows importing from a previously 
                    saved VerityExcel specific JSON file. 
                  </li>
                  <li>
                    Export to JSON: saves Field Definition in VerityExcel specific JSON file.
                  </li>
                </ul>
              </p>
              <figure>
                <img src="../assets/images/editFD_form.png" alt="Edit FieldDef form">
                <figcaption>
                  Edit Field Definition display with arrows highlighting icon 
                  functions at FieldDef level, context dependent table, as well as buttons 
                  to CANCEL or SAVE (disabled if nothing changed) and the select boxes for 
                  each field enabling operations on them.
                </figcaption>
              </figure>
              <p>
                The default view is for source fields which are listed in ascending START order. Summary 
                values are provided for each field's properties which can be edited by selecting the field 
                and using the icon to edit. Icons for this view are for:
                <ul>
                  <li>
                    Help: about the table of fields
                  </li>
                  <li>
                    Add: add a new field
                  </li>
                  <li>
                    Edit: open form with detailed properties of the field
                  </li>
                  <li>
                    Delete: delete selected field
                  </li>
                  <li>
                    Export: save field table information into a text file
                  </li>
                  <li>
                    Search: filter listed fields
                  </li>
                </ul>
              </p>
              <p>
                You edit a field by selecting it then using the icon for EDIT form. See its contextual 
                help for details. Its properties are:
                <ul>
                  <li>title: unique field title</li>
                  <li>type: source means in source file while enrich means added metadata field to generated file as target field</li>
                  <li>dataType: default is string and can choose int (integer), real, bool (Boolean), date (various formats supported)</li>
                  <li>pos: zero based integer position of the field in each record</li>
                  <li>isEncrypt: if true then the value will be decrypted using client specific encryption key</li>
                  <li>pin: used to refine how automated broken record stitching operates. If true, then this field will not have its value combined with adjacent values by the algorithm to correct parsing errors which allows keeping fields known to have a reliable position in every record safe from being modified</li>
                  <li>allowEmpty: If true, then empty field values will be accepted as valid even for dataTypes which disallow empty values like numeric and Boolean</li>
                  <li>range: optionally use short hand notation to specify allowed value range which depends on dataType</li>
                  <li>decimals: only for REAL numbers. The required number of decimal places</li>
                  <li>decimalOp: only for REAL numbers. Possible inequality operators to use with decimals value (lt=less than, lte= less than or equals, gt=greater than, gte=greater than or equals, nothing means equals)</li>
                  <li>dateFormat: only for DATE type. Select known format. ISO means ISO8601 like 20231215T092500</li>
                  <li>case: only for STRING type. Upper or lower if this is required casing</li>
                  <li>len: required string total length</li>
                  <li>lenOp: Possible inequality operators to use with len</li>
                  <li>cut: LEFT or RIGHT side of string to cut (i.e. remove) characters if the field value is longer than the required length condition</li>
                  <li>pad: LEFT or RIGHT side of string to pad (i.e. add) characters if the field value is shorter than the required length condition</li>
                  <li>padChar: character to pad value with</li>
                  <li>target: title of mapped target field. If not set then source field title is used</li>
                  <li>targPos: target field position in generated data records. Default is same as source field.</li>
                  <li>targDataType: override source field dataType for generated output and force into this new dataType</li>
                  <li>targEncrypt: if true then the value will be encrypted in generated file using client specific encryption key</li>
                </ul>
              </p>
              <figure>
                <img src="../assets/images/editfield.png" alt="Edit field">
                <figcaption>
                  Edit field form.
                </figcaption>
              </figure>
              <p>See context help in other displays for guidance.</p>
              <br><h5>Auto-Extract Field Definition</h5>
              <p>
                This special function allows you to create a Field Definition and then assign it by doing an 
                immediate analysis job on the file. Select a file and then the menu option. You can specify 
                the delimiter or allow it to be discovered, and set whether there is a header line (first 
                non-empty and non-comment) and if values are enclosed in double quotes.
              </p>
              <figure>
                <img src="../assets/images/autoextract_setting.png" alt="Auto extract Field Definition">
                <figcaption>
                  Settings for auto-extract Field Definition.
                </figcaption>
              </figure>
              <p>
                Configure the settings for the analysis job.
              </p>
              <figure>
                <img src="../assets/images/autoextract_results.png" alt="Auto extract Field Definition results">
                <figcaption>
                  Results from auto-extract Field Definition job. Arrows highlight selecting a field, and icons for viewing field details and saving FieldDef.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/autoextract_results_field.png" alt="Field details from auto-extract">
                <figcaption>
                  Results from auto-extract Field Definition job with field details.
                </figcaption>
              </figure>
              <p>
                Select icon to SAVE FIELDDEF and it will be saved for future use and also assigned to the source file. 
              </p>
              <p>An Excel spreadsheet can also be used for the source file.</p>
              <figure>
                <img src="../assets/images/source_xlsx_extractFD_select.png" alt="Auto-extract from Excel">
                <figcaption>
                  Use Excel spreadsheet to auto-extract Field Definition.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/source_xlsx_extractFD_results.png" alt="Auto-extract from Excel results">
                <figcaption>
                  Results from using Excel spreadsheet to auto-extract Field Definition.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/source_xlsx_extractFD_results_field_qual.png" alt="Quality factor for field from auto-extract from Excel">
                <figcaption>
                  Field details Quality from using Excel spreadsheet to auto-extract Field Definition.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/source_xlsx_extractFD_results_field_dtype.png" alt="Data types for field from auto-extract from Excel">
                <figcaption>
                  Field details Data Types from using Excel spreadsheet to auto-extract Field Definition.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/source_xlsx_extractFD_results_field_uv.png" alt="Unique Values for field from auto-extract from Excel">
                <figcaption>
                  Field details Unique Values from using Excel spreadsheet to auto-extract Field Definition.
                </figcaption>
              </figure>
              <p>
                While viewing the results table listing of fields, click icon to get a sample of spreadsheet rows.
              </p>
              <figure>
                <img src="../assets/images/source_xlsx_extractFD_results_getsample.png" alt="Get sample data from Excel">
                <figcaption>
                  Click icon highlighted by arrow to get sample data from Excel spreadsheet used to auto-extract Field Definition. 
                  Enter string with optional front or back wildcard (*) for Excel workbook sheet to use (such as *finan* matches 
                  first sheet found with finan in its name. This is case insensitive).
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/source_xlsx_extractFD_results_getsample_lines.png" alt="Sample data from Excel">
                <figcaption>
                  Sample data retrieved from Excel spreadsheet. The spreadsheet used is listed in title. VerityExcel 
                  inserts the pipe delimiter between cells in each row.
                </figcaption>
              </figure>
              <br><h5>Change or Remove Assigned Field Definition</h5>
              <p>
                Each source file needs a Field Definition assigned. In addition to doing an auto-extract job, 
                you can directly assign or remove assigned Field Definition using the ASSIGN FIELDDEF TO FILE 
                menu option.
              </p>
              <figure>
                <img src="../assets/images/assignFieldDef_hasexisting.png" alt="Menu to assign FieldDef to file">
                <figcaption>
                  Select menu to Assign FieldDef to file.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/assignFD_select.png" alt="Select FieldDef to assign to file">
                <figcaption>
                  Select a FieldDef to assign to file or -none- to remove assignment.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/filelist_afterremoveassign.png" alt="FieldDef removed from file">
                <figcaption>
                  Deselecting and reselecting the source file shows it no longer has an assigned FieldDef.
                </figcaption>
              </figure>
              <br>
              <br><h3>Jobs Page</h3>
              <p>
                This page handles all aspects of jobs from creating new ones, seeing results, and obtaining High Fidelity 
                generated output. When you open this page the list of jobs you have made over the past 90 days is shown. 
                You can use the Search icon to filter the table contents and do a new query to the server optionally 
                with filter conditions. The icons are for functions:
                <ul>
                  <li>
                    Job actions edit, copy, new, delete
                  </li>
                  <li>
                    Run or stop job
                  </li>
                  <li>
                    View or export job
                  </li>
                  <li>
                    Search table or server
                  </li>
                </ul>
              </p>
              <p>
                We create a new job to use the example source file we showcased in the Sources section above. The 
                properties are:
                <ul>
                  <li>
                    Title: must be unique across all of your jobs and cannot include special characters
                  </li>
                  <li>
                    Status: read-only set by system
                  </li>
                  <li>
                    Start: read-only set by system date time
                  </li>
                  <li>
                    End: read-only set by system date time
                  </li>
                  <li>
                    String Case: if true, then string value case matters otherwise all assessments are done in lower case
                  </li>
                  <li>
                    MaxRecs: If 0 then there is no limit. If a positive integer then this will be the maximum number of source records used at which point processing ceases.
                  </li>
                  <li>
                    MaxErrors: If 0 then there is no limit. If a positive integer then this will be the maximum number of errors allowed per source file at which point all further source records will be ignored.
                  </li>
                  <li>
                    Note: short description of job
                  </li>
                  <li>
                    Output Type: Choose the type of output file and structure. For text files, you can choose one of three delimiters. For Excel file, a new spreadsheet is generated with controlled field value data types and formats
                  </li>
                  <li>
                    Convert Exponent: When true, strings that have an exponential format (e.g. 3.476e5) will be converted into number data type value
                  </li>
                  <li>
                    DateToIso: When true, date fields either as a column with this data type or a string with known date format will be converted into ISO8601 format
                  </li>
                  <li>
                    Sheet Name: If using an Excel source file (only xlsx), enter either exact name or use wildcard * at start and/or end of a string. If using wildcard, 
                    the first sheet encountered matching will be used, otherwise the first sheet in the Excel workbook will be used 
                    (caution: sheets are not stored in the same order as shown in Excel). The spreadsheet must have a header 
                    row of field titles.
                  </li>
                </ul>
              </p>
              <figure>
                <img src="../assets/images/newjobform.png" alt="New job form">
                <figcaption>
                  New job form. 
                </figcaption>
              </figure>
              <p>
                Click the EDIT icon on the Sources card to open the source file settings. Your 
                registered locations are searched for sources files which are listed (only certain 
                file extensions: txt, dat, csv, out, xlsx). A selected source 
                file should have an assigned Field Definition which is handled in the 
                application's SOURCES page. However, you can use a source file without an 
                assigned Field Definition but in this case it must have a header line of 
                field titles that is the first non-empty and non-comment line (or row if Excel spreadsheet). 
                A text file source must also be delimited with one of the accepted delimiters: pipe, tab, comma, colon, caret.
              </p>
              <figure>
                <img src="../assets/images/job_sources.png" alt="New job source settings">
                <figcaption>
                  New job source file listing. Select source file.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/job_sources_selected.png" alt="New job selected file">
                <figcaption>
                  New job source file listing with a selected source file. Use the icon (under arrow in image) 
                  to check server for 
                  an assigned Field Definition and retrieve it if found. Note that the NUMFIELDS value changes 
                  from -unk- (unknown status) to a number (in this case 3 which is the number of fields in  
                  Field Definition), or -no FD- (no assigned Field Definition).
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/job_source_summary_nomissingFD.png" alt="New job summary with 1 source selected and none missing Field Definition">
                <figcaption>
                  New job summary showing one selected source file and none missing assigned Field Definition.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/job_sources_selected_unk.png" alt="New job 1 source selected that still has -unk- since we did not check for Field Definition">
                <figcaption>
                  One selected source file which we will save without checking for assigned Field Definition. During the 
                  saving process, the application automatically checks for a Field Definition.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/job_source_summary_missingFD.png" alt="New job summary with 1 source selected and one missing Field Definition">
                <figcaption>
                  New job summary showing one selected source file and one missing assigned Field Definition.
                </figcaption>
              </figure>
              <br><h4>Run Job</h4>
              <p>
                After you save the new job it will be listed with a status of notrun. This allows you to continue to 
                edit as needed. When you aready to run the job select it in table then use the icon to RUN. 
              </p>
              <figure>
                <img src="../assets/images/job_saved_rdyrun.png" alt="New job ready to run">
                <figcaption>
                  New job saved and ready to run. The arrow points to the icon to use to RUN or KILL a job.
                </figcaption>
              </figure>
              <p>
                When set to RUN, the job is checked to ensure all required settings are correct and that the 
                source file is accessible to the VerityExcel server process. It is then either rejected with an error 
                message about what failed so that you can fix them, or submitted to processing queue. When the job 
                completes an email is sent to your account email and then the results and output files will 
                be available by viewing the job in this listing. Note the status value to see if it is complete, 
                failed, or still running. You can also KILL a running job using the icon menu. 
              </p>
              <figure>
                <img src="../assets/images/job_running.png" alt="New job running">
                <figcaption>
                  New job running. Arrow is pointing at icon for SEARCH to refresh the list.
                </figcaption>
              </figure>
              <p>
                The time needed for the job to complete depends on several factors including the amount data 
                being processed and the server queue load. You can check both the status in VerityExcel (reload jobs 
                with SEARCH or reload entire page), and your email. 
              </p>
              <figure>
                <img src="../assets/images/job_completed.png" alt="New job completed">
                <figcaption>
                  New job completed per updated status using SEARCH to refresh list.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/job_email.png" alt="New job email">
                <figcaption>
                  New job completed per email.
                </figcaption>
              </figure>
              <br><h4>View Job Results</h4>
              <p>
                Once the job is complete, select the job in the table and then use the menu icons to click on 
                VIEW JOB. This opens an expanded view of the job with a summary. You can click on icons within 
                the SOURCE or RESULTS cards to see additional detail. 
              </p>
              <figure>
                <img src="../assets/images/job_complete_summary_view.png" alt="Job result summary view">
                <figcaption>
                  Job completed summary view. The icons in the top bar are to change the note, 
                  download to JSON file, and help.
                </figcaption>
              </figure>
              <figure>
                <img src="../assets/images/job_complete_source_view.png" alt="Job result source view">
                <figcaption>
                  Job completed view of source file information after clicking on SOURCES card in summary view. 
                  The ACTIONS column allows viewing a sample of the file.
                </figcaption>
              </figure>
              <p>
                The RESULTS view initially lists the source fields and their Field Definition settings (if used) 
                for DataType and Format. The Quality and High Unique columns are results from the assessment. 
                Quality levels are from best to worst: HiFi (for High Fidelity), Exc (excellent), Good, Fair, Poor. 
                The icons at the card title area (green arrow) provide help and enable downloading the corrected file. 
                The card title states the source file and the corrected file titles. The table lists fields and can 
                switch between source and target fields using icon at table level (blue arrow). For source fields, 
                you can select one and use icon to see more details. This is not enabled for target fields. You can also 
                download the table contents into a text file.
              </p>
              <figure>
                <img src="../assets/images/job_complete_results_view.png" alt="Job result results view">
                <figcaption>
                  Job completed view of results information after clicking on RESULTS card in summary view. 
                  Green arrow shows icons at card level for help and downloading corrected file. Blue arrow at table 
                  level points to icons for guidance, toggling between source and target fields, details of selected field, 
                  and downloading table into text file.
                </figcaption>
              </figure>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6" @click="SetScroll(2)">
              VerityRefiner Use Guide
            </v-expansion-panel-header>
            <v-expansion-panel-content eager class="text-body-2">
              <p>
                VerityRefiner significantly reduces the burden of getting data into shape for high impact analytics and modeling especially for large 
                heterogeneous sources. It is designed to use its core algorithms based on extensive real-world 
                human expertise as default operations to provide users with the both the benefits of the 
                expertise and a simple methodology. Advanced settings are available but optional so that a 
                realistic work flow can occur with substantially lower Level of Effort (LOE) than using standard 
                data tools. This realistic work flow entails iterative cycles of collect, analyze, correct, 
                cleanse-normalize-enrich, and use with visible and trackable actions. 
              </p>
              <p>
                There are two primary application areas. The Sources page handles all aspects of getting source 
                data files either uploaded or registered for use with VerityRefiner. This includes lists, viewing 
                sample data within the files, analyzing the files, creating and editing Field Definitions of the 
                structure and optionally more detailed properties, and the mechanics of managing these. The Jobs page 
                similarly handles all aspects of defining and running jobs to analyze and generate corrected 
                High Fidelity data. Access these pages using the menu in the application bar. There is <b>context help</b> 
                available in all areas which is the primary way to get guidance.
              </p>
              <figure>
                <img src="../assets/images/appbar_menu.png" alt="app bar menu">
                <figcaption>Application bar with menu to choose page</figcaption>
              </figure>
              <v-expansion-panels focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header color="teal" @click="SetScroll(3)">
                    Sources
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <p>
                      Selecting the Sources page will retrieve a listing of files you have available. There are three 
                      possible storage location types.
                      <ul>
                        <li>Internal: stored within application and managed by uploading, downloading, and possibly deleting</li>
                        <li>
                          Cloud: AWSS3 (others coming soon) in read-only mode. You must configure this with Register Cloud option 
                          and provide proper credentials including both Access and Secret Access keys, 
                          as well as being configured on AWS itself. 
                          Use the GET BUCKETS button to retrieve list of buckets for the REGION.                   
                          Make sure you test access on AWS before setting in VerityRefiner.
                        </li>
                        <li>
                          Networked (ExtDir): These can only be used with a custom installation in your own environment. 
                          It requires establishing a secure network drive mapping in the OS and 
                          ensuring the VerityRefiner server processes can access them. Configure with Register menu option. 
                          This is read-only.
                        </li>
                      </ul>
                      You can set filter conditions using * as front or back wildcard 
                      (as with *account* or project_23*), - to signify exclusion 
                      (as with -*.zzz to ignore files with an extension of zzz). Set multiple conditions by 
                      separating them with the pipe ( | ) character.
                    </p>
                    <figure>
                      <img src="../assets/images/sourcelist.png" alt="sources file list">
                      <figcaption>Sources page file listing. </figcaption>
                    </figure>
                    <p>
                      In the Sources page file listing, notice the different Types of locations which allows files with the 
                      same name to be listed multiple times if they are in different locations. The FieldDef column 
                      notes whether a Field Definition is assigned to the file. Initially, this is -unk- meaning it is 
                      unknown until you select the file using the check box next to it at which time the server is checked 
                      and either the column is changed to NO if no Field Definition is defined or the name of the 
                      Field Definition. The #Jobs column will be filled when you select the menu item to retrieve its 
                      usage. This is in the FileActions icon menu. The icon menus are at the top of the table for:
                      <ul>
                        <li>context help</li>
                        <li>Field Definition</li>
                        <li>File Actions</li>
                        <li>Upload file and register Cloud/Network</li>
                        <li>Search files in table or on server</li>
                      </ul>
                    </p>
                    <figure>
                      <img src="../assets/images/sources_menu_fielddef.png" alt="sources icon menu Field Definition">
                      <figcaption>Sources page Field Definition menu. </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/sources_filemenu.png" alt="sources icon menu File Actions">
                      <figcaption>Sources page File Actions menu. </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/sources_menu_uploadregister.png" alt="sources icon menu Upload/Register">
                      <figcaption>Sources page Upload/Register menu. </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/sources_tablesearch.png" alt="sources icon menu Search">
                      <figcaption>Sources page Search menu. </figcaption>
                    </figure>
                    <p>
                      We use the Table Filter menu function to filter the source files to find one we will use as example in 
                      this guide. Entering irs (case insensitive) reduces the list to records with that string part. We select 
                      one of these files and see that there is no Field Definition assigned. 
                    </p>
                    <figure>
                      <img src="../assets/images/sources_tablesearch_irs.png" alt="source file list after table filter">
                      <figcaption>Source files filtered by 'irs'</figcaption>
                    </figure>
                    <p>
                      Select menu option to View Sample. Results are displayed in a popup window. 
                    </p>
                    <figure>
                      <img src="../assets/images/sources_viewsampleform.png" alt="source file view sample form">
                      <figcaption>Source file selected and chose View Sample in menu to set a few options</figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/sources_viewsample_lines.png" alt="source file view sample retrieved lines">
                      <figcaption>Source file View Sample retrieved lines</figcaption>
                    </figure>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header color="teal lighten-2" @click="SetScroll(3)">
                    Field Definition
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <p>
                      The Field Definition is the consolidated description, rules, and transforms for source data. Most of 
                      it is optional and allows just a simple definition of fields, adding field data types and formats,  
                      as needed pre-built transform functions (including conditional tests) to cleanse and normalize source 
                      values into target values, changing field names in output data, adding enrichment metadata, and 
                      generating synthetic data using statistical functions.
                    </p>
                    <p>
                      There are multiple functions available to manage Field Definitions in the menu. 
                      Some will be disabled based on context such as those for a specific file if no file 
                      is selected. Every source file must be assigned a Field Definition when High Fidelity output data 
                      is made, but is not needed for analysis only. A Field Definition can be made by manually 
                      entering information, by importing from a special file format, or automatically using 
                      a special analysis job.
                    </p>
                    <p>
                      A new Field Definition has a default title that should be changed. Always use a title that has 
                      meaning and refers to its use context. 
                    </p>
                    <figure>
                      <img src="../assets/images/editFD_new.png" alt="editing new Field Definition">
                      <figcaption>New Field Definition. Arrows highlight top icon actions and those for fields.</figcaption>
                    </figure>
                    <p>
                      The icons (currently active in green) in the top banner are for:
                      <ul>
                        <li>information about the page</li>
                        <li>edit source and enrichment fields</li>
                        <li>source-target mapping</li>
                        <li>transforms</li>
                        <li>import from file</li>
                        <li>export to JSON file (can be used for import in future)</li>
                      </ul>
                    </p>
                    <p>
                      The icons at the top of Fields table are for:
                      <ul>
                        <li>information about the table</li>
                        <li>add field</li>
                        <li>edit field</li>
                        <li>delete field</li>
                        <li>export table to file</li>
                        <li>search table</li>
                      </ul>
                      Always refer to the context help ( 'i' button ) for guidance.
                    </p>                  
                    <br><h3>Auto-Extract</h3>
                    <p>
                      Use the menu to create a Field Definition for the file using auto-extraction 
                      (Extract FieldDef from File). An analysis job is performed with statistical algorithms 
                      determining appropriate field data types and formats. These can be refined afterwards 
                      by editing the new Field Definition. Once the results are displayed, you can review many 
                      characteristics of the data. You must decide to save the analysis as a new Field Definition using 
                      the icon to do so located at the top of the table. 
                      The header shows how many records were analyzed, the number of fields found, number of 
                      parsing errors discovered, and whether special characters were detected. Click the information 
                      icon to see additional details.
                    </p>
                    <figure>
                      <img src="../assets/images/sources_extractFD_form.png" alt="source file extract Field Definition">
                      <figcaption>Source file Extract Field Definition form</figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/sources_extractFD_results.png" alt="source file extract Field Definition results">
                      <figcaption>
                        Source file Extract Field Definition results. The arrows highlight icons for actions 
                        at top of table, the field AGI which has special characters detected in its values, and 
                        the select boxes next to each field that enable seeing greater detail. 
                      </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/sources_extractFD_results_headerinfo.png" alt="header information in extract results">
                      <figcaption>
                        Additional information from header boxes in Extract Field Definition results. 
                        Upper left is distribution of record sizes. Upper right is parsing errors. 
                        Lower right is special characters.
                      </figcaption>
                    </figure>
                    <p>
                      The results include a Health factor that ranges from HiFi (best) to Poor (worst), and is 
                      calculated using VerityRefiner's expert algorithms that take into account knowledge of how 
                      statistical characteristics have actually turned out in large data systems. The field 'y1_state' 
                      has the lowest Health so we investigate further by selecting it and clicking icon for details. 
                      The first display shows the Quality factors comprising the Health metric. These differ depending 
                      on data type. Other characteristics are viewed by choosing from the drop down box. The string case format 
                      shows a few values are in lowercase while the overwhelming amount (99.5%) are uppercase. 
                      The 0.5% values would typically be overlooked in standard data tools and while this difference 
                      may not matter in some end use cases it may cause signficant error in others. The most 
                      frequently occuring unique values can be viewed, as well as a key way to find low 
                      frequency errors which is viewing the lowest occuring unique values. Here we see 
                      erroneous values in the field (such as the number 95832). 
                    </p>
                    <figure>
                      <img src="../assets/images/sources_extractFD_results_fielddetail_quality.png" alt="extract Field Definition quality results for field y1_state">
                      <figcaption>
                        Extract Field Definition results for field y1_state. Quality factors comprising Health metric.
                      </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/sources_extractFD_results_fielddetail_case.png" alt="extract Field Definition string case results for field y1_state">
                      <figcaption>
                        Extract Field Definition results for field y1_state. String case distribution.
                      </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/sources_extractFD_results_fielddetail_uv.png" alt="extract Field Definition field y1_state top unique values">
                      <figcaption>
                        Extract Field Definition results for field y1_state. Top unique values.
                      </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/sources_extractFD_results_fielddetail_uvlow.png" alt="extract Field Definition field y1_state least unique values">
                      <figcaption>
                        Extract Field Definition results for field y1_state. Lowest unique values.
                      </figcaption>
                    </figure>
                    <p>
                      To save this analysis as a Field Definition which can then be used for multiple sources files click 
                      the icon to do so after closing the popup ( OK button ) for 'y1_state' details if it is still open. 
                      This will store the new Field Definition with a name for the source file and also will assign it as the 
                      Field Definition for the source file. Field Definitions can be assigned to multiple files. A file can 
                      have its Field Definition changed using menu in Sources listing with the file selected in the table. 
                    </p>
                    <figure>
                      <img src="../assets/images/sources_extractFD_saved_assignedinlist.png" alt="extract Field Definition assigned">
                      <figcaption>
                        Extract Field Definition saved and assigned to source file in file list.
                      </figcaption>
                    </figure>
                    <br><h3>Assign to File or Edit</h3>
                    <p>
                      To change or remove an assigned Field Definition from a file, use the menu for Assign FieldDef to File. 
                      Select a title to reassign or -none- to remove.
                    </p>
                    <p>
                      To modify a Field Definition, select the menu option to edit and then choose which Field Definition from selection box. 
                      As an example, we select the recently auto-extracted Field Definition. 
                    </p>
                    <figure>
                      <img src="../assets/images/sources_editFD_choose.png" alt="select Field Definition to edit">
                      <figcaption>select Field Definition to edit</figcaption>
                    </figure>
                    <p>
                      Our example data is from the IRS Migration data set. Auto-Extract set field names, data 
                      types, and formats that we can refine using the IRS documentation. 
                    </p>
                    <figure>
                      <img src="../assets/images/editFD_IRSMigr.png" alt="Editing Field Definition">
                      <figcaption>Editing Field Definition</figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/IRSMigration_reclayout.png" alt="IRS data definition">
                      <figcaption>IRS Migration data definition</figcaption>
                    </figure>
                    <p>
                      The AGI is defined having unit of thousands of dollars, and has special coding such 
                      that although negative numbers are allowed a value = -1 means not to use the value 
                      since it is intentionally withheld. This is the type of data value encoding that becomes 
                      forgotten over time and makes its way into downstream applications thereby corrupting those 
                      results. For example, calculating analytics will produce large overall errors if include 
                      -1 as an AGI when actual value is much different. Thus, we will make some adjustments to 
                      AGI and then plan to adjust it further after initial VeityRefiner processing and 
                      subsequent use in analytics and models. That is, we know and embrace an iterative workflow 
                      to make and use High Fidelity data. This is essential in high impact data use cases. Select 
                      AGI in table and then click icon to edit it.
                    </p>
                    <figure>
                      <img src="../assets/images/editFD_editFld.png" alt="edit Field">
                      <figcaption>Edit field AGI</figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/editFD_editFld_help.png" alt="Edit field context help">
                      <figcaption>Edit field context help provides detailed guidance and should be used</figcaption>
                    </figure>
                    <br><h4>Change Target Mapping</h4>
                    <p>
                      We will enter a new target name. If target properties are not changed then they 
                      are automatically set to the original source settings so this provides an 
                      optional way to map source fields to different target field names, data types, and formats.
                    </p>
                    <figure>
                      <img src="../assets/images/editFD_editFld_changetarget.png" alt="edit Field change target">
                      <figcaption>Edit field AGI changing target name and data type</figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/editFD_fields_cansavenow.png" alt="Changes cause save button to active">
                      <figcaption>Edit field AGI changing target enables the CANCEL and SAVE buttons</figcaption>
                    </figure>
                    <p>
                      Saving this change brings back the field list table. To see the mapping, click icon at top 
                      highlighted by arrow, and notice the new target name we just made.
                    </p>
                    <figure>
                      <img src="../assets/images/editFD_editFld_targetmap.png" alt="View source-target map">
                      <figcaption>Source-Target map. Arrows highlight icon at top to switch to this view and the new target name</figcaption>
                    </figure>
                    <br><h4>Enrichment Fields</h4>
                    <p>
                      Enrichment metadata fields can be added to the target fields for the output 
                      High Fidelity data made from source data. They are an important aspect of High 
                      Fidelity data since the quality and usefullness of data is intertwined with 
                      how it is used. An oft repeated lesson learned in science and computational 
                      modeling is that there is never a time when data is perfect and does not need 
                      to be reviewed in future periods. Thus, enrichment fields provide multiple benefits:
                      <ul>
                        <li>In record documentation of quality and pedigree</li>
                        <li>In record status flags</li>
                        <li>Additional facets for Machine Learning</li>
                        <li>Normalized values for high performance computing</li>
                        <li>Harmonized data for multi-system exchange</li>
                        <li>Linkage to enterprise governance and management processes</li>
                        <li>Alignment to analytics and reporting</li>
                      </ul>
                      <br>
                      Use the + icon to add a field. To specify the field's position in 
                      output records set the TARGPOS value. The full set of target positions will be 
                      sorted and deconflicted when Field Definition is saved so only relative values 
                      are needed during editing. Similarly, you can leave the TARGET field empty in 
                      which case it will be assigned to the new field TITLE. 
                      <b>Ensure you select ENRICH as type</b> otherwise it will be 
                      considered a source field and if not 
                      present in source data (which it will not be) an error will occur.
                    </p>
                    <figure>
                      <img src="../assets/images/editFD_addFld.png" alt="Add field">
                      <figcaption>Add new field. A warning is displayed that the default title must be changed.</figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/editFD_addFld_enrich.png" alt="Add enrichment field">
                      <figcaption>Add new field as enrich by setting the TYPE. Click SAVE.</figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/editFD_addenrich_list.png" alt="Added enrichment field in list">
                      <figcaption>Added enrichment field shown in Fields.</figcaption>
                    </figure>
                    <br><h4>Add Transform</h4>
                    <p>
                      VerityRefiner has a set of transforms that can be used to adjust sources values for 
                      cleansing, normalizing as well as generating new values for enrichment fields and synthetic 
                      data. See the TRANSFORM FUNCTIONS section for details. We create a transform here as 
                      an example. 
                    </p>
                    <p>
                      Click icon to enter Transforms view. Then click icon top of table to add a transform. 
                      Transforms are made and tested using a series of selections. Change the 
                      title to the target field it will be attached to. We are creating a transform 
                      to fill the enrichment field 'useAGI'. A transform has one or more operations (Ops) 
                      so we add the first one.
                    </p>
                    <figure>
                      <img src="../assets/images/editFD_addtransform.png" alt="Add transform">
                      <figcaption>
                        Add a transform by clicking icon in top banner highlighted by arrow to go 
                        into TRANSFORMS view. Then click icon + to add a new transform.
                      </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/editFD_newtransform_useagi.png" alt="New transform form">
                      <figcaption>
                        Transform made for target field 'useAGI'. Next, define one or more operations (Ops).
                      </figcaption>
                    </figure>
                    <p>
                      Add and edit Ops using icons at top table. The ORDER is 0-based relative ordering 
                      which will be used to sort all Ops when saved. Ops are organized by categories and then 
                      functions. Since the field 'useAGI' is an enrichment field, it does not have an initial value 
                      as source fields do. Therefore, we are creating a value not just modifying a source record value. 
                      We will use an assignment Op first to set the value by reference to the souce field AGI.  
                      Then, we use a CONDITIONAL Op first knowing that the flow is Conditional -> if false (then stop) 
                      -> else if true. We will base the conditional on the source field value for 'AGI'. As you 
                      select drop down settings, new options will be made available depending on the category and function. 
                      This includes parameters to set some of which may be required and some optional. Transforms 
                      calculate in a chained manner like Excel formulas and calculators. The initial value is 
                      defaulted to the value for the field in the source record, or as assigned using one of 
                      the Assignment functions. These functions allow using a referenced value to a different 
                      field. We do this for Op0 setting the initial value to that of the AGI field.
                    </p>
                    <figure>
                      <img src="../assets/images/editFD_transform_useagi_op0.png" alt="Assignment operation for transform for useAGI">
                      <figcaption>
                        First operation (Op0) for transform for target field 'useAGI'. Set to current record's value for 
                        field 'AGI'.
                      </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/editFD_transform_useagi_op1.png" alt="Conditional operation for transform for useAGI">
                      <figcaption>
                        Second operation (Op1) for transform for target field 'useAGI'. The Conditional IfEq evaluates the value 
                        currently present which is the result from Op0 to see if it = -1 which we know from 
                        IRS documentation is not a true value.
                      </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/editFD_transform_useagi_op2.png" alt="operation for false conditional result">
                      <figcaption>
                        Third operation (Op2) which executes only if the result from prior conditional  
                        is false.
                      </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/editFD_transform_useagi_op3.png" alt="operation for true conditional result">
                      <figcaption>
                        Fourth operation (Op3) which executes only if the result from prior conditional  
                        is true.
                      </figcaption>
                    </figure>
                    <p>
                      After saving Op3 the transform shows a summary of Ops and the order they will be executed in. To 
                      change the execution order, edit one or more and change its ORDER value recalling that 
                      only relative positions are needed. 
                    </p>
                    <figure>
                      <img src="../assets/images/editFD_transform_useagi_op_list.png" alt="All operations">
                      <figcaption>
                        List of Ops for transform useAGI.
                      </figcaption>
                    </figure>
                    <p>
                      Test overall operation of the transform by entering a suitable test value and using 
                      green arrow to run. Results show step wise operations with input and output values. 
                    </p>
                    <figure>
                      <img src="../assets/images/editFD_transform_useagi_test_true.png" alt="Test transform with true outcome">
                      <figcaption>
                        Test transform with sample value -1 resulting in correct final value = true.
                      </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/editFD_transform_useagi_test_false.png" alt="Test transform with false outcome">
                      <figcaption>
                        Test transform with sample value 136 resulting in correct final value = false.
                      </figcaption>
                    </figure>
                    <figure>
                      <img src="../assets/images/editFD_transform_useagi.png" alt="Transform saved to Field Definition">
                      <figcaption>
                        Saved transform now part of Field Definition.
                      </figcaption>
                    </figure>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header color="teal lighten-4" @click="SetScroll(3)">
                    Jobs
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <p>
                      Jobs are listed from the top application banner menu. A list of any made 
                      or run in the past 90 days is automatically retrieved. Use the Search 
                      icon to either search within the current table list, or set options to 
                      get a new list from the server. Some settings are only available depending on  
                      your account and configuration. 
                    </p>
                    <p> 
                      There are two types of jobs. One performs only a deep 
                      analysis of the source(s) specified. The other also performs this analysis but generates 
                      corrected, normalized, enriched High Fidelity data. Sources can come from a variety 
                      of locations (if configured) and file types and associated structures. Each source file 
                      must have an assigned Field Definition to be used for High Fidelity output. For just 
                      analysis, a Field Definition can be used to determine errors in more detail if fields have 
                      defined data types, formats, and ranges. For records that are broken into multiple lines, 
                      the built-in AutoStitch algorithm uses its rules and statistical information culled 
                      from the source data to determine how to "stitch" together broken lines into one 
                      good error-free record. This behavior can be refined with some advanced settings such 
                      as setting a field to be "pinned" (see context help for more information).
                    </p>
                    <p>
                      As a speciality High Fidelity tool, all actions 
                      are based on extensively tested human expertise. This means advanced settings are 
                      optional and there are a small number of them. Similarly, actions are constrained 
                      to those making High Fidelity and avoiding common mistakes that can be made in 
                      general purpose tools. 
                    </p>
                    <v-expansion-panels focusable>
                      <v-expansion-panel>
                        <v-expansion-panel-header color="blue-grey lighten-1" @click="SetScroll(3)">
                          Preparing Source Data
                        </v-expansion-panel-header>
                        <v-expansion-panel-content eager>
                          <p>
                            To showcase how to make a job, run it, and use results we use the sample IRS Migration 
                            data also shown in the Field Definition section. To make a job, first confirm the souce files 
                            are present in VerityRefiner. Assign a Field Definition to source files 
                            for greater control of output, to use transforms, and add enrichment fields. It 
                            is well worth the time to review Field Definitions for accuracy relative to the source files.
                          </p>
                          <figure>
                            <img src="../assets/images/editFD_IRSMigr.png" alt="Editing Field Definition IRS Migration">
                            <figcaption>Editing Field Definition for IRS Migration</figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/IRSMigration_reclayout.png" alt="IRS data definition">
                            <figcaption>IRS Migration data definition</figcaption>
                          </figure>
                          <p>
                            We add additional enrichment fields and transforms, and set field formats to complete 
                            the Field Definition to run a job to generate High Fidelity output. 
                          </p>
                          <figure>
                            <img src="../assets/images/FD_IRSMigr_fields.png" alt="IRS Field Definition fields">
                            <figcaption>IRS Migration Field Definition fields including two enrichment fields</figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/FD_IRSMigr_targets.png" alt="IRS Field Definition targets">
                            <figcaption>IRS Migration Field Definition targets including two enrichment fields and two renamed source fields</figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/FD_IRSMigr_transforms.png" alt="IRS Field Definition transforms">
                            <figcaption>IRS Migration Field Definition transforms</figcaption>
                          </figure>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header color="blue-grey lighten-2" @click="SetScroll(3)">
                          Configuring Job
                        </v-expansion-panel-header>
                        <v-expansion-panel-content eager>
                          <p>
                            An existing job can be used as a basis for a new job by using COPY after selecting it in the 
                            table. Alternately, start with an empty job using NEW in menu.
                          </p>
                          <figure>
                            <img src="../assets/images/job_new.png" alt="New job form">
                            <figcaption>Empty new job form</figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_new_help.png" alt="New job form help">
                            <figcaption>Empty new job form context help by clicking ? icon highlighted by arrow.</figcaption>
                          </figure>
                          <p>
                            Begin by entering a title. A good title should convey what is important about the job 
                            and the type of source and output data. In addition, it is typical when making High 
                            Fidelity data to run a job multiple times as new insights occur so it is good to 
                            plan a simple way to use a indexed number along with a title such as IRS_Migration_1 
                            which can then become IRS_Migration_2 with the next run. Then, carefully set the properties:
                            <ul>
                              <li>Analysis Only: When true only the analysis phase is done not the generation of output data</li>
                              <li>
                                Extract FieldDef: When true the auto-extract process will be run first to extract a 
                                Field Definition which is then used during subsequent processing. 
                              </li>
                              <li>String Case:a global setting whether case matters or is case insensitive</li>
                              <li>MaxRecs: If integer >0 then maximum number of source records to process</li>
                              <li>MaxErrors: If integer >0 then maximum numbers of errors that can occur before job is stopped</li>
                            </ul>
                          </p>
                          <p>
                            We set key job settings for Title, AnalysisOnly, and provide a short Note. Then, 
                            click on pencil icon in the Sources card to setup source files. Select file(s) and 
                            click icon to retrieve their Field Defintions which will put the number of source files 
                            defined in the #Fields column. Note whether a source file does not have a Field Definition 
                            in which case the job can only be AnalysisOnly or use the Extract FieldDef option. 
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_sourceadd.png" alt="add source to job">
                            <figcaption>Selected source file for job and clicked icon to retrieve its Field Definition. Entered Note and set AnalysisOnly</figcaption>
                          </figure>
                          <br><h4>Source Data View Sample</h4>
                          <p>
                            Each source file selected has 2 action buttons in its row in the table. One is to view its 
                            Field Definition, while the other is to view sample data. For sample data, you can set 
                            up to three query conditions including per field using wildcards. This is a powerful way to 
                            find specific issues in your data file as you define the job settings. The results 
                            will either be displayed a scrollable list of full lines from the source file or if 
                            it has an assigned Field Definition then that will be used to parse lines into a field view 
                            in a table. This is especially good for quickly recognizing errors in the Field Definition, 
                            the data file, or both. 
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_sourceadd_viewdata_form_help.png" alt="view source data form">
                            <figcaption>Selected source file for job and clicked its row icon to view sample data</figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_sourceadd_viewdata_table.png" alt="view source data in table">
                            <figcaption>Selected source sample data in table with field columns since it had an assigned Field Definition</figcaption>
                          </figure>
                          <br><h4>Source Data Record Filters</h4>
                          <p>
                            Record fileters are optional rules to limit use of source records to only those 
                            meeting these conditions. See conext hep for details of syntax.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_sources_recfilter_form.png" alt="sources Record Filters">
                            <figcaption>Sources have optional Record Filters</figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_sources_recfilter_form_help.png" alt="sources Record Filters guidance">
                            <figcaption>Guidance for optional Record Filters</figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_sources_recfilter_edit.png" alt="create Record Filter">
                            <figcaption>Making Record Filter</figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_sources_recfilter_list.png" alt="sources Record Filters list">
                            <figcaption>List of Record Filters</figcaption>
                          </figure>
                          <br><h4>View Source Field Definition</h4>
                          <p>
                            Clicking the icon to view the selected file's Field Definition shows it in a popup window.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_sourceadd_viewFD.png" alt="selected source view Field Definition">
                            <figcaption>View Field Definition of selected source file</figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_sourceadd_viewFD_targ.png" alt="selected source view Field Definition by target">
                            <figcaption>View Field Definition by target of selected source file</figcaption>
                          </figure>
                          <br><h4>Saving Job for Further Action</h4>
                          <p>
                            You can save the job which will then show in table listing that it has a status of 
                            NOTRUN. This means that it can either be edited more, or RUN. Jobs can have other status values, such as 
                            RUNNING for in-process, COMPLETED for a successfully run job, KILLED (user chose Kill menu on a running job), 
                            or an error message. For NOTRUN, select the appropriate menu option when ready. 
                          </p>
                          <p> 
                            After saving the SOURCES the main job view will show how many sources are selected. 
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_main_sourceadded.png" alt="saved source in job">
                            <figcaption>
                              Job has saved SOURCE with its card listing the number and total sizes of source files.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_list_notrun.png" alt="saved job with NOTRUN status">
                            <figcaption>
                              Job is saved and since has NOTRUN status can be further edited, set to RUN, copied 
                              into a new job, or deleted. Other status values will disallow some of these menu options.
                            </figcaption>
                          </figure>
                          <br><h4>CoValues: Correlated Fields</h4>
                          <p>
                            CoValues are joint field combinations which will be analyzed for their  
                            combined unique value distributions. This analysis can create very large amount of data so should be used  
                            only for fields with a small number of unique values (such as codes like US States) and not fields like  
                            prices or other where each record usually has a non-repeatable value. You can set 2 or 3 field combinations. 
                            Click SAVE on the CoValue edit form, then for table listing all CoValues, and then for Field Mappings. 
                            At the job summary view, the card for Field Maps & CoValues will show how many are defined.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_cov_add.png" alt="Addng CoValue to analyze">
                            <figcaption>
                              Adding CoValue to analyze. Use selection boxes to choose 2 or 3 fields. 
                              Make sure you choose fields that have a limited value space and not 
                              those like price or and ID that change for each record.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_cov_list.png" alt="CoValue in list">
                            <figcaption>
                              Added CoValue in list. Click SAVE.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_cov_card.png" alt="CoValue card">
                            <figcaption>
                              CoValue card in job summary shows one defined.
                            </figcaption>
                          </figure>
                          <br><h4>Field Maps</h4>
                          <p>
                            Fields maps are availble in the job card FIELD MAPS & COVALUES. 
                            Field Maps show the link between source fields and target fields. 
                            If the job is only doing analysis then only SOURCE fields are 
                            displayed. If the job setting for AnalysisOnly = FALSE so refined 
                            data will be generated, you can change the basis to be TARGET or 
                            SOURCE. When the basis is TARGET, the table contains a list of 
                            target fields in order of how they will occur in Refined result files 
                            along with the target properties including data type, format rules, 
                            whether enrichment, to be encrypted, the number of linked source fields, 
                            and if excluded. Each target also has usage of all source Field 
                            Definitions linked to it. When the basis is SOURCE, the table 
                            contains source fields with their properties from each Field 
                            Definition. Source Fields show a composite FORMAT column which 
                            depends on datatype. Suffixes denote special settings: (E) means 
                            the property to allow empty values is true; (P) means the property 
                            to PIN the field when doing automatic correction of parsing errors 
                            is true. Excluding a source field means it will not be analyzed nor 
                            used in processing. Its usage will show all the source Field Definitions 
                            and files with the field.                       
                          </p>
                          <br><h4>Running Job</h4>
                          <p>
                            To run a job select one in the table that is in the NOTRUN status. Use icon menu and clock RUN. 
                            Jobs run asynchronously so you will wait for a response from the server while it checks the job 
                            to ensure it meets requirements, then submits it to the VerityRefiner engine and finally 
                            responds back to the client it successfuly started it. If something is wrong, then the 
                            response will state this. If for some reason a more significant error occur you may 
                            see a status beginning with "notok:" in which case you should send a creen shot of this 
                            along with your job to the VerityRefiner administrator at Technik Interlytics who 
                            will analyze the situation.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_runicon.png" alt="Menu icon to run or stop job">
                            <figcaption>
                              Use this icon to run a job in NOTRUN status or STOP job in RUNNING status.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_running.png" alt="job is running">
                            <figcaption>
                              Job in RUNNING status.
                            </figcaption>
                          </figure>
                          <br><h4>Stopping Job</h4>
                          <p>
                            While a job is in the RUNNING status, you can choose to STOP it using the menu icon. 
                          </p>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header color="blue-grey lighten-3" @click="SetScroll(3)">
                          Job Results
                        </v-expansion-panel-header>
                        <v-expansion-panel-content eager>
                          <p>
                            You can check for job completion by refreshing the list (search jobs on server), or after getting an 
                            email from VeityRefiner providng job status, refresh the list. 
                            Then select the job and use menu icon to view it. The overview 
                            shows a variety of metrics: Source Assay, Field Assay, Deep Inspect. 
                            Explanations of each are available by mousing over the 'i' next to each. Clickng the sheet icon 
                            next to each will show additional data. 
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_result_overview.png" alt="result summary for completed job">
                            <figcaption>
                              Summary view of completed job. The cards show number of sources, total file size, and number of records. 
                              Results card states there are errors and special characters discovered. The Field Maps & CoValues card 
                              shows number of source fields and CoValues analyzed.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_result_overview_metric_summary.png" alt="result summary for completed job with metric explanations">
                            <figcaption>
                              Three primary metrics are shown. Source Assay is measure of error free 
                              soure records which is this case is high at 98.1% but still has some errors. Field Assay 
                              measures source data fields that were error free which here is a very low 14.3% which requires 
                              additional investigation. The Deep Inspect measures more complex factors which is 80%.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_result_overview_metric_detail.png" alt="result summary for completed job with metric details">
                            <figcaption>
                              Details from mouse over for each of the three primary metrics. For eaxmple, there are 19 of 1003 records 
                              with errors. For High Fidelity, all 19 must be reviewed and fixed. For Field Assay, there are many problems 
                              with datatype and format. Deep Inspect shows problems with parsing and existence of special characters.
                            </figcaption>
                          </figure>
                          <br><br><h3>Field Maps & CoValues</h3>
                          <p>
                            Click icon on Field Maps & CoValues card to see the list of fields and CoValues used.
                            An icon in each field's row in the Field Map will show a popup with the usage of that field meaning all the 
                            source files that have the field. In situations like current example, only one source is used so this is a 
                            simple list but for jobs using many source files this view identifies the lineage for the field.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_result_fieldmap.png" alt="completed job field map">
                            <figcaption>
                              Clicking icon on Field Maps card show detailed list of fields used for analysis. Click icon at 
                              top of table to see Covalues.
                            </figcaption>
                          </figure>                  
                          <figure>
                            <img src="../assets/images/job_IRSMigr_result_fieldmap_fieldusage.png" alt="completed job field usage from field map list">
                            <figcaption>
                              Field usage showing all the source files that have the field. 
                            </figcaption>
                          </figure>
                          <br><br><h3>Sources</h3>
                          <p>
                            Click icon on Sources card to see details of source files used. There are four icon actions at the 
                            top of the table.
                            <ul>
                              <li>Help information</li>
                              <li>Download table entries into CSV file</li>
                              <li>View source record filters if any defined</li>
                              <li>Search table which is useful when many source files are listed</li>
                            </ul>
                            <br>Each source file in the table has two action icons in last column: View Field 
                            Definition and View Source Data
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_result_sources.png" alt="completed job sources list">
                            <figcaption>
                              Icons in each row in the Sources table allows viewing its Field Definition (same display described in 
                              previous Field Definition section), and also retrieving sample data from the file using fielded query 
                              (as described in earlier section on Sources for job).
                            </figcaption>
                          </figure>
                          <br><br><h3>Results</h3>
                          <p>
                            Enter the detailed results section by clicking icon on RESULTS card in summary view. There are 
                            four main areas:
                            <ul>
                              <li>Source RecLength: distribution of source record lengths overall and per file</li>
                              <li>
                                Errors/Anomalies: primary analysis results to thoroughly review to determine 
                                root causes of errors impeding High Fidelity data output
                              </li>
                              <li>Fields: Unique Value distribution per field and for CoValues if they were defined</li>
                              <li>Refined: access to generated refined data output if the job created it</li>
                            </ul>
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard.png" alt="completed job results areas">
                            <figcaption>
                              There are four main areas of results. Click each for more detail.
                            </figcaption>
                          </figure>
                          <br><h4>Record Lengths</h4>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_reclength.png" alt="completed job results record lengths">
                            <figcaption>
                              Record length distribution. If more than one source file is used then you can select which to show.
                            </figcaption>
                          </figure>
                          <br><h4>Fields</h4>
                          <p>
                            The Fields results contain a great deal of detail into the state of data and sufficient 
                            indicators to discover root causes of errors. Again, if more than one source file was used 
                            you select which to show results for using selection box in header area. At top of table 
                            are four action icons:
                            <ul>
                              <li>Information for this view</li>
                              <li>Toggle view among source, target, and CoValues (if defined)</li>
                              <li>View details of field selected in table</li>
                              <li>Download table into text file</li>
                            </ul>
                          </p>
                          <br><h5>Source Fields</h5>
                          <p>
                            This example job shows that several fields have HiFi quality which is the highest whereas 
                            one (AGI) is only High and another (y1_state) is a problem with a Good quality. These 
                            require deeper inspection and ultimately modifying Field Definition settings and 
                            possibly defining special transforms to correct innate data source errors. Note that 
                            AGI has four special characters discovered, fields N1, N2, AGI have five empty occurrences each.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields.png" alt="completed job results field information">
                            <figcaption>
                              Field information including Quality metric and summary of assigned data type, format, and measured 
                              unique values, number empty occurrences, statistics for number fields, special characters 
                              discovered, and value range conditions if defined.
                            </figcaption>
                          </figure>
                          <p>
                            Selecting y2_statefips and clicking icon for details displays graphs and tables of 
                            more in-depth details. The Quality Factors graph has different factors per data type. 
                            For integer, this field has excellent quality based on data type and value range conformance. 
                            Its unique value distribution is evenly spread for most values but has a few values 
                            with very low occurrences. This may be true data or it may indicate a few problems. 
                            We investigate further by looking at the top occurring values and the lowest occurring values. 
                            The top values all look fine for data type, format, and flat distribution. The bottom values 
                            show a serious problem since there are some values that are words not 2 digit integers. This 
                            can only occur if there are problems with the source records although only a few among the 
                            total source record set. This is also evident in the data type distribution showing three 
                            strings amidst one thousand integers. As a number data type, there is also a numeric 
                            density display thats shows a good flat distribution for almost all, but not all, values. 
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y2statefips_quality.png" alt="completed job results field detail y2_statefips quality">
                            <figcaption>
                              Field detail information for y2_statefips Quality factors.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y2statefips_uvdist.png" alt="completed job results field detail y2_statefips unique values">
                            <figcaption>
                              Field detail information for y2_statefips Unique Value distribution.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y2statefips_uvtop.png" alt="completed job results field detail y2_statefips top unique values">
                            <figcaption>
                              Field detail information for y2_statefips Unique Value distribution top occurring.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y2statefips_uvbot.png" alt="completed job results field detail y2_statefips bottom unique values">
                            <figcaption>
                              Field detail information for y2_statefips Unique Value distribution bottom occurring.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y2statefips_numdens.png" alt="completed job results field detail y2_statefips numeric density">
                            <figcaption>
                              Field detail information for y2_statefips numeric density distribution.
                            </figcaption>
                          </figure>
                          <p>
                            Field y1_state has worst Health metric so viewing its details will provide insight into why.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y1state_quality.png" alt="completed job results field detail y1_state quality">
                            <figcaption>
                              Field detail information for y1_state Quality factors. Since string data type, it has different factors than y2_statefips.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y1state_uvdist.png" alt="completed job results field detail y1_state unique values">
                            <figcaption>
                              Field detail information for y1_state Unique Value distribution.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y1state_uvtop.png" alt="completed job results field detail y1_state top unique values">
                            <figcaption>
                              Field detail information for y1_state Unique Value distribution top occurring.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y1state_uvbot.png" alt="completed job results field detail y1_state bottom unique values">
                            <figcaption>
                              Field detail information for y1_state Unique Value distribution bottom occurring. Anomalous values 
                              indicate serious errors within source data.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y1state_dtypedist.png" alt="completed job results field detail y1_state data type distribution">
                            <figcaption>
                              Field detail information for y1_state data type distribution. Anomalous values 
                              indicate serious errors within source data.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y1state_casedist.png" alt="completed job results field detail y1_state string case distribution">
                            <figcaption>
                              Field detail information for y1_state string case distribution. Anomalous values 
                              indicate serious errors within source data.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_y1state_strlendist.png" alt="completed job results field detail y1_state string length distribution">
                            <figcaption>
                              Field detail information for y1_state string length distribution. Anomalous values 
                              indicate serious errors within source data.
                            </figcaption>
                          </figure>
                          <p>
                            Since the field AGI has special characters in its value distribution, there is an option to display 
                            them in details.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_fields_agi_specchar.png" alt="completed job results field detail AGI special characters">
                            <figcaption>
                              Field detail information for AGI special characters. When the special character is viewable, 
                              it is displayed in the table otherwise its codepoint (e.g. unicode_8252) is listed. 
                              These may or may not be acceptable values depending on the value range specification 
                              that should exist from the data owner.
                            </figcaption>
                          </figure>
                          <br><h5>Targets</h5>
                          <p>
                            Using the toggle icon action changes the view to show by target fields.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_targets.png" alt="completed job results targets list">
                            <figcaption>
                              Target information. Since this job was for analysisOnly, no target results were made. Thus, the 
                              table columns related to results are empty. These will be filled when a job generates output data.
                            </figcaption>
                          </figure>
                          <br><h5>CoValues</h5>
                          <p>
                            Toggling again shows CoValues since at least one of these was defined. 
                            A summary is shown. Select item in table and then 
                            click icon for more detail. The CoValue unique value distribution is listed for top fifty. 
                            This is one of several rapid views in VerityRefiner that speed discovering the 
                            presence of errors since it links the semantic value spaces of two to three fields. Use the selection 
                            box to view either the top fifty most prevalently occurring values or the bottom 25 least prevalent.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_covalues.png" alt="completed job results covalues list">
                            <figcaption>
                              CoValue information.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_covalues_uv.png" alt="Unique Values for completed job results covalue">
                            <figcaption>
                              CoValue information unique value list of top fifty most prevalent.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_covalues_uvlow.png" alt="Bottom 25 Unique Values for completed job results covalue">
                            <figcaption>
                              CoValue information unique value list of bottom tenty five least prevalent.
                            </figcaption>
                          </figure>
                          <br><br><h4>Errors/Anomalies</h4>
                          <p>
                            This showcases errors found for both fields (data type, format, range) and record structure 
                            (parsing) and presence of potentially erroneous special characters which often are invisible 
                            in data tool displays. There are multiple types of details that can be viewed both for all sources 
                            files and for individual source files. The default selection is a summary of Types/Counts.
                          </p>
                          <p>
                            Summary of errors by types and counts. This example is for all source files (top selection box). It shows 
                            that of 1003 records in the source data 19 had errors. Five records had parsing errors which is the most 
                            serious type since it means the fields were incorrectly structured. The nature of the parsing errors are 
                            listed as Too Big (too many fields were parsed relative to number of fields), Too Small 1 (one too few 
                            fields parsed which might be acceptable since often data systems strip off end field value if it is 
                            empty), and Too Small 2+ (2 or more too few fields parsed which is definitely an error). 
                          </p>
                          <p>
                            Subsequent views provide more granular information. For example, 
                            the datatype error example list states exactly what is 
                            wrong with a field's value and what line and file it occurs in. The format error examples similarly 
                            show details of errors and their locations. For fields which have a defined length, errors of this 
                            kind are shown as "length:a/d" where a is the actual length and d is the defined length, as 
                            with "length:5/2" meaning the parsed field value was five characters long while it was defined in the 
                            Field Definition to be two characters long. "case:upper" means the field was defined 
                            to have upper case strings but a lower or mixed case was discovered.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_errors_types.png" alt="Summary errors by types and counts">
                            <figcaption>
                              Summary of errors by types and counts. This example is for all source files (top selection box). The 
                              most serious errors are the parsing (5 of Too Small 2+) which means there are structurally 
                              corrupted records that will not only cause errors in their own values but also lead to 
                              errors in overall aggregations. 
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_errors_dtexample.png" alt="Example data type errors">
                            <figcaption>
                              Detailed examples of datatype errors. The table shows the field title, 
                              its defined datatype, the cause of the error, 
                              the actual field value, the line number in the source file, 
                              and unless viewing for a specific source file the file name. 
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_errors_dtexample_forfile.png" alt="Example data type errors for single source file">
                            <figcaption>
                              Select a single source file instead of aggregated results over all source files used. This example only 
                              used one source but this ability to get more granular information is available in all the result views. 
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_errors_fmtexample.png" alt="Example format errors">
                            <figcaption>
                              Detailed examples of format errors. The table shows the field title, 
                              its defined format, the cause of the error, the actual field value, the line number in the source file, 
                              and unless viewing for a specific source file the file name. length:5/2 means the 
                              field value was five characters long while it was defined in the 
                              Field Definition to be two characters long. case:upper means the field was defined 
                              to have upper case strings but a lower or mixed case was discovered.
                            </figcaption>
                          </figure>
                          <p>
                            Since special characters were discovered this option is available. The table lists the special 
                            characters, the count of occurrences, and the number of examples available to view. Characters 
                            that do not display properly in web pages are listed by their type (ASCII or UNICODE) and the 
                            code for it. Select an item in item and use the icon above the table to see more details. 
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_errors_spcharexample.png" alt="Special characters listed">
                            <figcaption>
                              Special characters discovered. When the special character can be viewed in a web page it is shown, 
                              otherwise it is listed as its codepoint such as unicode_8252.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_errors_spcharexample_u8252.png" alt="Special character Unicode 8252">
                            <figcaption>
                              Details of the special character UNICODE 8252 which is the Double Exclamation Mark. 
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_errors_spcharexample_a167.png" alt="Special character Ascii 167">
                            <figcaption>
                              Details of the special character ASCII 167 which is the section sign. 
                            </figcaption>
                          </figure>
                          <p>
                            When parsing errors occur it is typically very difficult to detect where they originate 
                            and what specific values exist. VerityRefiner provides several views for three types of 
                            parsing errors relative to how many fields are definer per record: too many fields (TooBig), 
                            too few by one (TooFew1) which may or may not be accurate data since some data tools eliminate 
                            the final value if it is empty, too few by two or more (TooFew2+) which is definitely an error 
                            situation.
                          </p>
                          <p>
                            Select an item in table and then click icon for more details. 
                            In this view for Too Few Field2+, the FieldCount states the number of fields in the record 
                            to the number defined (actual/defined). The Value is the actual record. In this example, it is clear 
                            to see that a serious parsing problems exists with the partial record fragments spread across 
                            multiple lines. Fortunately, VerityRefiner already knows the most likely root cause and can 
                            repair the records. 
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_errors_toofew2.png" alt="Parsing errors for too few2">
                            <figcaption>
                              Parsing errors when there are too few fields by 2 or more. 
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_errors_toofew2_detail.png" alt="Details for parsing errors for too few2">
                            <figcaption>
                              Details for parsing errors when there are too few fields by 2 or more. 
                            </figcaption>
                          </figure>
                          <br><br><h3>Exporting Results / Using in Excel & Jupyter (Advanced Plan)</h3>
                          <p>
                            Job settings can be exported to a JSON file in the main listing of jobs and 
                            also when viewing a job using a top banner action icon. The job's analysis results can be 
                            exported in many places typically every time a table or chart is shown. 
                            For a complete set of analysis results, there is an icon action in the RESULTS view.
                          </p>
                          <p>
                            The export file uses // for comment lines. Data is organized in sections that are 
                            demarcated starting with [sectionName] and ending with [/sectionName] with these on their 
                            own lines. Within each section, the first line is delimited table column names 
                            such as item|value. Note that the pipe character is the delimiter. 
                          </p>
                          <br><h4>Sections</h4>
                          <ul>
                            <li>summary: job settings</li>
                            <li>results: summary results with counts of records and various error types</li>
                            <li>
                              fieldDefs: for each Field Definition used a line with key information (not entire Field Definition)
                            </li>
                            <li>sourcefiles: one line source file used with its name (no path), size, and assigned Field Definition</li>
                            <li>datatypeerror: per source field the count of occurrences</li>
                            <li>formaterror: per source field the count of occurrences</li>
                            <li>specialchars: per special character the count of occurrences</li>
                            <li>fieldspecialchars: one line per combination of field and special character with the count of occurrences</li>
                            <li>recordlengths: length in bytes with count of occurrences</li>
                            <li>
                              recordlengthstat: statistics of record length distribution including number of total instances, 
                              number of different lengths, standard deviation, mean value,
                              minimum value, maximum value
                            </li>
                            <li>datatypedist: datatypes detected per field with counts for int, real, date, bool, string, and empty values</li>
                            <li>decimaldist: number of decimal places detected per numeric field (only listed if decimals found) with counts</li>
                            <li>strlendist: For string datatype fields, the distribution of lengths to occurrences</li>
                            <li>strcasedist: For string datatype fields, the distribution by upper case, lower case, mixed case, no cases (i.e. no alpha characters)</li>
                            <li>dateformatdist: For date dataype fields, date format detected to counts</li>
                            <li>
                              fieldquality: For each field the calculated quality factors with its total, by datatype, 
                              by number range, by decials, by string case, by string length, by string range, 
                              by special characters. Not all of these are actually used in calculation since it 
                              depends on data type.
                            </li>
                            <li>
                              datatypeexample: Examples of data type issues listing the field, its assigned 
                              data type, the field value, reason declared error, fileline of record in file
                            </li>
                            <li>
                              formatexample: Examples of format issues listing the field, its assigned 
                              data type, the field value, reason declared error, fileline of record in file
                            </li>
                            <li>
                              bigexample: If any, examples of parsing errors when too many fields found
                            </li>
                            <li>
                              small1example: If any, examples of parsing errors when one too few fields found
                            </li>
                            <li>
                              small2example: If any, examples of parsing errors when one two or more few fields found. 
                              The data line lists: field count as actual/expected like 4/7 when only four fields returned 
                              from parsing when there should be seven, the record line, the fileline number in the source file
                            </li>
                            <li>
                              specialcharexample: Examples of special characters found listed by character code, 
                              field found in, field value, and fileline in source file
                            </li>
                            <li>
                              uniquevalues: This is a long section since many unique values can be found for each field. 
                              The data lines list the field name, the unique value, count of occurrences, and data type of 
                              the unique value. This is typically limited to the top fifty most prevalent values per field 
                              but may have more.
                            </li>
                            <li>
                              uniquevalueslow: Similar to uniquevalues section but for least prevalent values per field. 
                              Typically limited to bottom twenty-five.
                            </li>
                            <li>
                              covalues: This is a long section since many unique values can be found for each combination 
                              of fields (the coValues). The data lines list the coValue name (fields concatenated with :), 
                              the unique value of the coValue (also combined with :), count of occurrences. 
                              This is typically limited to the top fifty most prevalent values per coValue but may have more.
                            </li>
                            <li>
                              covalueslow: Similar to covalues section but for least prevalent values per coValue. 
                              Typically limited to bottom twenty-five.
                            </li>
                          </ul>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_exporticon.png" alt="Results view with highlighted icon to export results">
                            <figcaption>
                              Results view with highlighted icon to export results.
                            </figcaption>
                          </figure>
                          <p>
                            The data is exported in a special format to make it easier to import into other tools such 
                            as Microsoft Excel and Jupyter Notebook.  To import in Microsoft Excel, use its option to set import 
                            parameters including skipping n lines from top, delimiter (note it is pipe | ), and quoted if needed. 
                            After import, you refine the sheets by cutting and pasting sections from initial sheet 
                            into new sheets for each result table type.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_exported.png" alt="Results exported in special format">
                            <figcaption>
                              Results exported in special format.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_exported_excelimport_set.png" alt="Results exported in special format then import into Excel- settings">
                            <figcaption>
                              Results exported in special format then import into Excel- settings.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultscard_exported_excelimport_sheet.png" alt="Results exported in special format then import into Excel- in sheet">
                            <figcaption>
                              Results exported in special format then import into Excel- in sheet. Notice how Excel automatically sets data types 
                              since some values are left justified, some center, and some right. See line 26 is a closing tag from the 
                              exported file that should not stay in your sheet. Use these tags to cut and paste rows into other sheets so 
                              each is a separate result table.
                            </figcaption>
                          </figure>
                          <p>
                            The export file can be read into a Jupyter Notebook with object collections made of the various result data. 
                            A Jupyter Notebook is available from Technik Interlytics that does this and generates many tables and charts, 
                            although they are a subset of the results available in VerityRefiner when viewing a job. Some 
                            examples of this Notebook are shown below.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultsexport_JN1.png" alt="initial part of Jupyter Notebook">
                            <figcaption>
                              Initial cells of Jupyter Notebook importing VerityRefiner results
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultsexport_JN2.png" alt="Assay chart in Jupyter Notebook">
                            <figcaption>
                              Chart of Assay metrics in Jupyter Notebook importing VerityRefiner results
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSMigr_resultsexport_JN3.png" alt="Fields and Datatype Errors in Jupyter Notebook">
                            <figcaption>
                              Chart of Assay metrics in Jupyter Notebook importing VerityRefiner results
                            </figcaption>
                          </figure>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header color="blue-grey lighten-4" @click="SetScroll(3)">
                          Interpreting Results
                        </v-expansion-panel-header>
                        <v-expansion-panel-content eager>
                          <p>
                            The first step in refining the job, and if necessary Field Definitions, is to inspect the 
                            analysis results. Since our example job did have errors we begin by investigating the 
                            following:
                            <ul>
                              <li>
                                Errors/Anomalies: these directly state errors and also situations that might be error.
                                <ul>
                                  <li>
                                    Parsing errors: these indicate structural problems in the records, incorrect 
                                    delimiter, extra delimiters within field values, need to specify values are quoted, 
                                    line feed characters within field values, or a combination of these problems. 
                                    There are three kinds relative to number fields in record: 
                                    TooBig (too many field values), TooFew1 (one too few field values which may be 
                                    acceptable to the sourece data system), TooFew2+ (two or more missing field values).
                                  </li>
                                  <li>
                                    DataType: this indicates incorrect field assignment, non-numeric characters, 
                                    or values that should not exist for the field such as due to parsing errors.
                                  </li>
                                  <li>
                                    Format: this indicates incorrect field assignment, or values that should not 
                                    exist for the field such as due to parsing errors.
                                  </li>
                                  <li>
                                    Range: when the value range is known from documentation and specified in the 
                                    Field Definition, this is a key indicator or problems. If an out-of-range 
                                    value occurs it can be due to incorrect documentation, excess characters like 
                                    special characters, or parsing errors.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                Fields: look at datatype and format results, and correlate these with the 
                                unique value distribution (especially the bottom 25 for the small occrrence problem). 
                                If Special Characters found, their presence does not always mean there is an error since 
                                many multi-language systems will have these as part of presenting non-basic Latin characters. 
                                However, odd characters in supposedly numeric fields will very likely be an error.
                              </li>
                              <li>
                                CoValues: If specified, see if any combinations are notably 
                                incorrect such as a state code and state name not having consistent known values 
                                (e.g. ny and 'new york' is correct while ny and 'data system' is clearly incorrect).
                              </li>
                            </ul>
                          </p>
                          <p>
                            In the example we used, the main findings are:
                            <ul>
                              <li>19 records out of 1003 had errors</li>
                              <li>5 records had parsing errors all of which are Too Few 2+</li>
                              <li>15 records had data type errors</li>
                              <li>8 records had format errors</li>
                              <li>
                                The datatype examples show errors due to empty field for integer type, 
                                non-numeric characters in integer type, enclosing parentheses in number field, 
                                lack of number digits for integer type
                              </li>
                              <li>
                                The format examples show errors due to string length too long and too short, 
                                lowercase value for uppercase field
                              </li>
                              <li>4 special characters occur in fields that should be numeric</li>
                              <li>
                                TooFew2 examples show records that are clearly partial thereby causing 
                                errors in datatype, format in addition to parsing errors
                              </li>
                              <li>
                                <b>y2_statefips</b>: defined as int but actually a 2 digit string code where 
                                leading 0 is important. Had 3 abnormal string values (e.g. non-) which indicates 
                                likely parsing error. Will make string, and rule is must be 2 digits with 
                                added prefix of 0 if only 1 digit and use VerityRefiner enrichment metadata 
                                field to note record has error otherwise                              
                              </li>
                              <li>
                                <b>y1_statefips</b>: similar to above supposed to be 2 digit string. 
                                Has an empty and long integer value which indicate likely 
                                parsing error. Will make same rule as for y2_statefips.                              
                              </li>
                              <li>
                                <b>y1_state</b>: defined as string with length =2 to be state abbreviation 
                                like TX. However, 5 values are lower case, 1 empty, and 2 long integers. 
                                Comparing to the other fields it strongly indicates a parsing error. 
                                A transform will be used to make values of 2 characters upper case                              
                              </li>
                              <li>
                                <b>y1_state_name</b>: string name correlated to y1_state. Has 2 integers, 1 empty, and many 
                                long names that when inspected are not state names such as 'az non-migrants' 
                                and 'ia total migration-us'. Question is whether these are record errors or field 
                                semantics overloaded which is common in large data systems. Checking documentation 
                                shows it is indeed *semantic overloading* of field value with these indicating aggregated 
                                values instead of individual records. These cannot be used is the output data set is intended 
                                to be used as independent records from which various filtered aggregations will be made such 
                                as in analytic charts and database queries. Thus, we will filter out these records in the 
                                High Fidelity output.
                              </li>
                              <li>
                                <b>n1</b>: has 5 empty values, 13 values = -1 which is the most prevalent, 
                                some large values compared to most (including 13.7 million). Documentation states 
                                this is supposed to be number of tax returns therefore a value= -1 does not make sense. 
                                As before, it is common for large data systems to semantically overload fields which 
                                is true for this field with a value of -1 defined as 'suppressed' meaning do not use 
                                this record for aggregation. Thus, we will filter this type of record out of the final 
                                HiFi data.
                              </li>
                              <li>
                                <b>n2</b>: Similar to n1 has 5 empty, a few very large values whereas most are small (e.g. 30 million), 
                                and -1 as most prevalent value. DataType Examples shows there are values with non-numeric prefix 
                                which will be removed. It is defined as the number of exemptions on tax return with -1 
                                indicating 'suppressed'. We will filter out these records.
                              </li>
                              <li>
                                <b>AGI</b>: defined as int. Has 2 real values, 5 empty, and 6 string. -1 
                                is most prevalent value. Has special characters in some values. Documentation 
                                states -1 is suppressed so we will remove these records. Also, AGI is in 
                                thousands of dollars so we will normalize to dollars by multiplying by 1000, 
                                and setting to real with 2 decimal places. Detailed view of DataType Examples 
                                shows special characters occur in several numbers as either prefix or suffix so 
                                must be cleansed. Some values are in parens indicating negative value so this will 
                                be normalized to remove parens.
                              </li>
                              <li>
                                Parsing errors in RESULTS Example TooFew2 shows reason for abnormal values in fields. 
                                Clear evidence of parsing errors with single records broken across multiple lines. 
                                This is common is older large database systems and VerityRefiner has its AutoStitch algorithm 
                                to fix this to make correct records for processing. 
                              </li>
                            </ul>
                          </p>
                          <p>
                            The total view of these results is what is important. No single value can 
                            definitively show errors or lack thereof. In this case, the combination of results 
                            shows a strong likelihood of significant structural and parsing errors which must be handled 
                            in a job to generate High Fidelity output.
                          </p>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header color="blue-grey" @click="SetScroll(3)">
                          Job to Create High Fidelity Output
                        </v-expansion-panel-header>
                        <v-expansion-panel-content eager>
                          <br><h4>Field Definition</h4>
                          <p>
                            VerityRefiner uses a consolidated specification object, the Field Definition, 
                            to define how to organize, normalize, cleanse, and transform source data into 
                            High Fidelity output data while also doing deep expertise driven inspection of data. 
                            Therefore, we go to the Field Definition assigned to the source file we used which 
                            we access in the SOURCES page (use application banner bar menu). We EDIT it selecting 
                            from table and now make changes.                           
                          </p>
                          <figure>
                            <img src="../assets/images/sources_editFD_choose.png" alt="In Sources page choose Field Definition">
                            <figcaption>
                              In SOURCES page, use menu icon to EDIT Field Definition and then select from listing in table
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/editFD_IRSMigr.png" alt="Edit Field Definition">
                            <figcaption>
                              Edit Field Definition used for analysis job
                            </figcaption>
                          </figure>
                          <p>
                            We change the data types and apply formmating rules for string length as stated above. 
                            We set Range rules for fields n1 and n2 to be greater than or equal to 0 so that the suppressed values ( -1 ) 
                            are declared errors. This works in conjunction with a <i>Job setting 'NumDefault'</i> which applies 
                            a specified value when Out-of-Range error is found. But, since we will 
                            define <i>Job setting for 'Source Record Filters'</i> to exclude these records 
                            it is a safety valve for the Job rule. 
                          </p>
                          <figure>
                            <img src="../assets/images/editfield_n1_setrange.png" alt="Edit field n1 to set range">
                            <figcaption>
                              Set field n1 range to be greater than or equal to 0
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/editFD_adjustfields_analres.png" alt="Fields changed">
                            <figcaption>
                              Adjusted fields in Field Definition to prepare for High Fidelity job. Note changed data types, 
                              length rules with padding leading 0, and range rules.
                            </figcaption>
                          </figure>
                          <br><h4>Job Settings</h4>
                          <p>
                            We created a new job by COPYING the job used for analysis. Now, we modify its setting to refine 
                            how it will handle field problems discovered in the analysis. We made some changes to the 
                            Field Definition but the job has many more settings we can use. In the summary view, we see 
                            that the Sources already have the single file selected, that there are three transforms 
                            defined, and the Field Maps & CoValues are defined. The black arrows showcase that we 
                            changed Analysis Only to be false so High Fidelity output is made. 
                          </p>
                          <figure>
                            <img src="../assets/images/editjob_IRSHiFi_summary.png" alt="Editing job for High Fidelity output">
                            <figcaption>
                              Editing job made by copying from analysis job and modifying settings based on 
                              analysis results and objective to make High Fidelity output data.
                            </figcaption>
                          </figure>
                          <p>
                            Select SOURCES and then use icon action to show only the source file selected for the job.
                          </p>
                          <figure>
                            <img src="../assets/images/editjob_IRSHiFi_sources.png" alt="Job for High Fidelity output editing SOURCES">
                            <figcaption>
                              In SOURCES section for new job. Black arrow shows icon action to show only selected source files. 
                              Indigo arrow show icon action to set record filters.
                            </figcaption>
                          </figure>
                          <p>
                            Select the icon action to define record filters since we want to exclude the partial 
                            aggregation records. 
                          </p>
                          <figure>
                            <img src="../assets/images/editjob_IRSHiFi_sources_filter_help.png" alt="Help information for editing SOURCES record filters">
                            <figcaption>
                              In FILTERS page of SOURCES section for new job. Read the context help carefully for syntax required.
                            </figcaption>
                          </figure>
                          <p>
                            Our first filter is for the field y1_state_name where the Unique Values showed 
                            the aggregation records to have the terms 'migrant' and 'migration'. Therefore, we set a filter 
                            to exclude records with '*migra*' since that covers both terms using the wildcard *.
                            It is very likely that the exclusion conditions occur in the 
                            same records and therefore only one would be needed. However, to both ensure these 
                            records are excluded and provide built-in business rules in the job documentation 
                            it is better to define all of them.
                          </p>
                          <figure>
                            <img src="../assets/images/editjob_IRSHiFi_sources_filter_y1statename.png" alt="Record filter for y1_state_name">
                            <figcaption>
                              Record Filter for field y1_state_name excluding records when field has value containing *migra* 
                              meaning any value with 'migra' in it. Important to set Op properly when more than one 
                              filter. Since we want any of the filters to exclude records we set Op = OR.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/editjob_IRSHiFi_sources_filter_n1.png" alt="Record filter for n1">
                            <figcaption>
                              Record Filter for field n1 excluding records when field has value = -1. Important to set Op properly when more than one 
                              filter. Since we want any of the filters to exclude records we set Op = OR.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/editjob_IRSHiFi_sources_filter_all.png" alt="All record filters">
                            <figcaption>
                              All Record Filters. Important to set Op properly when more than one 
                              filter. Since we want any of the filters to exclude records we set Op = OR. 
                            </figcaption>
                          </figure>
                          <br><h4>Run Job</h4>
                          <p>
                            Now that we have modified the job we will run it after we do one final review. When viewing the 
                            JOBS listing use the menu icon to VIEW the job instead of EDITING to avoid making changes 
                            by mistake. Review all settings and note any discrepancies that need to be chnaged in EDIT. 
                            Then, use menu icon to RUN the job which will submit it to server where a thorough check of 
                            all settings will be made. If anything is amiss, the job will not be run and an error 
                            message returned that you must address before trying to RUN job again. 
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_Menuviewicon.png" alt="Select VIEW to review job">
                            <figcaption>
                              Selecting VIEW to review job before running it.  
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_view_fieldmapping.png" alt="Review job field mapping">
                            <figcaption>
                              Review job Field Mapping by Target before running it.  
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_Menurun.png" alt="Select RUN job">
                            <figcaption>
                              Selecting RUN to submit job to server.  
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_running.png" alt="job running">
                            <figcaption>
                              Job successfully passed checks on server and now has status = running.  
                            </figcaption>
                          </figure>
                          <br><h4>Job Results</h4>
                          <p>
                            When the job status is complete, open it to VIEW results. The summary view 
                            shows the same analysis of source data we saw in the prior job (analysis only) but 
                            now includes analysis of the High Fidelity output. We see that the output data has 
                            a 100% Refined Assay. Note that there are 898 refined records which is less than the 
                            number of source records. This is due to both fixing broken records and the exclusion 
                            filters we defined to eliminate the partial aggregation sum records. 
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_summary.png" alt="job completed summary with High Fidelity">
                            <figcaption>
                              Job result summary after analyzing source data and generating High Fidelity output. 
                              Refined assay is analysis of the High Fidelity output and shows 100% quality level.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_view.png" alt="job completed High Fidelity Results">
                            <figcaption>
                              Job generating High Fidelity output Results view. Note ERRORS/ANOMALIES has multiple 
                              types of issues all of which were corrected to make High Fidelity output.
                            </figcaption>
                          </figure>
                          <p>
                            Begin with the ERRORS/ANOMALIES table listing SOURCE ERRORS. Many facets of the data and 
                            the VerityRefiner operations are shown. In this example, note the FILTER EXCLUDE item 
                            showing 102 records were excluded from High Fidelity output since they matched the 
                            filter conditions we defined for the job.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_view_types.png" alt="job completed High Fidelity Results by Types">
                            <figcaption>
                              High Fidelity output job Results view by types. Note Filter Exclude, Recs Parsing Too Few2+, 
                              Recs Error Fixed, Remove Non-Numeric Chars as some of the indicators of how VerityRefiner 
                              corrected multiple kinds of problems in the source data.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_view_filteractions.png" alt="job completed High Fidelity Results Filter Actions">
                            <figcaption>
                              High Fidelity output job Results view by Filter Actions. It is important to review all of the items VerityRefiner 
                              produces to both understand the data set and monitor Quality Assurance of results.
                            </figcaption>
                          </figure>
                          <p>
                            The primary result of the High Fidelity job is the output data. To access it, we 
                            select the REFINED view from RESULTS view. We generated one file with 898 records and 
                            9 fields. Output fields include source fields with some mapped to new names and also 
                            added enrichment metadata fields. The record count includes fixed parsing error source 
                            records and excluded source records by filter conditions. 
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_refined.png" alt="job completed High Fidelity Refined files">
                            <figcaption>
                              High Fidelity output job Refined output file list. Select 
                              output file and use icon actions to download file or view filtered data records.
                            </figcaption>
                          </figure>
                          <p>
                            Select a Refined FIle in the table and use the icons to view data 
                            (with optional filter conditions) and download the file.
                          </p>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_refined_getdata.png" alt="Getting data from High Fidelity Refined file">
                            <figcaption>
                              Selected output file and used icon action to view filtered data records. Setting form to perform query.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_refined_getdata_recs.png" alt="Records from High Fidelity Refined file">
                            <figcaption>
                              Query results without filter parameters.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_refined_getdata_1field.png" alt="Query using 1 field parameter">
                            <figcaption>
                              Query with one filter parameter for StartState= ca.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_refined_getdata_1field_recs.png" alt="Records from High Fidelity Refined file using 1 field filter">
                            <figcaption>
                              Query results with one filter parameter for StartState= ca.
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_refined_getdata_2fields.png" alt="Query using 2 field parameters">
                            <figcaption>
                              Query with two filter parameters for StartState= ca and EndState= FL (note query is case insensitive).
                            </figcaption>
                          </figure>
                          <figure>
                            <img src="../assets/images/job_IRSHiFi_result_refined_getdata_2fields_recs.png" alt="Records from High Fidelity Refined file using two field parameters">
                            <figcaption>
                              Query results with two filter parameters for StartState= ca and EndState= FL.
                            </figcaption>
                          </figure>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6" @click="SetScroll(2)">
              Sample Files
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <p>Click to download or right-click to SAVE-AS</p>
              <ul>
                <li>
                  <a href="/files/excel_conversion_orig_comma.csv" download="excel_conversion_orig_text.csv"><b>CSV to import into Excel</b></a>: 
                  Sample for VerityExcel. Text file with comma delimiter, header row, and number and 
                  string values that showcase problems with standard Excel importing causing corrupted number formats 
                  and loss of number precision. 
                </li>
                <li>
                  <a href="/files/excel_conversion_imported.xlsx" download="excel_conversion_imported.xlsx"><b>Excel spreadsheet after importing CSV file</b></a>: 
                  Sample for VerityExcel. Excel spreadsheet after importing csv file using standard and 
                  automatic settings. 
                </li>
                <li>
                  <a href="/files/Encode1_testData_20200731T175831.dat" download="Encode1_testData.dat"><b>Data file with mixed encoding</b></a>: 
                  Sample for VerityExcel and VerityRefiner. Text file having data in UTF-8 encoding but file itself 
                  has different encoding leading to inability to open and read data. Try it with various text editors, 
                  database importing, Excel importing. Use in VerityRefiner for it to auto-correct with new usable data file output.
                </li>
                <li>
                  <a href="/files/StateAbbrfromFIPS_lookup.dat" download="StateAbbrfromFIPS_lookup.dat"><b>Lookup of US State FIPS codes to abbreviation</b></a>:
                  Delimited text file of FIPS code to state 2 letter abbreviation (e.g. 48 for TX). This is used in LOOKUP transform 
                  in example in User Guide in VerityRefiner and a tutorial.
                </li>
                <li>
                  <a href="/files/StateNamefromFIPS_lookup.dat" download="StateNamefromFIPS_lookup.dat"><b>Lookup of US State FIPS codes to name</b></a>:
                  Delimited text file of FIPS code to state name. This is used in LOOKUP transform 
                  in VerityRefiner tutorial.
                </li>
                <li>
                  <a href="/files/FIPS_StateNametoCodes.dat" download="FIPS_StateNametoCodes.dat"><b>Lookup of US State name patterns</b></a>:
                  Delimited text file of US state name pattern with wilcards to their FIPS code 
                  (e.g. Tex* for Texas code 48, *bama for Alabama code 01). This is used in LOOKUP transform in a tutorial.
                </li>
                <li>
                  <a href="/files/FIPS_stateCountyCodes.dat" download="FIPS_stateCountyCodes.dat"><b>Lookup of US State + county codes</b></a>:
                  Delimited text file of 3 columns requiring a joint lookup codes for US state and county to find county name 
                  (e.g. Alabama code 01 and county code 023 for county name Choctaw). This is used in LOOKUP transform in a tutorial.
                </li>
                <li>
                  <a href="/files/IRSMigration_WithErrors_Hdr.csv" download="IRSMigration_WithErrors_Hdr.csv"><b>IRS Migration data</b></a>:
                  Delimited text file with sample records from the IRS Migration data set with errors introduced for testing. These 
                  data issues are showcased in the VerityRefiner User Guide. This file is used in tutorials. 
                </li>
                <li>
                  <a href="/files/IRSMigrationHiFI_analysis_20240318T112744.dat" download="IRSMigrationHiFI_analysis_20240318T112744.dat"><b>Job results for analyzing IRS Migration data</b></a>: 
                  Exported job information to be read into Jupyter Notebook.
                </li>
                <li>
                  <a href="/files/IRSMigrationHiFI_merged_corrected.dat" download="IRSMigrationHiFI_merged_corrected.dat"><b>High Fidelity output data from IRS Migration data</b></a>: 
                  High Fidelity cleansed, normalized, QC output data to be read into Jupyter Notebook.
                </li>
                <li>
                  <a href="/files/VerityRefiner_Results.ipynb" download="VerityRefiner_Results.ipynb"><b>VerityRefiner Jupyter Notebook file</b></a>: 
                  Jupyter Notebook file generating report with tables and charts from VerityRefiner exported information.
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6" @click="SetScroll(2)">
              Tutorials VerityExcel
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <p>Click to download or right-click to SAVE-AS</p>
              <ul>
                <li>
                  <a href="/files/VE_Tut1_FixExcel.pdf" download="VE_Tut1_FixExcel.pdf"><b>Tutorial 1: Fix Excel</b></a>: 
                  Perform deep analysis to detect value and format corruption. 
                  Determine settings to correct problems with automatic 
                  algorithms. Generate High Fidelity spreadsheet output with 
                  cleansed, normalized data.
                </li>
                <li>
                  <a href="/files/VE_Tut2_csvExcelcsv.pdf" download="VE_Tut2_csvExcelcsv.pdf"><b>Tutorial 2: Making Correct Excel from CSV</b></a>: 
                  Start with CSV text file containing corrupted values from a prior 
                  export from Excel and cleanse and normalize into correct values in 
                  Excel. Export this correct data set into a new csv file maintaining 
                  correct values.
                </li>
                <li>
                  <a href="/files/VE_Tut3_AdvTransform.pdf" download="VE_Tut3_AdvTransform.pdf"><b>Tutorial 3: Using Transforms for Advanced Rules</b></a>: 
                  Start with Excel spreadsheet used in tutorial 1. Add transform to 
                  handle complicated value normalization issue identified in that 
                  tutorial. Generate new fully normalized Excel output.
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6" @click="SetScroll(2)">
              Tutorials VerityRefiner
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <p>Click to download or right-click to SAVE-AS</p>
              <ul>
                <li>
                  <a href="/files/VR_Tut1_LoadAssessFile.pdf" download="VR_Tut1_LoadAssessFile.pdf"><b>Tutorial 1: Load & Evaluate Source Data File</b></a>: 
                  Discover fields, data types, formats, value ranges, errors, inconsistencies, and 
                  automatically extract Field Definition. Review analysis results. Refine Field Definition 
                  by editing field specifications. Assign Field Definition to source file.
                </li>
                <li>
                  <a href="/files/VR_Tut2_CharData_DetectAnom.pdf" download="VR_Tut2_CharData_DetectAnom.pdf"><b>Tutorial 2: Characterize Data & Detect Anomalies</b></a>: 
                  Data intelligence: Fully investigate data health, errors, 
                  inconsistencies with deep inspection job and multiple views. 
                </li>
                <li>
                  <a href="/files/VR_Tut3_upgradeFieldDef.pdf" download="VR_Tut3_upgradeFieldDef.pdf"><b>Tutorial 3: Upgrade Field Definition with Analysis Insights</b></a>: 
                  Upgrade Field Definition to correct errors using built-in datatype 
                  and format controls. Add enrichment fields. Upload files to be lookup dictionaries. 
                  Define transforms for conditional logic value corrections. Define transforms to fill enrichment field values including looking 
                  up codes to replace with names and abbreviations.
                </li>
                <li>
                  <a href="/files/VR_Tut4_MakeHiFi_Jupyter.pdf" download="VR_Tut4_MakeHiFi_Jupyter.pdf"><b>Tutorial 4: Make High Fidelity Data</b></a>: 
                  Create and run job to High Fidelity data using source file and 
                  Field Definition from tutorial 3. Set job to use AutoStitch to repair 
                  broken records, and record filters to remove unusable records. Export 
                  job information to Jupyter Notebook.
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6" @click="SetScroll(3)">
              Blog & Forum
            </v-expansion-panel-header>
            <v-expansion-panel-content eager class="text-body-2">
              <p>
                <a href="https://technikinterlytics.com/blog/index.php/blog/" target="_">Technik Interlytics Blog</a>
              </p>
              <p>
                <a href="https://technikinterlytics.com/blog/index.php/forums/" target="_">Technik Interlytics Forums</a>
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
    </v-container>
</template>

<script>

import { VR_Common as VRC } from '@/assets/js/VR_Common';
import { VR_Transforms as VRT } from "@/assets/js/VR_Transforms.js"

  export default {
    name: 'Home',
    data: () => ({
      alertMsg:{
        show: false,
        msg: "",
        type:"error"
      },
      plans:{
        VE:{
          trial:{},
          standard:{},
          advanced:{},
          custom:{},
        },
        VR:{
          trial:{},
          standard:{},
          advanced:{},
          custom:{},
        },
      },
      features:[],
      planTable:{
        cols:[],
        items:[],
      },
      transformTable:{
        assignment:{cols:[],items:[]},
        conditional:{cols:[],items:[]},
        numeric:{cols:[],items:[]},
        text:{cols:[],items:[]},
        date:{cols:[],items:[]},
      },
    }),
    computed:{},
    watch:{},
    created() {
    },
    mounted() {
      this.SetPlans();
      this.SetPlanTable();
      this.SetTransforms();
    },
    methods:{
      SetPlans() {
        let featureObj={};
        try {
          this.plans.VE.trial={period:"week",price:0,yrDiscPercent:0};
          this.plans.VE.standard={period:"month",price:50,yrDiscPercent:40};
          this.plans.VE.advanced={period:"month",price:70,yrDiscPercent:40};
          this.plans.VE.custom={period:"year",price:0,yrDiscPercent:0};

          this.plans.VR.trial={period:"week",price:0,yrDiscPercent:0};
          this.plans.VR.standard={period:"month",price:70,yrDiscPercent:40};
          this.plans.VR.advanced={period:"month",price:120,yrDiscPercent:40};
          this.plans.VR.custom={period:"year",price:0,yrDiscPercent:0};

          featureObj={
            title:"max jobs per month",
            VE:{
              trial:"5",
              standard:"30",
              advanced:"100",
              custom:"unlimited",
            },
            VR:{
              trial:"5",
              standard:"60",
              advanced:"300",
              custom:"unlimited",
            },
          };
          this.features.push(featureObj);
          featureObj={
            title:"max records",
            VE:{
              trial:"1K",
              standard:"25K",
              advanced:"1M",
              custom:"1M",
            },
            VR:{
              trial:"1K",
              standard:"100K",
              advanced:"500M",
              custom:"unlimited",
            },
          };
          this.features.push(featureObj);
          featureObj={
            title:"max storage",
            VE:{
              trial:"10MB",
              standard:"50MB",
              advanced:"250MB",
              custom:"10GB",
            },
            VR:{
              trial:"10MB",
              standard:"250MB",
              advanced:"1GB",
              custom:"10GB",
            },
          };
          this.features.push(featureObj);
          featureObj={
            title:"add enrichment",
            VE:{
              trial:"yes",
              standard:"no",
              advanced:"yes",
              custom:"yes",
            },
            VR:{
              trial:"yes",
              standard:"no",
              advanced:"yes",
              custom:"yes",
            },
          };
          this.features.push(featureObj);
          featureObj={
            title:"networked dirs",
            VE:{
              trial:"no",
              standard:"no",
              advanced:"no",
              custom:"yes",
            },
            VR:{
              trial:"no",
              standard:"no",
              advanced:"no",
              custom:"yes",
            },
          };
          this.features.push(featureObj);
          featureObj={
            title:"cloud dirs",
            VE:{
              trial:"no",
              standard:"no",
              advanced:"yes",
              custom:"yes",
            },
            VR:{
              trial:"no",
              standard:"no",
              advanced:"yes",
              custom:"yes",
            },
          };
          this.features.push(featureObj);
          featureObj={
            title:"csv/text source",
            VE:{
              trial:"yes",
              standard:"yes",
              advanced:"yes",
              custom:"yes",
            },
            VR:{
              trial:"yes",
              standard:"yes",
              advanced:"yes",
              custom:"yes",
            },
          };
          this.features.push(featureObj);
          featureObj={
            title:"Excel source",
            VE:{
              trial:"yes",
              standard:"yes",
              advanced:"yes",
              custom:"yes",
            },
            VR:{
              trial:"no",
              standard:"no",
              advanced:"no",
              custom:"no",
            },
          };
          this.features.push(featureObj);
          featureObj={
            title:"csv/text output",
            VE:{
              trial:"yes",
              standard:"yes",
              advanced:"yes",
              custom:"yes",
            },
            VR:{
              trial:"yes",
              standard:"yes",
              advanced:"yes",
              custom:"yes",
            },
          };
          this.features.push(featureObj);
          featureObj={
            title:"Excel output",
            VE:{
              trial:"yes",
              standard:"yes",
              advanced:"yes",
              custom:"yes",
            },
            VR:{
              trial:"no",
              standard:"no",
              advanced:"no",
              custom:"no",
            },
          };
          this.features.push(featureObj);
        }
        catch (e) { alert(e.toString()); }
      },
      SetPlanTable() {
        let obj={};
        try {
          this.planTable.cols=[]; this.planTable.items=[];
          obj={value: "item",text: "Item",align: "start",sortable: true,datatype:"string"};
          this.planTable.cols.push(obj);
          obj={value: "trialVE",text: "Trial VE",align: "start",sortable: true,datatype:"string"};
          this.planTable.cols.push(obj);
          obj={value: "standardVE",text: "Std VE",align: "start",sortable: true,datatype:"string"};
          this.planTable.cols.push(obj);
          obj={value: "advVE",text: "Adv VE",align: "start",sortable: true,datatype:"string"};
          this.planTable.cols.push(obj);
          obj={value: "trialVR",text: "Trial VR",align: "start",sortable: true,datatype:"string"};
          this.planTable.cols.push(obj);
          obj={value: "standardVR",text: "Std VR",align: "start",sortable: true,datatype:"string"};
          this.planTable.cols.push(obj);
          obj={value: "advVR",text: "Adv VR",align: "start",sortable: true,datatype:"string"};
          this.planTable.cols.push(obj);
          obj={value: "custom",text: "Custom",align: "start",sortable: true,datatype:"string"};
          this.planTable.cols.push(obj);

          obj={
          id:this.planTable.items.length,
            item:`Price per month USD`,
            trialVE:`${this.plans.VE.trial.price} (week)`,
            standardVE:this.plans.VE.standard.price,
            advVE:this.plans.VE.advanced.price,
            trialVR:`${this.plans.VR.trial.price} (week)`,
            standardVR:this.plans.VR.standard.price,
            advVR:this.plans.VR.advanced.price,
            custom:"Contact Us",
          };
          this.planTable.items.push(obj);
          obj={
            id:this.planTable.items.length,
            item:`Discount Annual %`,
            trialVE:this.plans.VE.trial.yrDiscPercent,
            standardVE:this.plans.VE.standard.yrDiscPercent,
            advVE:this.plans.VE.advanced.yrDiscPercent,
            trialVR:this.plans.VR.trial.yrDiscPercent,
            standardVR:this.plans.VR.standard.yrDiscPercent,
            advVR:this.plans.VR.advanced.yrDiscPercent,
            custom:"NA",
          };
          this.planTable.items.push(obj);

          for (let i=0; i< this.features.length; i++) {
            obj={
              id:this.planTable.items.length,
              item:this.features[i].title,
              trialVE:this.features[i].VE.trial,
              standardVE:this.features[i].VE.standard,
              advVE:this.features[i].VE.advanced,
              trialVR:this.features[i].VR.trial,
              standardVR:this.features[i].VR.standard,
              advVR:this.features[i].VR.advanced,
              custom:this.features[i].VR.custom,
            };
            this.planTable.items.push(obj);
          }
        }
        catch (e) { alert(e.toString()); }
      },
      GoToVerityX(typ) {
        try {
          if (typeof typ!="string") throw("no location specified");
          if (VRC.Contains(typ,"excel",false)) {
            window.open("https://verityexcel.com");
          }
          else if (VRC.Contains(typ,"refiner",false)) {
            window.open("https://verityrefiner.com");
          }
        }
        catch (e) { alert(e.toString()); }
      },
      SetScroll(nTop) {
        let nScroll=0;
        try {
          if (typeof nTop!="number" || !VRC.IsPosZeroInt(nTop)) nTop=0;
          else if (nTop>3) nTop=0;
          if (nTop==0) nScroll=2700;
          else if (nTop==1) nScroll=2800;
          else if (nTop==2) nScroll=3100;
          else if (nTop==3) nScroll=3350;
          this.$vuetify.goTo(nScroll,{});
        }
        catch (err) {
          alert("SetScroll:" + err.toString());
        }
      },
      SetTransforms() {
        let nCat=-1;
        let txt="", cat="", param1="", param2="", param3="", desc="";
        let obj={}, arrObj={};
        try {
          VRT.SetHashArrays();

          this.transformTable.assignment.cols=[]; this.transformTable.assignment.items=[];
          this.transformTable.conditional.cols=[]; this.transformTable.conditional.items=[];
          this.transformTable.numeric.cols=[]; this.transformTable.numeric.items=[];
          this.transformTable.text.cols=[]; this.transformTable.text.items=[];
          this.transformTable.date.cols=[]; this.transformTable.date.items=[];
          obj={value: "name",text: "Name",align: "start",sortable: true,datatype:"string"};
          this.transformTable.assignment.cols.push(obj);
          this.transformTable.conditional.cols.push(obj);
          this.transformTable.numeric.cols.push(obj);
          this.transformTable.text.cols.push(obj);
          this.transformTable.date.cols.push(obj);
          obj={value: "desc",text: "Description",align: "start",sortable: true,datatype:"string"};
          this.transformTable.assignment.cols.push(obj);
          this.transformTable.conditional.cols.push(obj);
          this.transformTable.numeric.cols.push(obj);
          this.transformTable.text.cols.push(obj);
          this.transformTable.date.cols.push(obj);
          obj={value: "param1",text: "Param1",align: "start",sortable: true,datatype:"string"};
          this.transformTable.assignment.cols.push(obj);
          this.transformTable.conditional.cols.push(obj);
          this.transformTable.numeric.cols.push(obj);
          this.transformTable.text.cols.push(obj);
          this.transformTable.date.cols.push(obj);
          obj={value: "param2",text: "Param2",align: "start",sortable: true,datatype:"string"};
          this.transformTable.assignment.cols.push(obj);
          this.transformTable.conditional.cols.push(obj);
          this.transformTable.numeric.cols.push(obj);
          this.transformTable.text.cols.push(obj);
          this.transformTable.date.cols.push(obj);
          obj={value: "param3",text: "Param3",align: "start",sortable: true,datatype:"string"};
          this.transformTable.assignment.cols.push(obj);
          this.transformTable.conditional.cols.push(obj);
          this.transformTable.numeric.cols.push(obj);
          this.transformTable.text.cols.push(obj);
          this.transformTable.date.cols.push(obj);
          if (typeof VRT.hashCat["assignment"]!="number") throw("cannot find assignment in transform categories");
          if (typeof VRT.hashCat["conditional"]!="number") throw("cannot find conditional in transform categories");
          if (typeof VRT.hashCat["numeric"]!="number") throw("cannot find numeric in transform categories");
          if (typeof VRT.hashCat["text"]!="number") throw("cannot find text in transform categories");
          if (typeof VRT.hashCat["date"]!="number") throw("cannot find date in transform categories");
          
          for (let h=0; h<5; h++) {
            if (h==0) {
              cat="assignment";
              arrObj=this.transformTable.assignment.items;
            }
            else if (h==1) {
              cat="conditional";
              arrObj=this.transformTable.conditional.items;
            }
            else if (h==2) {
              cat="numeric";
              arrObj=this.transformTable.numeric.items;
            }
            else if (h==3) {
              cat="text";
              arrObj=this.transformTable.text.items;
            }
            else if (h==4) {
              cat="date";
              arrObj=this.transformTable.date.items;
            }
            nCat= VRT.hashCat[cat];
            for (let i=0; i< VRT.categories[nCat].funcs.length; i++) {
              param1=""; param2=""; param3=""; desc="";
              obj={
                name:VRT.categories[nCat].funcs[i].title.toUpperCase(),
              };
              desc=VRT.categories[nCat].funcs[i].desc;
              if (VRC.Contains(desc,"Param3:",true)) {
                param3= VRC.GetEndPart(desc, "Param3:", true).trim();
                desc=VRC.GetFrontPart(desc,"Param3:",true).trim();
              }
              if (VRC.Contains(desc,"Param2:",true)) {
                param2= VRC.GetEndPart(desc, "Param2:", true).trim();
                desc=VRC.GetFrontPart(desc,"Param2:",true).trim();
              }
              if (VRC.Contains(desc,"Param1:",true)) {
                param1= VRC.GetEndPart(desc, "Param1:", true).trim();
                desc=VRC.GetFrontPart(desc,"Param1:",true).trim();
              }
              obj.desc=desc;
              if (typeof VRT.categories[nCat].funcs[i].param1=="object" && typeof VRT.categories[nCat].funcs[i].param1.datatype=="string") {
                txt = VRT.categories[nCat].funcs[i].param1.required ? "Required." : "Optional.";
                txt += ` ${VRT.categories[nCat].funcs[i].param1.datatype.toUpperCase()}.`;
                txt += ` ${param1}`;
              }
              else txt="NA";
              obj.param1=txt;
              if (typeof VRT.categories[nCat].funcs[i].param2=="object" && typeof VRT.categories[nCat].funcs[i].param2.datatype=="string") {
                txt = VRT.categories[nCat].funcs[i].param2.required ? "Required." : "Optional.";
                txt += ` ${VRT.categories[nCat].funcs[i].param2.datatype.toUpperCase()}.`;
                txt += ` ${param2}`;
              }
              else txt="NA";
              obj.param2=txt;
              if (typeof VRT.categories[nCat].funcs[i].param3=="object" && typeof VRT.categories[nCat].funcs[i].param3.datatype=="string") {
                txt = VRT.categories[nCat].funcs[i].param3.required ? "Required." : "Optional.";
                txt += ` ${VRT.categories[nCat].funcs[i].param3.datatype.toUpperCase()}.`;
                txt += ` ${param3}`;
              }
              else txt="NA";
              obj.param3=txt;
              arrObj.push(obj);
            }
          }
        }
        catch (err) {
          alert("SetTransforms:" + err.toString());
        }
      },
    },
  };
</script>

<style>
  ul li {
    margin-bottom: 20px;
  }

  ol li {
    margin-bottom: 6px;
  }

 code {
    font-family: Consolas,"courier new";
    color: crimson;
    background-color: #f1f1f1;
    padding: 2px;
    font-size: 105%;
  }

  figcaption {
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 1.5;
  }

  figure {
    padding-bottom: 10px;
  }

</style>