import Vue from 'vue'
import Router from 'vue-router'
//import store from '@/plugins/store.js'
import Home from "@/pages/Home.vue"

Vue.use(Router)

const routes = [
    {
        path:"/",
        redirect:"/home",
        name:"Home",
        component: Home,
    },
    {
        path:"/home",
        name:"Home",
        component: Home,
    }
]



export default () => {
    const router = new Router({
        routes,
        mode:"history",
        scrollBehavior:() => ({x:0, y:0}),
    });


    router.beforeResolve((to, from, next) => {
        //store.commit("isLoading", true);
        next();
    })

    router.afterEach(() => {
        setTimeout(function () {
            //store.commit("isLoading", false);
        }, 500);
    })

    const originalPush = Router.prototype.push;
    Router.prototype.push = function (location) {
        return originalPush.call(this, location).catch(err => {
            if (Router.isNavigationFailure(err, Router.NavigationFailureType.duplicated)) {
                // handled duplicate navigation
            } else throw err
        })
    }

    return router;
}

