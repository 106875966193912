//import { data } from "jquery";

export const VR_Common = {

    dblQuote: "\"",
    attrDelim:"/,/",
    crlf:"\r\n",
    hifiMetaFields:["vr_DTE","vr_FE","vr_AE","vr_AEFix","vr_DTEFld","vr_FEFld","vr_Dup"],
    hifiMetaFieldsDT:["bool","bool","bool","bool","string","string","bool"],

//from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    /**
     * Checks if browser supports storage
     * @param {*} type - either sessionStorage or localStorage
     * @returns {boolean} true if OK
     */
    IsStorageAvailable(type) {
        var storage;
        try {
            storage = window[type];
            var x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch (e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                (storage && storage.length !== 0);
        }
    },


    /**
     * Removes special characters from a string and returns new string. Which chars are removed depends on type. 
     * Always excluded: ASCII <=31 and 127 and >=254 
     * type= empty, uri, filename:  ! " , < > ` ~ % ' ( ) ; [ ] { }
     * type = URI or all: # $ & = ? ^ | 
     * type = filename or all: / 
     * type=comment: diallow all above except following which are allowed: ! , % ' ( ) [ ] # $ & = ? /
     * backslash \ only allowed for uri
     * @param {any} strIn - original string
     * @param {any} typ - type (all, uri, filename, comment, "")  empty is set to all
     * @returns {string} modified input
     */
    RemoveSpecialChar: function(strIn, typ) {
        let result = "", txt="", n1=-1, temp=[];
        try {
            if (strIn == null || typeof strIn != 'string') result = "";
            if (typ== null || typeof typ != 'string') typ= "";
            if (typ.length==0 || typ=="newfield") typ="all";
            temp = strIn.split("");
            for (let i = 0; i < temp.length; i++) {
                n1 = temp[i].charCodeAt(0);
                txt = temp[i];
                if (n1 <= 31 || n1 == 127 || n1 >= 254) txt = "";
                else if (n1 == 34 || n1==59 || n1 == 60 || n1 == 62 || n1 == 123 || n1 == 125 || n1 == 96 || n1 == 126) {
                    //34= ", 59=;, 60= < , 62= >, 123= {, 125=}, 96= `, 126= ~
                    txt="";
                }
                else if (n1 == 33 || n1==44 || n1 == 37 || n1 == 39 || n1 == 40 || n1 == 41 || n1 == 91 || n1 == 93) {
                    //33= !, 44= comma, 37= %, 39= ', 40= (, 41=), 91= [, 93= ]
                    txt= typ=="comment" ? txt : "";
                }
                else if (n1==35 || n1==36 || n1 == 38 || n1 == 61 || n1 == 63 || n1 == 94) {
                    //35=# ,36=$, 38=&,61= =,63=?,94=^
                    //allowed for filename and comment
                    txt= typ == "uri" || typ=="all" ? "" : txt;
                }
                else if (n1 == 124) {
                    //124=|
                    txt= typ == "uri" || typ=="all" ? "" : txt;
                }
                else if (n1 == 47) {
                    // fwdSlash
                    txt= typ == "filename" || typ=="all" ? "" : txt;
                }
                else if (n1 == 92) {
                    //backslash
                    txt= typ=="uri" ? txt : "";
                }
                result += txt;
            }
        }
        catch (ex) {
            alert("RemoveSpecialChar:" + ex.message);
        }
        return result;
    },
  
  

    /**
     * Determines if string has special characters depending on type. 
     * Always excluded: ASCII <=31 and 127 and >=254 
     * type= empty, uri, filename:  ! " , < > ` ~ % ' ( ) ; [ ] { }
     * type = URI or all: # $ & = ? ^ | 
     * type = filename or all: / 
     * type=comment: diallow all above except following which are allowed: ! , % ' ( ) [ ] # $ & = ? /
     * backslash \ only allowed for uri
     * @param {any} strIn - original string
     * @param {any} typ - type (all, uri, filename, comment, "")  empty is set to all
     * @returns {boolean} whether has special character
     */
    HasSpecialChar: function(strIn, typ) {
      let result = "", txt="", n1=-1, temp=[];
      let hasSC=false;
      try {
          if (strIn == null || typeof strIn != 'string') result = "";
          if (typ== null || typeof typ != 'string') typ= "";
          if (typ.length==0 || typ=="newfield") typ="all";
          temp = strIn.split("");
          for (let i = 0; i < temp.length; i++) {
              n1 = temp[i].charCodeAt(0);
              txt = temp[i];
              if (n1 <= 31 || n1 == 127 || n1 >= 254) txt = "";
              else if (n1 == 34 || n1==59 || n1 == 60 || n1 == 62 || n1 == 123 || n1 == 125 || n1 == 96 || n1 == 126) {
                //34= ", 59=;, 60= < , 62= >, 123= {, 125=}, 96= `, 126= ~
                txt="";
              }
              else if (n1 == 33 || n1==44 || n1 == 37 || n1 == 39 || n1 == 40 || n1 == 41 || n1 == 91 || n1 == 93) {
                //33= !, 44= comma, 37= %, 39= ', 40= (, 41=), 91= [, 93= ]
                txt= typ=="comment" ? txt : "";
              }
              else if (n1==35 || n1==36 || n1 == 38 || n1 == 61 || n1 == 63 || n1 == 94) {
                //35=# ,36=$, 38=&,61= =,63=?,94=^
                //allowed for filename and comment
                txt= typ == "uri" || typ=="all" ? "" : txt;
              }
              else if (n1 == 124) {
                //124=|
                txt= typ == "uri" || typ=="all" ? "" : txt;
              }
              else if (n1 == 47) {
                // fwdSlash
                txt= typ == "filename" || typ=="all" ? "" : txt;
              }
              else if (n1 == 92) {
                //backslash
                txt= typ=="uri" ? txt : "";
              }
              result += txt;
          }
          hasSC= result==strIn ? false : true;
      }
      catch (ex) {
          alert("HasSpecialChar:" + ex.message);
      }
      return hasSC;
    },


    /**
     * Determines if string has numbers 0 1 2 3 4 5 6 7 8 9 
     * @param {any} strIn - original string
     * @returns {boolean} 
     */
    HasNumber: function(strIn) {
        let result=false;
        try {
            if (VR_Common.Contains(strIn,"0",false) 
            || VR_Common.Contains(strIn,"1",false) 
            || VR_Common.Contains(strIn,"2",false) 
            || VR_Common.Contains(strIn,"3",false) 
            || VR_Common.Contains(strIn,"4",false) 
            || VR_Common.Contains(strIn,"5",false) 
            || VR_Common.Contains(strIn,"6",false) 
            || VR_Common.Contains(strIn,"7",false) 
            || VR_Common.Contains(strIn,"8",false) 
            || VR_Common.Contains(strIn,"9",false) 
            ) result=true;
        }
        catch (ex) {
            alert("HasNumber:" + ex.message);
        }
        return result;
    },
  
    /**
     * Determines if string has letter in lower or upper case per type 
     * @param {any} strIn - original string
     * @param {any} typ - upper, lower
     * @returns {boolean} 
     */
    HasLetterCase: function(strIn, typ) {
        let result=false, temp=[], n1=-1;
        try {
            if (typeof typ!="string") throw("missing type");
            else if (typeof strIn!="string") throw("missing input string");
            else if (typ.length==0) throw("missing type");
            typ=typ.toLowerCase();
            if (typ!="upper" && typ!="lower") throw ("type must be either upper or lower");

            temp = strIn.split("");
            for (let i = 0; i < temp.length; i++) {
                n1 = temp[i].charCodeAt(0);
                if (typ=="upper" && n1>=65 && n1<=90) {
                    result=true;
                    break;
                }
                else if (typ=="lower" && n1>=97 && n1<=122) {
                    result=true;
                    break;
                }
            }
        }
        catch (ex) {
            alert("HasLetterCase:" + ex.message);
        }
        return result;
    },
  
  
    /**
     * Removes spaces from string
     * @param {string} strIn 
     * @param {string} replaceWith - optional char to replace space with 
     * @returns {string} new string or starts with notok: if error
     */
    RemoveSpaces(strIn, replaceWith) {
        let result="";
        try {
            if (replaceWith == null || typeof replaceWith != 'string') replaceWith="";
            if (strIn == null || typeof strIn != 'string') result = "";
            else if (replaceWith.length>0) {
                result=strIn.replace(/\s/g,replaceWith);
            }
            else result=strIn.replace(/\s/g,"");
        }
        catch (ex) {
            result = "notok:" + ex.message;
        }
        return result;
    },


    RemoveArrayItem(nIndex, arr) {
        let result = [], nlen=-1;
        try {
            if (nIndex == null || typeof nIndex != 'number') throw ("missing array index");
            if (arr == null || typeof arr != 'object' || !Array.isArray(arr)) throw ("missing array");
            if (nIndex >= arr.length) throw ("array index is larger than array size");
            if (nIndex < 0) throw ("array index is < 0");
            nlen = arr.length;
            if (nIndex == nlen - 1) {
                arr.pop();
                result = arr;
            }
            else {
                result = arr.slice(nIndex, nIndex+1);
            }
        }
        catch (ex) {
            result[0] = ex.message;
        }
        return result;
    },


    /**  StartsWith
    *determines if a string starts with another string
    *@param param - original string
    *@param strtofind - string item to find
    *@param iscasesens - boolean whether case sensitive
    *@return {boolean} true if find string is at beginning
    */
    StartsWith(param, strtofind, iscasesens) {
        var result=false;
        try {
            if (param==null || typeof(param)=='undefined') param="";
            if (strtofind==null || typeof(strtofind)=='undefined') strtofind="";
            if (iscasesens==null || typeof(iscasesens)=='undefined' || typeof(iscasesens)=='boolean') iscasesens=false;
            if (param=="" || strtofind=="") return result;
            if (!iscasesens) {
                param=param.toLowerCase();
                strtofind=strtofind.toLowerCase();
            }
            if (param.indexOf(strtofind)==0) result=true;
        }
        catch (ex) {
            result=false;
        }
        return result;
    },

    /**  EndsWith
    *determines if a string ends with another string
    *@param param - original string
    *@param strtofind - string item to find
    *@param iscasesens - boolean whether case sensitive
    *@return {boolean} true if find string is at beginning
    */
    EndsWith(param, strtofind, iscasesens) {
        var result=false, n1=-1;
        try {
            if (param==null || typeof(param)=='undefined') param="";
            if (strtofind==null || typeof(strtofind)=='undefined') strtofind="";
            if (iscasesens==null || typeof(iscasesens)=='undefined' || typeof(iscasesens)=='boolean') iscasesens=false;
            if (param=="" || strtofind=="") return result;
            if (!iscasesens) {
                param=param.toLowerCase();
                strtofind=strtofind.toLowerCase();
            }
            n1=param.length - strtofind.length;
            if (n1>=0) {
                if (param.lastIndexOf(strtofind)==n1) result=true;
            }
        }
        catch (ex) {
            result=false;
        }
        return result;
    },

    /**  Contains
    *determines if a string ends with another string
    *@param param - original string
    *@param strtofind - string item to find
    *@param iscasesens - boolean whether case sensitive
    *@return {boolean} true if find string is original string
    */
    Contains(param, strtofind, iscasesens) {
        var result = false;
        try {
            if (param == null || typeof (param) == 'undefined') param = "";
            if (strtofind == null || typeof (strtofind) == 'undefined') strtofind = "";
            if (iscasesens == null || typeof (iscasesens) == 'undefined' || typeof (iscasesens) == 'boolean') iscasesens = false;
            if (param == "" || strtofind == "") return result;
            if (!iscasesens) {
                param = param.toLowerCase();
                strtofind = strtofind.toLowerCase();
            }
            if (param.indexOf(strtofind) > -1) result = true;
        }
        catch (ex) {
            result = false;
        }
        return result;
    },

    GetEndPart(param, strtofind, iscasesens) {
        let result="", strIn="", n1=-1;
        try {
            strIn=param;
            if (typeof iscasesens!="boolean" || !iscasesens) {
                strIn=strIn.toLowerCase();
                strtofind=strtofind.toLowerCase();
            }
            n1= strIn.indexOf(strtofind);
            if (n1>=0) {
                result= param.substring(n1+ strtofind.length);
            }
        }
        catch (ex) {
            result= `notok:${ex.toString()}`;
        }
        return result;
    },

    GetFrontPart(param, strtofind, iscasesens) {
        let result="", strIn="", n1=-1;
        try {
            strIn=param;
            if (typeof iscasesens!="boolean" || !iscasesens) {
                strIn=strIn.toLowerCase();
                strtofind=strtofind.toLowerCase();
            }
            n1= strIn.indexOf(strtofind);
            if (n1>0) {
                result= param.substring(0,n1);
            }
        }
        catch (ex) {
            result= `notok:${ex.toString()}`;
        }
        return result;
    },

    /**
    *check if string is a number. Empty or null string value fails.
    *@param {string} typ: number type to check for (integer, real)
    *@param {string} param: initial string value to check
    *@param {boolean} removechars: boolean whether to remove special chars before checking ($ spaces * ' %). note: comma always removed
    *@return {boolean} result
    */
    IsNumber(typ,param, removechars) {
        var result=true;
        var i=0, nE=-1;
        var txt="", paramstr="";
        try {
            if (removechars==null || typeof(removechars)=='undefined' || typeof(removechars)!="boolean") removechars=false;
            if (param==null || typeof(param)=='undefined') paramstr="";
            else paramstr = param.toString();
            if (typ==null) typ="";
            paramstr=paramstr.replace(/,/g,''); 
            if (removechars) {
                paramstr=paramstr.replace(/$/g,''); 
                paramstr=paramstr.replace(/ /g,'');         
                paramstr=paramstr.replace(/\*/g,'');         
                paramstr=paramstr.replace(/'/g,'');         
                paramstr=paramstr.replace(/%/g,'');         
            }
            if (paramstr=="") {result=false;}
            else {
                paramstr=paramstr.toLowerCase();
                for (i=0; i<paramstr.length; i++) {
                    txt=paramstr.substring(i, i+1);
                    if (txt!="0" && txt!="1" && txt!="2" && txt!="3" && txt!="4" && txt!="5" && txt!="6" 
                    && txt!="7" && txt!="8" && txt!="9") {
                        if (txt=="."){
                            if (typ=="integer"){
                                result=false;
                                break;
                            }
                        }
                        else if (txt=="-") {
                            if (nE>0 && i==nE+1) {
                                //ok
                            }
                            else if (i>0) {
                                result=false;
                                break;
                            }
                        }
                        else if (txt=="+") {
                            if (nE>0 && i==nE+1) {
                                //ok
                            }
                            else if (i>0) {
                                result=false;
                                break;
                            }
                        }
                        else if (txt=="e") {
                            if (nE<0 && i>0) nE=i;
                            else {
                                result=false;
                                break;
                            }
                        }
                        else {
                            result=false;
                            break;
                        }
                    }
                }
            }
            if (result) {
                if (typ=="integer" && isNaN(parseInt(paramstr))) result=false;
                else if (typ=="real" && isNaN(parseFloat(paramstr))) result=false;
            }
        }
        catch(ex) {
            result=false;
        }
        return result;
    },

    IsPosInt(param) {
      let result=false, n1=-1;
      try {
        if (this.IsNumber("integer", param, false)) {
          n1=parseInt(param);
          if (n1>0) result=true;
        }
      }
      catch (e) {alert(`IsPosInt:${e}`);}
      return result;
    },

    IsPosZeroInt(param) {
      let result=false, n1=-1;
      try {
        if (this.IsNumber("integer", param, false)) {
          n1=parseInt(param);
          if (n1>=0) result=true;
        }
      }
      catch (e) {alert(`IsPosZeroInt:${e}`);}
      return result;
    },

    IsInt(param) {
      return this.IsNumber("integer", param, false);
    },

    IsReal(param) {
      return this.IsNumber("real", param, false);
    },

    IsPosReal(param) {
      let result=false, n1=-1;
      try {
        if (this.IsNumber("real", param, false)) {
          n1=parseFloat(param);
          if (n1>0) result=true;
        }
      }
      catch (e) {alert(`IsPosReal:${e}`);}
      return result;
    },

    IsPosZeroReal(param) {
      let result=false, n1=-1;
      try {
        if (this.IsNumber("real", param, false)) {
          n1=parseFloat(param);
          if (n1>=0) result=true;
        }
      }
      catch (e) {alert(`IsPosZeroReal:${e}`);}
      return result;
    },

    /**
     * Determines if a string contains another string within it with optional wildcard characters (*)
     * @param strval - string to be tested
     * @param testval - string fragment to find in other string. This can have optional wildcards at front and back as *term*
     * @param {Boolean} iscasesens - boolean whether case sensitive
     * @return {Boolean} true if term found within string
     */
    IsTermMatch(strval, testval, iscasesens) {
        let flag=false, flag1=false, result=false;
        try {
            if (strval==null || typeof(strval)=='undefined') strval="";
            if (testval==null || typeof(testval)=='undefined') testval="";
            if (iscasesens==null || typeof(iscasesens)!='boolean') iscasesens=false;
            if (strval=="" || testval=="") return result;
            if (!iscasesens){
                strval=strval.toLowerCase();
                testval=testval.toLowerCase();
            }
            if (VR_Common.StartsWith(testval, "*", false)) {
                flag=true;
                testval=testval.substring(1);
            }
            if (testval == "") flag1 = true;
            else if (VR_Common.EndsWith(testval, "*", false)) {
                flag1=true;
                testval=testval.substring(0, testval.length-1);
            }
            if (testval=="") result=true;
            else if (flag && flag1) {
                if (VR_Common.Contains(strval, testval, true)) result=true;
            }
            else if (flag && !flag1) {
                if (VR_Common.EndsWith(strval, testval, false)) result=true;
            }
            else if (!flag && flag1) {
                if (VR_Common.StartsWith(strval, testval, false)) result=true;
            }
            else if (strval==testval) result=true;
        }
        catch(ex) {
            result=false;
        }
        return result;
    },


    /**
     * Rounds a number to decimal places
     * @param value - number to be rounded
     * @param decimals - integer number of decimal places (0-n)
     */
    RoundNumber(value, decimals) {
        let result=0;
        try {
            result= Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        }
        catch(ex) {
            result= 0;
        }  
        return result;
    },

    SetDecDigString(value, nDec) {
        let result="", dec="", ndig=0;
        try {
            if (typeof value!="number") throw("number not provided");
            if (typeof nDec!="number") throw("number digits not provided");
            if (nDec<=0) {
                result= Math.floor(value).toString();
                if (this.Contains(result,".", false)) result=result.substring(0, result.indexOf("."));
            }
            else {
                result=value.toString();
                if (this.Contains(result,".", false)) {
                    dec=result.substring(result.indexOf(".")+1);
                    result=result.substring(0, result.indexOf("."));
                }
                if (dec.length>nDec) {
                    dec=dec.substring(0, nDec);
                    result += `.${dec}`;
                }
                else {
                    ndig=nDec- dec.length;
                    for (let i=0; i< ndig; i++) {
                        dec += "0";
                    }
                    result += `.${dec}`;
                }
            }
            if (result.length==0) result="0";
        }
        catch (e) {result="0"; }
        return result;
    },

    /**
     * Uses the international formatting for currency in US English to make a number formatted for currency
     * @param numvalue - incoming real number 
     * @returns - formatted string or starts with notok; if there is an error
     */
    AssignCurrencyFormat(numvalue) {
        var result="", txt="", txt1="";
        var numberFormat=null;
        try {
            if (typeof(numvalue)!="number") throw({message:"input is not a number"});
            numberFormat=new Intl.NumberFormat('us-EN',{style:'currency',currency:'USA'});
            try {
                txt=numberFormat.format(numvalue);
            }
            catch (e){txt="";}
            if (txt.toLowerCase().indexOf("usa")==0) txt=txt.substring(3);
            else if (txt.toLowerCase().indexOf("usa")==1) {
                txt1=txt.substring(0,1);
                txt=txt.substring(4);
                txt = txt1 + txt;
            }
            result=txt;
        }
        catch(ex) {
            result="notok:" + ex.toString();
        }  
        return result;
    },


    /**
    *Gets number from string, looks for end scaling character (k=1000, m=10^6, g=10^9, t=10^12, p=10^15),
    * or pb= petabyte, tb = terabyte, gb=gigabyte, mb=megabyte, kb=kilobyte, b=byte
    *@param {string} typ: number type to check for (integer, real)
    *@param {string} param: initial string value to check
    *@param {boolean} removechars: boolean whether to remove special chars before checking ($ , spaces)
    *@return {string} result of number string or empty if not a number
    */
    GetNumber(typ, param, removechars) {
        return this.GetNumberFromStr(typ, param, removechars, false);
    },


    /**
    *Gets number from string and removes non-string chars. 
    *Looks for end scaling character (k=1000, m=10^6, g=10^9, t=10^12, p=10^15),
    * or pb= petabyte, tb = terabyte, gb=gigabyte, mb=megabyte, kb=kilobyte, b=byte
    *@param {string} typ: number type to check for (integer, real)
    *@param {string} param: initial string value to check
    *@return {string} result of number string or empty if not a number
    */
    GetNumberWithClean(typ, param) {
        return this.GetNumberFromStr(typ, param, true, false);
    },



    /**
    *Gets number from string, looks for end scaling character(k=1000, m=10^6, g=10^9, t=10^12, p=10^15),
    * or pb= petabyte, tb = terabyte, gb=gigabyte, mb=megabyte, kb=kilobyte, b=byte
    *@param {string} typ: number type to check for (integer, real)
    *@param {string} param: initial string value to check
    *@param {boolean} removechars: boolean whether to remove special chars before checking ($ , spaces)
    *@param {boolean} intNoDecimal: boolean whether to enforce rule integers cannot have decimal point when this is true
    *@return {string} result of number string or empty if not a number
    */
    GetNumberFromStr(typ, param, removechars, intNoDecimal) {
        var result = true;
        var i = 0, scale = 1, n1 = 0;
        var txt = "", paramstr = "";
        const kb = 1024, mb = 1048576, gb = 1073741824;
        try {
            const tb = kb * gb;
            const pb = kb * tb;
            if (removechars == null || typeof (removechars) == 'undefined' || typeof (removechars) != "boolean") removechars = false;
            if (param == null || typeof (param) == 'undefined') paramstr = "";
            else paramstr = param.toString();
            if (typ == null || typeof (typ) == 'undefined') typ = "";
            paramstr = paramstr.replace(/ /g, '').toLowerCase();
            if (paramstr == "") throw ("missing string");

            n1 = 0;
            if (VR_Common.EndsWith(paramstr, "pb", false)) { scale = pb; n1 = 2; }
            else if (VR_Common.EndsWith(paramstr, "tb", false)) { scale = tb; n1 = 2; }
            else if (VR_Common.EndsWith(paramstr, "gb", false)) { scale = gb; n1 = 2; }
            else if (VR_Common.EndsWith(paramstr, "mb", false)) { scale = mb; n1 = 2; }
            else if (VR_Common.EndsWith(paramstr, "kb", false)) { scale = kb; n1 = 2; }
            else if (VR_Common.EndsWith(paramstr, "b", false)) { scale = 1; n1 = 1; }
            else if (VR_Common.EndsWith(paramstr, "p", false)) { scale = Math.pow(10, 15); n1 = 1;}
            else if (VR_Common.EndsWith(paramstr, "t", false)) { scale = Math.pow(10, 12); n1 = 1; }
            else if (VR_Common.EndsWith(paramstr, "g", false)) { scale = Math.pow(10, 9); n1 = 1; }
            else if (VR_Common.EndsWith(paramstr, "m", false)) { scale = Math.pow(10, 6); n1 = 1; }
            else if (VR_Common.EndsWith(paramstr, "k", false)) { scale = Math.pow(10, 3); n1 = 1; }
            if (n1 > 0) paramstr = paramstr.substring(0, paramstr.length - n1).trim();

            if (removechars) {
                paramstr = paramstr.replace(/$/g, '');
                paramstr = paramstr.replace(/,/g, '');
                paramstr = paramstr.replace(/\*/g, '');
                paramstr = paramstr.replace(/'/g, '');
                paramstr = paramstr.replace(/%/g, '');
            }
            if (paramstr == "") throw ("missing string");

            for (i = 0; i < paramstr.length; i++) {
                txt = paramstr.substring(i, i+1);
                if (txt != "0" && txt != "1" && txt != "2" && txt != "3" && txt != "4" && txt != "5" && txt != "6"
                    && txt != "7" && txt != "8" && txt != "9") {
                    if (txt == ".") {
                        if (intNoDecimal && typ == "integer") {
                            result = false;
                            break;
                        }
                    }
                    else if (i > 0 || (i == 0 && txt != "-")) {
                        //allow negative integers
                        result = false;
                        break;
                    }
                }
            }
            if (result) {
                n1= typ == "integer" ? parseInt(paramstr) : parseFloat(paramstr);
                if (isNaN(n1)) result = false;
                else {
                    n1 *= scale;
                    paramstr = n1.toString();
                    return n1;
                }
            }
            if (!result) paramstr = "";
        }
        catch (ex) {
            result = false;
            paramstr = "";
        }
        return paramstr;
    },


    GetSizeStr(nBytes) {
      let sizeStr="";
      if (nBytes>= Math.pow(10,12)) {
        sizeStr = (Math.round(nBytes/Math.pow(10,12))).toString() + "TB";
      }
      else if (nBytes>= Math.pow(10,9)) {
        sizeStr = (Math.round(nBytes/Math.pow(10,9))).toString() + "GB";
      }
      else if (nBytes>= Math.pow(10,6)) {
        sizeStr = (Math.round(nBytes/Math.pow(10,6))).toString() + "MB";
      }
      else if (nBytes>= Math.pow(10,3)) {
        sizeStr = (Math.round(nBytes/Math.pow(10,3))).toString() + "KB";
      }
      else {
        sizeStr = (Math.round(nBytes)).toString() + "B";
      }
      return sizeStr;
    },



    /**
     * Determines the scale for a number and returns string of it rounded with scale unit (Thousand, Million, Billion, Trillion) 
     * or letter if that option set (K, M, B, T), or if using metric then (K, M, G, T, P)
     * @param valuein - numeric value in
     * @param decimals - number decimal places
     * @param useletter - boolean whether to use letter for scale
     * @param usemetric - boolean whether to use metric letters for scale
     */
    GetNumberScale(valuein, decimals, useletter, usemetric) {
        var scale="", result="", n1=-1, n2=-1;
        var isneg=false;
        try {
            if (valuein==null || typeof(valuein)=='undefined') valuein=-1;
            else if (typeof(valuein)=="string") {
                if (valuein=="") valuein=-1;
                else if (VR_Common.IsNumber("integer", valuein, false)) valuein=parseInt(valuein);
                else if (VR_Common.IsNumber("real", valuein, false)) valuein=parseFloat(valuein);
                else valuein=-1;
            }
            else if (typeof(valuein)!="number")valuein=-1;
            if (decimals==null || typeof(decimals)=='undefined' || typeof(decimals)!="number") decimals=0;
            if (useletter==null || typeof(useletter)!='boolean') useletter=true;
            if (usemetric==null || typeof(usemetric)!='boolean') usemetric=true;
            n1=valuein;
            if (n1<0) {
                isneg=true;
                n1 *= -1;
            }
            if (n1>=1000 && n1<1000000) {
                n1 /= 1000;
                scale="k";
            }
            else if (n1>=1000000 && n1<1000000000) {
                n1 /= 1000000;
                scale="m";
            }
            else if (n1>=1000000000 && n1<1000000000000) {
                n1 /= 1000000000;
                scale="b";
            }
            else if (n1>=1000000000000 && n1<1000000000000000) {
                n1 /= 1000000000000;
                scale="t";
            }
            else if (n1>=1000000000000000) {
                n1 /= 1000000000000000;
                scale="p";
            }
            n2= VR_Common.RoundNumber(n1, decimals);
            if (scale=="k") {
                if (usemetric) {
                    scale="K";
                }
                else {
                    if (useletter) scale="K"; else scale="Thousand";
                }
            }
            else if (scale=="m") {
                if (usemetric) {
                    scale="M";
                }
                else {
                    if (useletter) scale="M"; else scale="Million";
                }
            }
            else if (scale=="b") {
                if (usemetric) {
                    scale="G";
                }
                else {
                    if (useletter) scale="B"; else scale="Billion";
                }
            }
            else if (scale=="t") {
                if (usemetric) {
                    scale="T";
                }
                else {
                    if (useletter) scale="T"; else scale="Trillion";
                }
            }
            else if (scale=="p") {
                if (usemetric) {
                    scale="P";
                }
                else {
                    if (useletter) scale = "P"; else scale ="Quadrillion";
                }
            }
            else if (scale == "") {
                if (usemetric) {
                    scale = "B";
                }
            }
            if (isneg){
                n2 *= -1;
            }
            result = n2.toString();
            if (scale!="") result += scale;
        }
        catch(ex) {
            result="";
        }
        return result;
    },



    /**
     * Gets the parameters from a URL string after doing URI decode. The original string is found from location.search
     * @returns {Array} - rank 2 array with index 0 the instance, and index 1 having entry 0 = parm name and entry 1=param value
     */
    GetURLParams() {
        var txt="", txt1="";
        var i=0, n1=-1;
        var results=[], temp=[];
        try {
            txt = location.search;
            if (txt==null || typeof(txt)=='undefined') txt="";
            if (txt.indexOf("?")==0) txt=txt.substring(1);
            txt=decodeURIComponent(txt);
            temp= txt.split("&");
            for (i=0; i< temp.length; i++) {
                txt= temp[i].trim();
                txt1="";
                if (txt.indexOf("=")>-1) {
                    txt1=txt.substring(txt.indexOf("=")+1);
                    txt=txt.substring(0, txt.indexOf("="));
                }
                txt= txt.trim();
                txt1= txt1.trim();
                if (txt.length>0) {
                    n1= results.length;
                    results[n1]=[];
                    results[n1][0]=txt;
                    results[n1][1]=txt1;
                }
            }
        }
        catch(ex) {
            alert("error: GetURLParams- " + ex.message);
        }  
        temp=null;
        return results;
    },


    /**
    * Determines if a date is current relative to today's date with or without an offset datetime. Calculation is: true if inputdate <= (today+offset)
    * @param datestr incoming date string in ISO format to compare
    * @param offset integer offset amount (added so can be negative) added to today's datetime. Use positive to allow input date to exceed actual date and be considered current. 
    * @param offtype unit for offset (year, month, day, hour, minute, second)
    * @return {boolean} whether current or not
    */
    IsDateCurrent(datestr, offset, offtype) {
        let result = true, today="", dt="", tm="", txt="";
        let nyr=0, nmo=0, nday=0, nhr=0, nmin=0, nsec=0, nyr1=0, nmo1=0, nday1=0, nhr1=0, nmin1=0, nsec1=0;
        try {
            if (typeof (datestr) == 'undefined') datestr = "";
            if (typeof (offtype) == 'undefined') offtype = "";
            if (typeof (offset) == 'undefined') offset = 0;
            if (datestr == "") throw ("missing date string");
            datestr = datestr.toLowerCase();
            offtype = offtype.toLowerCase();
            today = VR_Common.CreateNewISODate("", offset, offtype, false);
            if (VR_Common.StartsWith(today, "error:", false)) throw (today);
            today = today.toLowerCase();
            if (VR_Common.Contains(datestr, " ", false) && datestr.indexOf(" ") >= 8 && datestr.length > datestr.indexOf(" ")) {
                datestr = datestr.replace(" ", "t");
            }
            else if (VR_Common.Contains(datestr,"_",false) && datestr.indexOf("_") >= 8 && datestr.length > datestr.indexOf("_")) {
                datestr = datestr.replace("_", "t");
            }
            if (!VR_Common.Contains(datestr,"t",false)) datestr += "t235959"; //add time for end of day

            dt = datestr.substring(0, datestr.indexOf("t"));
            tm = datestr.substring(datestr.indexOf("t") + 1);
            if (dt.length != 8) throw ("incorrect date string: " + dt);
            txt = dt.substring(0, 4);
            nyr = parseInt(txt);
            txt = dt.substring(4, 2);
            nmo = parseInt(txt);
            txt = dt.substring(6, 2);
            nday = parseInt(txt);
            if (tm.length >= 2) {
                txt = tm.substring(0, 2);
                nhr = parseInt(txt);
            }
            if (tm.length >= 4) {
                txt = tm.substring(2, 2);
                nmin = parseInt(txt);
            }
            if (tm.length >= 6) {
                txt = tm.substring(4, 2);
                nsec = parseInt(txt);
            }

            dt = today.substring(0, today.indexOf("t"));
            tm = today.substring(today.indexOf("t") + 1);
            if (dt.length != 8) throw ("incorrect today string: " + dt);
            txt = dt.substring(0, 4);
            nyr1 = parseInt(txt);
            txt = dt.substring(4, 2);
            nmo1 = parseInt(txt);
            txt = dt.substring(6, 2);
            nday1 = parseInt(txt);
            if (tm.length >= 2) {
                txt = tm.substring(0, 2);
                nhr1 = parseInt(txt);
            }
            if (tm.length >= 4) {
                txt = tm.substring(2, 2);
                nmin1 = parseInt(txt);
            }
            if (tm.length >= 6) {
                txt = tm.substring(4, 2);
                nsec1 = parseInt(txt);
            }

            if (nyr > nyr1) result = false;
            else if (nyr1 > nyr) result = true;
            else if (nmo > nmo1) result = false;
            else if (nmo1 > nmo) result = true;
            else if (nday > nday1) result = false;
            else if (nday1 > nday) result = true;
            else if (nhr > nhr1) result = false;
            else if (nhr1 > nhr) result = true;
            else if (nmin > nmin1) result = false;
            else if (nmin1 > nmin) result = true;
            else if (nsec > nsec1) result = false;
            else if (nsec1 > nsec) result = true;
        }
        catch (ex) {
            result = false;
        }
        return result;
    },


    /**
    * Computes number of days between today's date and date submitted
    * @param datestr incoming date string in ISO format to compare
    * @param offset integer offset amount (added so can be negative).  Added to today's datetime.
    * @param offtype unit for offset (year, month, day, hour, minute, second)
    * @return integer days = today+offset - datestr
    */
    DateDayDifference(datestr, offset, offtype) {
        let today = "", dt = "", txt = "";
        let nyr = 0, nmo = 0, nday = 0, nyr1 = 0, nmo1 = 0, nday1 = 0;
        let p = 0, pval = 0, pval1 = 0, nDelta=0;
        try {
            if (typeof (datestr) == 'undefined') datestr = "";
            if (typeof (offtype) == 'undefined') offtype = "";
            if (typeof (offset) == 'undefined') offset = 0;
            if (datestr == "") throw ("missing date string");
            datestr = datestr.toLowerCase();
            offtype = offtype.toLowerCase();

            today = VR_Common.CreateNewISODate("", offset, offtype, false);
            if (VR_Common.StartsWith(today, "error:", false)) throw (today);
            today = today.toLowerCase();

            if (VR_Common.Contains(datestr, " ", false) && datestr.indexOf(" ") >= 8 && datestr.length > datestr.indexOf(" ")) {
                datestr = datestr.replace(" ", "t");
            }
            else if (VR_Common.Contains(datestr, "_", false) && datestr.indexOf("_") >= 8 && datestr.length > datestr.indexOf("_")) {
                datestr = datestr.replace("_", "t");
            }
            if (!VR_Common.Contains(datestr, "t", false)) datestr += "t235959"; //add time for end of day

            dt = datestr.substring(0, datestr.indexOf("t"));
            if (dt.length != 8) throw ("incorrect date string: " + dt);
            txt = dt.substring(0, 4);
            nyr1 = parseInt(txt);
            txt = dt.substring(4, 2);
            nmo1 = parseInt(txt);
            txt = dt.substring(6, 2);
            nday1 = parseInt(txt);

            dt = today.substring(0, today.indexOf("t"));
            if (dt.length != 8) throw ("incorrect today string: " + dt);
            txt = dt.substring(0, 4);
            nyr = parseInt(txt);
            txt = dt.substring(4, 2);
            nmo = parseInt(txt);
            txt = dt.substring(6, 2);
            nday = parseInt(txt);

            pval = 0;
            for (let i = 1; i < nmo; i++) {
                if (i == 1) p = 31;
                else if (i == 2) p = 28.25;  
                else if (i == 3) p = 31;
                else if (i == 4) p = 30;
                else if (i == 5) p = 31;
                else if (i == 6) p = 30;
                else if (i == 7) p = 31;
                else if (i == 8) p = 31;
                else if (i == 9) p = 30;
                else if (i == 10) p = 31;
                else if (i == 11) p = 30;
                else if (i == 12) p = 31;
                pval += p;
            }
            pval += nday;  //number of days into year for today

            pval1 = 0;
            for (let i = 1; i < nmo1; i++) {
                if (i == 1) p = 31;
                else if (i == 2) p = 28.25;  
                else if (i == 3) p = 31;
                else if (i == 4) p = 30;
                else if (i == 5) p = 31;
                else if (i == 6) p = 30;
                else if (i == 7) p = 31;
                else if (i == 8) p = 31;
                else if (i == 9) p = 30;
                else if (i == 10) p = 31;
                else if (i == 11) p = 30;
                else if (i == 12) p = 31;
                pval1 += p;
            }
            pval1 += nday1; //number of days into year for date string

            nDelta = Math.floor((365.25 * (nyr - nyr1)) + (pval - pval1));
        }
        catch (ex) {
            nDelta = -999999;
        }
        return nDelta;
    },


    ISODateFormat(dateIn, dateDelim, incTime, timeSep, timeDelim, incTimeSec) {
        let result="", dt="", tim="", txt="";
        try {
            if (!dateIn || typeof dateIn!='string' || dateIn.length==0) return result;
            if (!dateDelim || typeof dateDelim!='string') dateDelim="";
            if (!timeSep || typeof timeSep!='string') timeSep="";
            if (!timeDelim || typeof timeDelim!='string') timeDelim="";
            if (!incTime || typeof incTime!='boolean') incTime=false;
            if (!incTimeSec || typeof incTimeSec!='boolean') incTimeSec=false;
            dt=dateIn.toLowerCase();
            if (this.Contains(dt,"t",false)) {
                tim=dt.substring(dt.indexOf("t")+1);
                dt=dt.substring(0, dt.indexOf("t"));
            }
            else if (this.Contains(dt," ",false)) {
                tim=dt.substring(dt.indexOf(" ")+1);
                dt=dt.substring(0, dt.indexOf(" "));
            }
            if (this.Contains(dt,"-",false)) dt=dt.replace(/-/g,"");
            else if (this.Contains(dt,"/",false)) dt=dt.replace(/\//g,"");
            else if (this.Contains(dt,".",false)) dt=dt.replace(/./g,"");
            else if (this.Contains(dt,"_",false)) dt=dt.replace(/_/g,"");
            if (dt.length<8) dt.padEnd(8,"0");
            if (dateDelim.length>0) {
                txt=dt.substring(4);
                dt=dt.substring(0,4) + dateDelim + txt.substring(0,2);
                txt=txt.substring(2);
                dt += dateDelim + txt;
            }
            if (incTime && tim.length>0) {
                if (this.Contains(tim,".",false)) tim=this.GetFrontPart(tim,".",false);
                if (this.Contains(tim,":",false)) tim=tim.replace(/:/g,"");
                else if (this.Contains(tim,"-",false)) tim=tim.replace(/-/g,"");
                if (tim.length>4 && !incTimeSec) tim=tim.substring(0,4);
                if (tim.length<4) tim=tim.padEnd(4,"0");
                if (timeDelim.length>0) {
                    txt=tim.substring(2);
                    tim=tim.substring(0,2) + timeDelim + txt.substring(0,2);
                    if (txt.length>2) {
                        txt=txt.substring(2);
                        tim += timeDelim + txt;
                    }
                }                                    
            }
            result = incTime ? dt += timeSep + tim : dt;
        }
        catch(ex) {
            result="error:" + ex;             
        }
        return result;
    },



    /**
     * Creates a new ISO8601 date using current date time with an optional offset
     * @param delim - optional string delimiter to use in date portion , such as - or / with default no character. max length is 5 chars.
     * @param noffset - integer offset to current value determined by offsettyp
     * @param offsettyp - date time portion to add offset to (year, month, day, hour, minute, second)
     * @param incMS - boolean whether to include msec
     * @returns {String} - ISO 8601 datetime value
     */
    CreateNewISODate(delim, noffset, offsettyp, incMS) {
        let result="", txt="";
        let nhr=0, nmin=0, nsec=0, nms=0, nyr=0, nmo=0, ndy=0;
        let datobj={};
        try {
            if (!delim || typeof(delim)!='string') delim="";
            if (!offsettyp || typeof(offsettyp)!='string') offsettyp="";
            if (!noffset || typeof (noffset) != 'number') noffset = 0;
            if (incMS == null || !typeof (incMS) == "boolean") incMS = false;
            if (delim.length>5) delim="";
            offsettyp= offsettyp.toLowerCase().trim();
            if (!VR_Common.IsNumber("integer", noffset, false)) noffset=0;
            datobj=new Date();
            nyr= datobj.getFullYear();
            nmo=datobj.getMonth() + 1;
            ndy= datobj.getDate();
            nhr= datobj.getHours();
            nmin= datobj.getMinutes();
            nsec = datobj.getSeconds();
            nms=datobj.getMilliseconds();
            if (offsettyp!="" && noffset!=0) {
                if (offsettyp=="year") nyr += noffset;
                else if (offsettyp=="month") nmo += noffset;
                else if (offsettyp=="day") ndy += noffset;
                else if (offsettyp=="hour") nhr += noffset;
                else if (offsettyp=="minute") nmin += noffset;
                else if (offsettyp=="second") nsec += noffset;
                if (nsec>59) {
                    nmin++;
                    nsec=0;
                }
                else if (nsec<0) {
                    nmin--;
                    nsec=59;
                }
                if (nmin>59) {
                    nhr++;
                    nmin=0;
                }
                else if (nmin<0) {
                    nhr--;
                    nmin=59;
                }
                if (nhr>23) {
                    ndy++;
                    nhr=0;
                }
                else if (nhr<0) {
                    ndy--;
                    nhr=23;
                }
                if ((nmo==1 || nmo==3 || nmo==5 || nmo==7 || nmo==8 || nmo==10 || nmo==12) && ndy>31) {
                    nmo++;
                    ndy=1;
                }
                else if ((nmo==4 || nmo==6 || nmo==9 || nmo==11) && ndy>30) {
                    nmo++;
                    ndy=1;
                }
                else if (nmo==2 && ndy>28) {
                    nmo++;
                    ndy=1;
                }
                if (nmo>12) {
                    nyr++;
                    nmo=1;
                }
                else if (nmo<1) {
                    nyr--;
                    nmo=12;
                }
                if (nyr<100) nyr += 2000;        	
            }
            result = nyr.toString() + delim;
            txt= nmo.toString();
            if (txt.length==1) txt="0" + txt;
            result += txt + delim;
            txt= ndy.toString();
            if (txt.length==1) txt="0" + txt;
            result += txt;
            result += "T";
            txt= nhr.toString();
            if (txt.length==1) txt="0" + txt;
            result += txt;
            txt= nmin.toString();
            if (txt.length==1) txt="0" + txt;
            result += txt;
            txt= nsec.toString();
            if (txt.length==1) txt="0" + txt;
            result += txt;
            if (incMS) {
                result += "." + nms.toString();
            }
        }
        catch(ex) {result= "error:" + ex;}
        datobj=null;
        return result;
    },




    /**
    * Converts a datetime into ISO8601 format based on specified format. If no format is supplied, this method will try to 
    * assign a format input based on detecting values in the date portion as yyyymmdd, yyyyddmm, ddmmyyyy, mmddyyyy. This requires 
    * the day portion to have a value greater than 12 to be sure which 2 digit portion is for days.
    * The time portion is detected by looking for a break space or the ISO 't'. This will be maintained and added to the final date string if found.
    * @param datein incoming date string not in ISO8601. delimiters are detected and used if one of ( - / _ . )
    * @param formatin mmddyy, mmdyy, mdyy, mmddyyyy, mmdyyyy, mdyyyy, ddmmyy, ddmyy, dmyy, ddmmyyyy, ddmyyyy, dmyyyy,
    * yymmdd, yymmd, yymd, yyyymmdd, yyyymmd, yyyymd, yyyyddd, yyyyMMMdd, ddMMMyyyy. MMM = 3 letter month title like 'JAN'. 
    * If none specified then assumed to be mmddyyyy
    * @return result as yyyymmdd  or starts with 'notok:' if there is an error
    */
    ConvertDateToIso(datein, formatin) {
        let txt = "", txt1 = "", txt2 = "", txt3 = "", yr = "", mo = "", dy = "", result = "", delimin = "", timestr = "", monthnameused = "";
        let i=0, n1 = -1, n2 = -1, nyr = 0, nmo = -1, ndy = -1;
        let flag = false, flag1 = false;
        let temp=[], daysPerMonth=[];
        let hashmonthnames=[], hashmonthlongnames=[];
        try {
            daysPerMonth[0]=31;
            daysPerMonth[1] = 28;
            daysPerMonth[2] = 31;
            daysPerMonth[3] = 30;
            daysPerMonth[4] = 31;
            daysPerMonth[5] = 30;
            daysPerMonth[6] = 31;
            daysPerMonth[7] = 31;
            daysPerMonth[8] = 30;
            daysPerMonth[9] = 31;
            daysPerMonth[10] = 30;
            daysPerMonth[11] = 31;

            hashmonthnames["jan"]= "01";
            hashmonthnames["feb"] = "02";
            hashmonthnames["mar"] = "03";
            hashmonthnames["apr"] = "04";
            hashmonthnames["may"] = "05";
            hashmonthnames["jun"] = "06";
            hashmonthnames["jul"] = "07";
            hashmonthnames["aug"] = "08";
            hashmonthnames["sep"] = "09";
            hashmonthnames["oct"] = "10";
            hashmonthnames["nov"] = "11";
            hashmonthnames["dec"] = "12";

            hashmonthlongnames["january"]= "01";
            hashmonthlongnames["february"] = "02";
            hashmonthlongnames["march"] = "03";
            hashmonthlongnames["april"] = "04";
            hashmonthlongnames["may"] = "05";
            hashmonthlongnames["june"] = "06";
            hashmonthlongnames["july"] = "07";
            hashmonthlongnames["august"] = "08";
            hashmonthlongnames["september"] = "09";
            hashmonthlongnames["october"] = "10";
            hashmonthlongnames["november"] = "11";
            hashmonthlongnames["december"] = "12";

            datein = datein.toLowerCase().trim();
            if (VR_Common.Contains(datein,"t",false)) {
                txt = datein;
                if (VR_Common.Contains(txt, "august", false)) {
                    n1 = txt.indexOf("august");
                    txt = txt.substring(n1 + 6);
                }
                else if (VR_Common.Contains(txt, "oct", false)) {
                    n1 = txt.indexOf("oct");
                    txt = txt.substring(n1 + 3);
                }
                else if (VR_Common.Contains(txt, "sept", false)) {
                    n1 = txt.indexOf("sept");
                    txt = txt.substring(n1 + 4);
                }
                if (VR_Common.Contains(txt, "t", false)) n2 = txt.indexOf("t");
            }
            if (VR_Common.Contains(datein, "t", false) && (n1 < 0 || n2 > n1)) {
                timestr = datein.substring(n2 + 1);
                datein = datein.substring(0, n2);
            }

            flag = false;
            for (txt3 in hashmonthnames) {
                if (VR_Common.Contains(datein, txt3, false)) {
                    flag = true;
                    monthnameused = txt3;
                    break;
                }
            }

            flag1 = false;
            for (txt3 in hashmonthlongnames) {
                if (VR_Common.Contains(datein, txt3, false)) {
                    flag1 = true;
                    flag = false;
                    monthnameused = txt3;
                    break;
                }
            }

            if (!flag && !flag1 && VR_Common.Contains(datein," ")) {
                timestr = datein.substring(datein.indexOf(" ") + 1);
                datein = datein.substring(0, datein.indexOf(" "));
            }

            formatin = formatin.toLowerCase().trim();
            if (VR_Common.Contains(formatin,"-")) formatin = formatin.replace(/-/g, "");
            if (VR_Common.Contains(formatin,"/")) formatin = formatin.replace(/\//g, "");
            if (VR_Common.Contains(formatin,"_")) formatin = formatin.replace(/_/g, "");
            if (VR_Common.Contains(formatin,".")) formatin = formatin.replace(/./g, "");
            if (VR_Common.Contains(datein,"-")) delimin = "-";
            else if (VR_Common.Contains(datein,"/")) delimin = "/";
            else if (VR_Common.Contains(datein,"_")) delimin = "_";
            else if (VR_Common.Contains(datein,".")) delimin = ".";

            //if no format specified try to determine it
            if (formatin=="" && delimin!="") {
                if (datein.indexOf(delimin) == 4) {
                    txt1 = datein.substring(datein.indexOf(delimin) + delimin.length);
                    if (txt1.indexOf(delimin) == 3) formatin = "yyyymmmdd";
                    else formatin = "yyyymmdd";
                }
                else if (datein.indexOf(delimin) == 2) {
                    txt1 = datein.substring(datein.indexOf(delimin) + delimin.length);
                    if (txt1.indexOf(delimin) == 3) formatin = "ddmmmyyyy";
                    else if (txt1.indexOf(delimin) == 2) formatin = "mmddyyyy";
                    else if (txt1.indexOf(delimin) == 4) formatin = "mmyyyydd";
                }
            }
            txt = datein.trim();
            //try to determine formatin if not specified
            if (formatin=="") {
                if (delimin!="") {
                    if (txt.indexOf(delimin) == 4) {
                        txt2 = txt.substring(4);
                        if (txt2.indexOf(delimin) == 2) {
                            txt3 = txt2.substring(0, 2);
                            if (VR_Common.IsNumber("integer", txt3, false) && (parseInt(txt3) > 12)) {
                                formatin = "yyyyddmm";
                            }
                            else if (txt2.length > 3) {
                                txt3 = txt2.substring(3);
                                if (VR_Common.IsNumber("integer", txt3, false) && (parseInt(txt3) > 12)) {
                                    formatin = "yyyymmdd";
                                }
                            }
                        }
                    }
                    else if (txt.lastIndexOf(delimin) == 5) {
                        txt2 = txt.substring(0, 5);
                        if (txt2.indexOf(delimin) == 2) {
                            txt3 = txt2.substring(0, 2);
                            if (VR_Common.IsNumber("integer", txt3, false) && (parseInt(txt3) > 12)) {
                                formatin = "ddmmyyyy";
                            }
                            else if (txt2.length > 3) {
                                txt3 = txt2.substring(3);
                                if (VR_Common.IsNumber("integer", txt3, false) && (parseInt(txt3) > 12)) {
                                    formatin = "mmddyyyy";
                                }
                            }
                        }
                    }
                }
                else if (flag || flag1) {
                    //has month title
                    if (VR_Common.Contains(datein,",")) {
                        txt1 = datein.substring(datein.indexOf(",") + 1).trim();
                        if (txt1.length == 4 && VR_Common.IsNumber("integer", txt1, false)) {
                            txt1 = datein.substring(0, datein.indexOf(",")).trim();
                            txt2 = "";
                            if (VR_Common.Contains(txt1," ")) {
                                txt2 = txt1.substring(txt1.indexOf(" ") + 1).trim();
                                txt1 = txt1.substring(0, txt1.indexOf(" ")).trim();
                                if (txt1==monthnameused) {
                                    formatin = "monthdd,yyyy";
                                }
                                else if (txt2==monthnameused) {
                                    formatin = "ddmonth,yyyy";
                                }
                            }
                        }
                    }
                }
                else if (txt.length == 8) {
                    txt2 = txt.substring(0, 4);
                    n1 = -1;
                    if (VR_Common.IsNumber("integer", txt2, false)) n1 = parseInt(txt2);
                    if (n1 >= 1980 && n1 <= 2050) {
                        txt2 = txt.substring(4, 6);
                        n1 = -1;
                        if (VR_Common.IsNumber("integer", txt2, false)) n1 = parseInt(txt2);
                        if (n1 > 12) {
                            formatin = "yyyyddmm";
                        }
                        else {
                            txt2 = txt.substring(6);
                            n1 = -1;
                            if (VR_Common.IsNumber("integer", txt2, false)) n1 = parseInt(txt2);
                            if (n1 > 12) {
                                formatin = "yyyymmdd";
                            }
                        }
                    }
                    else {
                        txt2 = txt.substring(4);
                        n1 = -1;
                        if (VR_Common.IsNumber("integer", txt2, false)) n1 = parseInt(txt2);
                        if (n1 >= 1980 && n1 <= 2050) {
                            txt2 = txt.substring(0, 2);
                            n1 = -1;
                            if (VR_Common.IsNumber("integer", txt2, false)) n1 = parseInt(txt2);
                            if (n1 > 12) {
                                formatin = "ddmmyyyy";
                            }
                            else {
                                txt2 = txt.substring(2, 4);
                                n1 = -1;
                                if (VR_Common.IsNumber("integer", txt2, false)) n1 = parseInt(txt2);
                                if (n1 > 12) {
                                    formatin = "mmddyyyy";
                                }
                            }
                        }
                    }
                }
            }


            if (formatin=="monthdd,yyyy" || formatin=="ddmonth,yyyy") {
                yr = datein.substring(datein.indexOf(",") + 1).trim();
                if (flag) mo = hashmonthnames[monthnameused];
                else if (flag1) mo = hashmonthlongnames[monthnameused];
                if (formatin=="monthdd,yyyy") {
                    dy = datein.substring(datein.indexOf(" ") + 1);
                }
                else if (formatin=="ddmonth,yyyy") {
                    dy = datein.substring(0, datein.indexOf(" "));
                }
                dy = dy.substring(0, dy.indexOf(",")).trim();
            }
            else if (delimin != "") {
                temp=txt.split(delimin, 1000);

                if (formatin=="ddmmmyyyy") {
                    if (temp.length > 0) dy = temp[0];
                    if (temp.length > 1) mo = temp[1];
                    if (temp.length > 2) yr = temp[2];
                    mo = mo.toLowerCase();
                    if (typeof hashmonthnames[mo] != 'undefined') mo = hashmonthnames[mo]; else mo = "00";
                }
                else if (formatin=="yyyymmmdd") {
                    if (temp.length > 0) yr = temp[0];
                    if (temp.length > 1) mo = temp[1];
                    if (temp.length > 2) dy = temp[2];
                    mo = mo.toLowerCase();
                    if (typeof hashmonthnames[mo] != 'undefined') mo = hashmonthnames[mo]; else mo = "00";
                }
                else if (formatin=="" || formatin=="mmddyy" || formatin=="mddyy" || formatin=="mmdyy" || formatin=="mdyy"
                    || formatin=="mmddyyyy" || formatin=="mddyyyy" || formatin=="mmdyyyy" || formatin=="mdyyyy") {
                    if (temp.length > 0) mo = temp[0];
                    if (temp.length > 1) dy = temp[1];
                    if (temp.length > 2) yr = temp[2];
                }
                else if (formatin=="ddmmyy" || formatin=="ddmyy" || formatin=="dmmyy" || formatin=="dmyy"
                    || formatin=="ddmmyyyy" || formatin=="ddmyyyy" || formatin=="dmmyyyy" || formatin=="dmyyyy") {
                    if (temp.length > 0) dy = temp[0];
                    if (temp.length > 1) mo = temp[1];
                    if (temp.length > 2) yr = temp[2];
                }
                else if (formatin=="yyddmm" || formatin=="yyddm" || formatin=="yydmm" || formatin=="yydm"
                    || formatin=="yyyyddmm" || formatin=="yyyyddm" || formatin=="yyyydmm" || formatin=="yyyydm") {
                    if (temp.length > 0) yr = temp[0];
                    if (temp.length > 1) dy = temp[1];
                    if (temp.length > 2) mo = temp[2];
                }
                else if (formatin=="yymmdd" || formatin=="yymdd" || formatin=="yymmd" || formatin=="yymd"
                    || formatin=="yyyymmdd" || formatin=="yyyymdd" || formatin=="yyyymmd" || formatin=="yyyymd") {
                    if (temp.length > 0) yr = temp[0];
                    if (temp.length > 1) mo = temp[1];
                    if (temp.length > 2) dy = temp[2];
                }
                else if (formatin=="yyyyddd") {
                    if (temp.length > 0) yr = temp[0];
                    if (temp.length > 1) dy = temp[1];
                    if (dy!="" && VR_Common.IsNumber("integer", dy, false)) {
                        ndy = parseInt(dy);
                        if (ndy > 0) {
                            n1 = 0;
                            for (i = 0; i < daysPerMonth.size(); i++) {
                                if (ndy <= (n1 + daysPerMonth[i])) {
                                    mo = (i + 1).toString();
                                    ndy -= n1;
                                    dy = ndy.toString();
                                    break;
                                }
                                n1 += daysPerMonth[i];
                            }
                        }
                        ndy = -1;
                    }
                }
                else dy = "";
            }
            else {
                if (formatin=="ddmmmyyyy") {
                    if (txt.length <= 2) dy = txt;
                    else if (txt.length <= 5) {
                        dy = txt.substring(0, 2);
                        mo = txt.substring(2);
                    }
                    else if (txt.length > 5) {
                        dy = txt.substring(0, 2);
                        mo = txt.substring(2, 5);
                        yr = txt.substring(5);
                    }
                    mo = mo.toLowerCase();
                    if (typeof hashmonthnames[mo] != 'undefined') mo = hashmonthnames[mo]; else mo = "00";
                }
                else if (formatin=="yyyymmmdd") {
                    if (txt.length <= 4) yr = txt;
                    else if (txt.length <= 7) {
                        yr = txt.substring(0, 4);
                        mo = txt.substring(4);
                    }
                    else if (txt.length > 7) {
                        yr = txt.substring(0, 4);
                        mo = txt.substring(4);
                        dy = txt.substring(7);
                    }
                    mo = mo.toLowerCase();
                    if (typeof hashmonthnames[mo] != 'undefined') mo = hashmonthnames[mo]; else mo = "00";
                }
                else if (formatin=="" || formatin=="mmddyy" || formatin=="mmddyyyy") {
                    //assume mmddyyyy
                    if (txt.length <= 2) mo = txt;
                    else if (txt.length <= 4) {
                        mo = txt.substring(0, 2);
                        dy = txt.substring(2);
                    }
                    else if (txt.length > 4) {
                        mo = txt.substring(0, 2);
                        dy = txt.substring(2, 4);
                        yr = txt.substring(4);
                        if (formatin=="mmddyy" && yr.length == 2) yr = "20" + yr;
                    }
                }
                else if (formatin=="mddyy" || formatin=="mddyyyy") {
                    if (txt.length <= 1) mo = txt;
                    else if (txt.length <= 3) {
                        mo = txt.substring(0, 1);
                        dy = txt.substring(1);
                    }
                    else if (txt.length > 3) {
                        mo = txt.substring(0, 1);
                        dy = txt.substring(1, 3);
                        yr = txt.substring(3);
                    }
                }
                else if (formatin=="mdyy" || formatin=="mdyyyy") {
                    if (txt.length <= 1) mo = txt;
                    else if (txt.length <= 2) {
                        mo = txt.substring(0, 1);
                        dy = txt.substring(1);
                    }
                    else if (txt.length > 2) {
                        mo = txt.substring(0, 1);
                        dy = txt.substring(1, 2);
                        yr = txt.substring(2);
                    }
                }
                else if (formatin=="ddmmyy" || formatin=="ddmmyyyy") {
                    if (txt.length <= 2) dy = txt;
                    else if (txt.length <= 4) {
                        dy = txt.substring(0, 2);
                        mo = txt.substring(2);
                    }
                    else if (txt.length > 4) {
                        dy = txt.substring(0, 2);
                        mo = txt.substring(2, 4);
                        yr = txt.substring(4);
                    }
                }
                else if (formatin=="ddmyy" || formatin=="ddmyyyy") {
                    if (txt.length <= 2) dy = txt;
                    else if (txt.length <= 3) {
                        dy = txt.substring(0, 2);
                        mo = txt.substring(2);
                    }
                    else if (txt.length > 3) {
                        dy = txt.substring(0, 2);
                        mo = txt.substring(2, 3);
                        yr = txt.substring(3);
                    }
                }
                else if (formatin=="dmyy" || formatin=="dmyyyy") {
                    if (txt.length <= 1) dy = txt;
                    else if (txt.length <= 2) {
                        dy = txt.substring(0, 1);
                        mo = txt.substring(1);
                    }
                    else if (txt.length > 2) {
                        dy = txt.substring(0, 1);
                        mo = txt.substring(1, 2);
                        yr = txt.substring(2);
                    }
                }
                else if (formatin=="yyddmm" || formatin=="yyddm") {
                    if (txt.length <= 2) yr = txt;
                    else if (txt.length <= 4) {
                        yr = txt.substring(0, 2);
                        dy = txt.substring(2);
                    }
                    else if (txt.length > 4) {
                        yr = txt.substring(0, 2);
                        dy = txt.substring(2, 4);
                        mo = txt.substring(4);
                    }
                }
                else if (formatin=="yymmdd" || formatin=="yymmd") {
                    if (txt.length <= 2) yr = txt;
                    else if (txt.length <= 4) {
                        yr = txt.substring(0, 2);
                        mo = txt.substring(2);
                    }
                    else if (txt.length > 4) {
                        yr = txt.substring(0, 2);
                        mo = txt.substring(2, 4);
                        dy = txt.substring(4);
                    }
                }
                else if (formatin=="yydmm" || formatin=="yydm") {
                    if (txt.length <= 2) yr = txt;
                    else if (txt.length <= 3) {
                        yr = txt.substring(0, 2);
                        dy = txt.substring(2);
                    }
                    else if (txt.length > 3) {
                        yr = txt.substring(0, 2);
                        dy = txt.substring(2, 3);
                        mo = txt.substring(3);
                    }
                }
                else if (formatin=="yymdd" || formatin=="yymd") {
                    if (txt.length <= 2) yr = txt;
                    else if (txt.length <= 3) {
                        yr = txt.substring(0, 2);
                        mo = txt.substring(2);
                    }
                    else if (txt.length > 3) {
                        yr = txt.substring(0, 2);
                        mo = txt.substring(2, 3);
                        dy = txt.substring(3);
                    }
                }
                else if (formatin=="yyyyddmm" || formatin=="yyyyddm") {
                    if (txt.length <= 4) yr = txt;
                    else if (txt.length <= 6) {
                        yr = txt.substring(0, 4);
                        dy = txt.substring(4);
                    }
                    else if (txt.length > 6) {
                        yr = txt.substring(0, 4);
                        dy = txt.substring(4, 6);
                        mo = txt.substring(6);
                    }
                }
                else if (formatin=="yyyymmdd" || formatin=="yyyymmd") {
                    if (txt.length <= 4) yr = txt;
                    else if (txt.length <= 6) {
                        yr = txt.substring(0, 4);
                        mo = txt.substring(4);
                    }
                    else if (txt.length > 6) {
                        yr = txt.substring(0, 4);
                        mo = txt.substring(4, 6);
                        dy = txt.substring(6);
                    }
                }
                else if (formatin=="yyyydmm" || formatin=="yyyydm") {
                    if (txt.length <= 4) yr = txt;
                    else if (txt.length <= 5) {
                        yr = txt.substring(0, 4);
                        dy = txt.substring(4);
                    }
                    else if (txt.length > 5) {
                        yr = txt.substring(0, 4);
                        dy = txt.substring(4, 5);
                        mo = txt.substring(5);
                    }
                }
                else if (formatin=="yyyymdd" || formatin=="yyyymd") {
                    if (txt.length <= 4) yr = txt;
                    else if (txt.length <= 5) {
                        yr = txt.substring(0, 4);
                        mo = txt.substring(4);
                    }
                    else if (txt.length > 5) {
                        yr = txt.substring(0, 4);
                        mo = txt.substring(4, 5);
                        dy = txt.substring(5);
                    }
                }
                else if (formatin=="yyyyddd") {
                    if (txt.length <= 4) yr = txt;
                    else if (txt.length == 7) {
                        yr = txt.substring(0, 4);
                        dy = txt.substring(4);
                    }
                    if (dy!="" && VR_Common.IsNumber("integer", dy, false)) {
                        ndy = parseInt(dy);
                        if (ndy > 0) {
                            n1 = 0;
                            for (i = 0; i < daysPerMonth.size(); i++) {
                                if (ndy <= (n1 + daysPerMonth[i])) {
                                    mo = (i + 1).toString();
                                    ndy -= n1;
                                    dy = ndy.toString();
                                    break;
                                }
                                n1 += daysPerMonth[i];
                            }
                        }
                        ndy = -1;
                    }
                    else dy = "";
                }
            }

            if (mo!="" && VR_Common.IsNumber("integer",mo, false)) {
                nmo = parseInt(mo);
            }
            if (dy!="" && VR_Common.IsNumber("integer", dy, false)) {
                ndy = parseInt(dy);
            }
            if (yr!="" && VR_Common.IsNumber("integer", yr, false)) {
                nyr = parseInt(yr);
            }
            if (nmo < 0) nmo = 0;
            if (ndy < 0) ndy = 0;
            if (nyr < 0) nyr = 0;

            if (!VR_Common.Contains(formatin, "yyyy") && nyr < 1900) nyr += 1900;

            yr = nyr.toString();
            mo = nmo.toString();
            dy = ndy.toString();
            if (yr.length == 1) yr = "000" + yr;
            else if (yr.length == 2) yr = "00" + yr;
            else if (yr.length == 3) yr = "0" + yr;
            if (mo.length == 1) mo = "0" + mo;
            if (dy.length == 1) dy = "0" + dy;
            result = yr + mo + dy;
            if (!timestr=="") {
                result += "T" + timestr;
            }
        }
        catch (ex) {
            result = "notok:" + ex;
        }
        return result;
    },


    //18288 is Jan 25, 1950 and 40203 is Jan 25, 2010 in Excel
    ConvertExcelNumericDateToIso(nDateIn) {
        let result = "", n1=-1;
        let dt = null;
        try {
            if (nDateIn == null || typeof nDateIn != 'number') throw ("missing date");
            n1 = Math.floor(nDateIn)-1;
            dt = new Date(1900, 0, n1);
            result = dt.getFullYear().toString() + (dt.getMonth() + 1).toString() + dt.getDate().toString();
        }
        catch (ex) {
            result = "notok:" + ex;
        }
        return result;
    },

    ConvertAMPMTimeto24(timeIn) {
        let result="", hr="", min="", sec="", ampm="", temp=[];
        let nHr=0;
        try {
            timeIn=timeIn.toLowerCase().replace(/ /g,"");
            if (this.Contains(timeIn,"pm",false)) {
                timeIn=this.GetFrontPart(timeIn,"pm",false);
                ampm="pm";
            }
            else if (this.Contains(timeIn,"am",false)) {
                timeIn=this.GetFrontPart(timeIn,"am",false);
                ampm="am";
            }
            if (this.Contains(timeIn,".",false)) timeIn=this.GetFrontPart(timeIn,".",false);
            if (this.Contains(timeIn,":",false)) {
                temp=timeIn.split(":");
                for (let i=0; i<temp.length; i++) {
                    if (i==0) hr=temp[i];
                    else if (i==1) min=temp[i];
                    else if (i==2) sec=temp[i];
                }
            }
            else if (timeIn.length>2) {
                hr=timeIn.substring(0,2);
                if (timeIn.length>4) {
                    min=timeIn.substring(2,4);
                    sec=timeIn.substring(4);
                }
                else min=timeIn.substring(2);
            }
            else hr=timeIn;
            if (ampm=="pm") {
                if (hr.length>0 && this.IsInt(hr)) nHr= parseInt(hr);
                nHr += 12;
                hr= nHr.toString();
            }
            hr=hr.padStart(2,"0");
            min=min.padStart(2,"0");
            sec=sec.padStart(2,"0");
            result = hr + min + sec;
        }
        catch (e) { result=e.toString(); }
        return result;
    },


    /**
    * Converts a string representing a main frame formatted number with an encoded last character into a string of a real along with sign reversal if necessary 
    * Always makes last 2 digits into decimal portion so no further divide by 100 is necessary. If special char is within input string it becomes the end char and the 
    * remaining suffix is discarded. Leading zeros are truncated so 000.12 becomes 0.12 . Codes are:
    * <ul><li>  </li>
    * <li>{= 0</li>
    * <li>}= 0 and negate</li>
    * <li>a= 1</li>
    * <li>j= 1 and negate</li>
    * <li>b= 2</li>
    * <li>k= 2 and negate</li>
    * <li>c= 3</li>
    * <li>l= 3 and negate</li>
    * <li>d= 4</li>
    * <li>m= 4 and negate</li>
    * <li>e= 5</li>
    * <li>n= 5 and negate</li>
    * <li>f= 6</li>
    * <li>o= 6 and negate</li>
    * <li>g= 7</li>
    * <li>p= 7 and negate</li>
    * <li>h= 8</li>
    * <li>q= 8 and negate</li>
    * <li>i= 9</li>
    * <li>r= 9 and negate</li>
    * </ul>
    * @param strin original string of number value
    * @param typ conversion type: ibm. Default is ibm.
    * @return result or starts with 'notok:' if error
    */
    ConvertMainFrameNumber(valin, typ) {
        let msg = "", txt = "", txt1 = "", txt2 = "", signtyp = "";
        let i = 0, n1 = -1;
        let isChgSign = false;
        try {
            typ = typ.toLowerCase().trim();
            if (typ == "") typ = "ibm";
            if (typ == "ibm") {
                txt = valin.toLowerCase().trim();
                if (VR_Common.StartsWith(txt, "-", false)) {
                    signtyp = "-";
                    txt = txt.substring(1);
                }
                if (txt.length >= 2) {
                    //check for badly formatted strings

                    for (i = 0; i <= 20; i++) {
                        if (i == 0) txt2 = "{";
                        else if (i == 1) txt2 = "}";
                        else if (i == 2) txt2 = "a";
                        else if (i == 3) txt2 = "j";
                        else if (i == 4) txt2 = "b";
                        else if (i == 5) txt2 = "k";
                        else if (i == 6) txt2 = "c";
                        else if (i == 7) txt2 = "l";
                        else if (i == 8) txt2 = "d";
                        else if (i == 9) txt2 = "m";
                        else if (i == 10) txt2 = "e";
                        else if (i == 11) txt2 = "n";
                        else if (i == 12) txt2 = "f";
                        else if (i == 13) txt2 = "o";
                        else if (i == 14) txt2 = "g";
                        else if (i == 15) txt2 = "p";
                        else if (i == 16) txt2 = "h";
                        else if (i == 17) txt2 = "q";
                        else if (i == 18) txt2 = "i";
                        else if (i == 19) txt2 = "r";
                        else break;

                        if (VR_Common.Contains(txt, txt2, false)) {
                            n1 = txt.indexOf(txt2);
                            break;
                        }
                    }
                    if (n1 >= 0 && n1 < txt.length - 1) {
                        //has chars after coded end char
                        txt1 = txt.substring(n1, n1 + 1); //end char
                        txt = txt.substring(0, n1); //prefix
                    }
                    else {
                        //no special char found or at end
                        txt1 = txt.substring(txt.length - 1); //end char
                        txt = txt.substring(0, txt.length - 1); //prefix
                    }

                    txt2 = "0"; //default
                    if (txt1 == "{") txt2 = "0";
                    else if (txt1 == "}") {
                        txt2 = "0"; isChgSign = true;
                    }
                    else if (txt1 == "a") txt2 = "1";
                    else if (txt1 == "j") {
                        txt2 = "1"; isChgSign = true;
                    }
                    else if (txt1 == "b") txt2 = "2";
                    else if (txt1 == "k") {
                        txt2 = "2"; isChgSign = true;
                    }
                    else if (txt1 == "c") txt2 = "3";
                    else if (txt1 == "l") {
                        txt2 = "3"; isChgSign = true;
                    }
                    else if (txt1 == "d") txt2 = "4";
                    else if (txt1 == "m") {
                        txt2 = "4"; isChgSign = true;
                    }
                    else if (txt1 == "e") txt2 = "5";
                    else if (txt1 == "n") {
                        txt2 = "5"; isChgSign = true;
                    }
                    else if (txt1 == "f") txt2 = "6";
                    else if (txt1 == "o") {
                        txt2 = "6"; isChgSign = true;
                    }
                    else if (txt1 == "g") txt2 = "7";
                    else if (txt1 == "p") {
                        txt2 = "7"; isChgSign = true;
                    }
                    else if (txt1 == "h") txt2 = "8";
                    else if (txt1 == "q") {
                        txt2 = "8"; isChgSign = true;
                    }
                    else if (txt1 == "i") txt2 = "9";
                    else if (txt1 == "r") {
                        txt2 = "9"; isChgSign = true;
                    }
                    else if (txt1 == "0" || txt1 == "1" || txt1 == "2" || txt1 == "3" || txt1 == "4" || txt1 == "5" || txt1 == "6" || txt1 == "7" || txt1 == "8" || txt1 == "9") {
                        txt2 = txt1;
                    }
                    txt += txt2;
                    if (!VR_Common.Contains(txt, ".", false) && txt.length >= 2) {
                        //always make last two chars into decimal portion
                        txt1 = txt.substring(txt.length - 2);
                        txt = txt.substring(0, txt.length - 2);
                        txt += "." + txt1;
                    }
                    if (isChgSign) {
                        if (signtyp == "-") signtyp = ""; else signtyp = "-";
                    }

                    while (VR_Common.StartsWith(txt, "0", false)) {
                        //strip leading 0's
                        if (VR_Common.StartsWith(txt, ".", false) || !VR_Common.StartsWith(txt, "0", false)) break;
                        txt = txt.substring(1);
                    }
                    if (txt == "") {
                        txt = "0.00";
                    }
                    if (VR_Common.StartsWith(txt, ".", false)) txt = "0" + txt; //force final form to have at least 0 left of decimal point
                    txt = signtyp + txt;

                }
                else {
                    txt = valin; //reset to original
                }

            }
            else throw ("unknown conversion type: " + typ);
            msg = txt;
        }
        catch (ex) {
            msg = "notok:" + ex;
        }
        return msg;
    },




    ConvertFromExp(valin) {
        let result = "", origNum = "", intPart="", decPart = "", numStr = "", expPart = "", remainder="";
        let nExp = -1, n1 = -1;
        let isExpNeg = false, flag = false;
        try {
            if (valin == null || typeof valin == 'undefined') valin = "";
            result = valin;
            if (result == "") return result;
            origNum = valin.toLowerCase();
            if (VR_Common.Contains(origNum, "e-", false)) isExpNeg = true;
            if (VR_Common.Contains(origNum, "e", false)) {
                flag = true;
                numStr = origNum.substring(0, origNum.indexOf("e"));
            }
            //should have decimal pt at 2nd char
            if (flag) {
                if (!VR_Common.Contains(numStr, ".", false) || numStr.indexOf(".") != 1) flag = false;
                else if (!VR_Common.IsNumber("real", numStr, false)) flag = false;
            }
            if (flag) {
                decPart = numStr.substring(numStr.indexOf(".") + 1);
                intPart = numStr.substring(0, numStr.indexOf("."));
                expPart = origNum.substring(origNum.indexOf("e") + 1);
                if (VR_Common.StartsWith(expPart, "+", false) || VR_Common.StartsWith(expPart, "-", false)) expPart = expPart.substring(1);
                if (expPart != "" && VR_Common.IsNumber("integer", expPart, false)) {
                    nExp = parseInt(expPart);
                    if (isExpNeg) {
                        result = ".";
                        for (let i = 0; i < (nExp - 1); i++) {
                            result += "0";
                        }
                        result += intPart + decPart;
                    }
                    else {
                        if (decPart.length > nExp) {
                            remainder = decPart.substring(nExp);
                            decPart = decPart.substring(0, nExp);
                            numStr = numStr.substring(0, numStr.indexOf(".") + 1) + decPart;
                            result = numStr.replace(".", "") + "." + remainder;
                        }
                        else if (decPart.length == nExp) {
                            result = numStr.replace(".", "");
                        }
                        else if (decPart.length < nExp) {
                            n1 = nExp - decPart.length;
                            result = numStr.replace(".", "");
                            for (let i = 0; i < n1; i++) {
                                result += "0";
                            }
                        }
                    }
    //                didFix = true;
                }
            }
        }
        catch (ex) {
            result = "notok:" + ex;
        }
        return result;
    },



    DeepCloneByJSONParse(objIn) {
      try {
        if (objIn==null || typeof objIn=='undefined') throw("empty object");
        return JSON.parse(JSON.stringify(objIn));
      }
      catch (ex) {return null;}
    },

    /**
     * Sorts an array of numbers either ascending or descending
     * @param {Array} dataIn - array of numbers to be sorted
     * @param {string} typ - asc or desc sort order
     * @returns {Array} a_sorted - sorted array
     */
    SortNumbers(dataIn, typ) {
        let a_sorted=[];
        try {
            if (typ==null || typeof typ=='undefined') typ="asc";
            if (dataIn==null || typeof dataIn=='undefined' || !Array.isArray(dataIn)) throw("no data supplied");
            for (let i=0; i< dataIn.length; i++) {
                a_sorted.push(dataIn[i]);
            }
            if (this.StartsWith(typ,"desc",false)) a_sorted.sort((a,b)=>b-a);
            else a_sorted.sort((a,b)=>a-b);
        }
        catch (ex) {
            a_sorted[0]="notok:" + ex;
        }
        return a_sorted;
    },


    NormalizeDataType(datatype) {
        let result="";
        try {
            if (datatype == null || typeof datatype == 'undefined') datatype = "";
            datatype=datatype.toLowerCase().trim();
            if (VR_Common.StartsWith(datatype,"int", false) || VR_Common.Contains(datatype,"short", false) || VR_Common.Contains(datatype,"long", false)) result="int";
            else if (VR_Common.Contains(datatype,"real", false) || VR_Common.Contains(datatype,"number", false) || VR_Common.Contains(datatype,"float", false) 
            || VR_Common.Contains(datatype,"double", false) || VR_Common.Contains(datatype,"decimal", false) || VR_Common.Contains(datatype,"single", false)) result="real";
            else if (VR_Common.Contains(datatype,"bool", false)) result="bool";
            else if (VR_Common.Contains(datatype,"datetime", false)) result="datetime";
            else if (VR_Common.Contains(datatype,"date", false)) result="date";
            else if (datatype.length>0) result="string";
        }
        catch (ex) {
            result = "notok:" + ex;
        }
        return result;
    },


}
